import React from 'react';

function TrashSvg(props:any) {
    return (
        <svg {...props} width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 3.53027H15" stroke="#898989" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5 7.4707V13.5307" stroke="#898989" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M8 6.31055V14.5805" stroke="#898989" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M11 7.4707V13.5307" stroke="#898989" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.795 17.4723H4.205C2.987 17.4723 2 16.4853 2 15.2673V3.52832H14V15.2673C14 16.4853 13.013 17.4723 11.795 17.4723Z"
                  stroke="#898989" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M12 3.52832L11.262 1.22332C11.129 0.80932 10.744 0.52832 10.31 0.52832H5.69C5.255 0.52832 4.87 0.80932 4.738 1.22332L4 3.52832"
                stroke="#898989" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 3.53027H2" stroke="#898989" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default TrashSvg;