import React from 'react';

function FileIconSvg(props: any) {
    return (
        <svg {...props} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 4C0 1.79086 1.79086 0 4 0H34C36.2091 0 38 1.79086 38 4V34C38 36.2091 36.2091 38 34 38H4C1.79086 38 0 36.2091 0 34V4Z"
                fill="#D9D9D9"/>
            <line x1="6.98047" y1="8.03064" x2="29.4703" y2="8.03064" stroke="white"/>
            <line x1="6.98047" y1="15.0102" x2="29.4703" y2="15.0102" stroke="white"/>
            <line x1="6.98047" y1="21.9898" x2="24.8172" y2="21.9898" stroke="white"/>
            <line x1="6.98047" y1="28.9694" x2="18.6131" y2="28.9694" stroke="white"/>
        </svg>
    );
}

export default FileIconSvg;