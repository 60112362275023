import React from 'react';

function ZoomSvg(props: any) {
    return (
        <svg {...props} width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.548 3.51693C15.0588 6.29471 15.0588 10.7984 12.548 13.5762C10.0371 16.354 5.96625 16.354 3.4554 13.5762C0.944553 10.7984 0.944553 6.29471 3.4554 3.51693C5.96625 0.739148 10.0371 0.739148 12.548 3.51693"
                stroke="#787878" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.1106 19.2175L12.2886 13.8828" stroke="#787878" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
}

export default ZoomSvg;