import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link, useLocation, useParams} from "react-router-dom";
import {Button, Form, Input, message, Select} from "antd";
import {MaskedInput} from "antd-mask-input";
import {StyledCheckbox} from "../../components/StyledCheckbox";
import {
    loginAction,
    regPhoneAction,
    resetAction,
    restoreAction,
    signupAction,
    verifyAction
} from "../../redux/actions/loginActions";
import store from "../../redux/store";
import moment from "moment";

const {Option} = Select;

const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 45px;

  h1 {
    margin: 0 12px 0 0;

    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;

    color: #311C1C;
  }

  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    display: flex;
    align-items: center;

    color: #5B77CB;
  }
`;

const StyledCreateProfilePage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 65px 18.75% 220px 18.75%;

  @media screen and (max-width: 1919px) {
    padding: 65px 20px 220px 20px;
  }
  
  .ant-form-item {
    margin: 0;
  }

  .askBlock {
    margin-top: 40px;

    .askAgain {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      text-decoration-line: underline;

      color: #5B77CB;
      cursor: not-allowed;

      &.available {
        cursor: pointer;
      }
    }

    .timer {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;


      color: #8A8A8A;
    }
  }

  .ant-form {
    .ant-input {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;


      height: auto;
      padding: 16px 10px;

      color: #000000;
    }

    label {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;

      color: #8A8A8A;

      &.ant-checkbox-wrapper {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #000000;
      }
    }
  }


  .ant-btn {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: #FFFFFF;
    border: none;
    padding: 17px 28px;
    height: auto;
    background: #5B77CB;
    border-radius: 4px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
`;


function VerifyPage() {
    const waitingTime = 60;
    const {phone, email} = useParams();

    const controller = new AbortController();

    const [time, setTime] = useState<number>(waitingTime)
    const [againAvailable, setAgainAvailable] = useState<boolean>(false);

    const [formRef] = Form.useForm();

    useEffect(() => {
        setTime(waitingTime);
    }, [againAvailable])

    useEffect(() => {
        let interval: any;
        if (!againAvailable) {
            interval = setInterval(() => {
                setTime((time) => time - 1)
            }, 1000)
        }

        if (time === 0) {
            clearInterval(interval);
            setAgainAvailable(true);
        }


        return () => {
            if (interval)
                clearInterval(interval);
        }
    }, [time, againAvailable])


    return (
        <StyledCreateProfilePage>
            <Top><h1>Введите код</h1></Top>
            <Form form={formRef} requiredMark={false} layout={"vertical"} onFinish={({code}) => {
                store.dispatch(resetAction(email !== undefined ? "email" : "phone", email !== undefined ? email : phone, code, controller.signal))
            }}>
                <Form.Item name={"code"} rules={[{required: true}, {
                    validator: (_, value) => {
                        if (value !== undefined && value.indexOf("_") !== -1) {
                            return Promise.reject(new Error("Поле должно быть заполнено полностью!"))
                        } else {
                            return Promise.resolve()
                        }
                    }
                }]} style={{display: 'inline-block'}}
                           label={email !== undefined ? "Укажите код который пришел на вашу почту" : "Укажите 4 последние цифры с номера, который Вам позвонил. На звонок отвечать не нужно"}>
                    <MaskedInput style={{width: 165}} mask={'0000'} onChange={({target: {value}}) => {
                        if (!value.includes("_")) {
                            formRef.submit()
                        }
                    }}/>
                </Form.Item>
                <Form.Item className={"askBlock"}>
                    <span className={`askAgain ${againAvailable ? "available" : ""}`} onClick={() => {
                        if (againAvailable) {
                            setAgainAvailable(false);
                            store.dispatch(restoreAction(email !== undefined ? "email" : "phone", email !== undefined ? email : phone))
                        }
                    }}>Запросить код повторно</span> {!againAvailable && <span
                    className={"timer"}>через {moment.utc(time * 1000).format("mm:ss")}</span>}
                </Form.Item>
            </Form>
        </StyledCreateProfilePage>
    );
}

export default VerifyPage;