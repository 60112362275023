import React from 'react';
import styled from "styled-components";
import Breadcrumbs from "../../components/Breadcrumbs";

const StyledPublicOfferAgreementPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 65px 18.75% 135px 18.75%;

  b {
    margin-top: 20px;
  }

  p, h1, h2 {
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
    padding: 0;
    color: #000000;
  }

  h1 {
    &:first-of-type {
      margin-top: 50px;
    }

    font-weight: 600;
  }

  h2 {
    font-weight: 400;
    margin-bottom: 20px;
  }

  h1, h2 {
    text-align: center;
  }

  @media screen and (max-width: 1919px) {
    padding: 65px 20px 135px 20px;
  }
`;

function PublicOfferAgreementPage() {
    return (
        <StyledPublicOfferAgreementPage>
            <Breadcrumbs/>
            <h1>Договор публичной оферты</h1>
            <h2>Интернет-магазина niz-zarya.ru</h2>

            <p>Настоящий договор между интернет-магазином niz-zarya.ru, именуемым в дальнейшем «Продавец» и
                пользователем услуг интернет-магазина, именуемым в дальнейшем «Покупатель», совместно именуемые
                «Стороны», определяет условия приобретения товаров через интернет-магазин niz-zarya.ru.</p>
            <p>Настоящий договор публичной оферты действует с 15.02.2023 г. и до момента принятия оферты в новой
                редакции.</p>

            <b><p>1. Термины и определения.</p></b>
            <p>1.1 «Оферта» - настоящий документ, являющийся публичным предложением Продавца, адресованным любому лицу,
                о заключении договора купли-продажи Товара (далее также - Договор) на условиях, содержащихся в настоящем
                документе, включая все её приложения.</p>
            <p>1.2 «Акцепт» - полное и безоговорочное принятие Покупателем условий настоящей Оферты.</p>
            <p>1.3 «Продавец» - общество с ограниченной ответственностью «Новый Инструментальный Завод ЗАРЯ» (ОГРН
                1237400004269, далее ООО «НИЗ ЗАРЯ»).</p>
            <p>1.4 «Покупатель» - пользователь услуг интернет-магазина Продавца, принявший в полном объеме и без
                исключений условия оферты (совершившее акцепт оферты).</p>
            <p>1.5 «Сайт» - совокупность электронных данных, доступных для просмотра Покупателям по адресу в сети
                «Интернет» niz-zarya.ru.</p>
            <p>1.6 «Интернет-магазин» - принадлежащий Продавцу ресурс, размещенный в сети Интернет и имеющий адрес
                niz-zarya.ru, предназначенный для продажи товаров Покупателям на основании настоящей Оферты.</p>
            <p>1.7 «Заказ» - намерение Покупателя приобрести определенный им Товар, представленный в Интернет-магазине
                Продавца.</p>
            <p>1.8 «Каталог» - информация о товарах, размещенная в интернет-магазине.</p>
            <p>1.9 «Товар» - имущество, реализуемое продавцом в интернет – магазине niz-zarya.ru.</p>

            <b><p>2. Статусы сторон.</p></b>
            <p>2.1 Статус интернет-магазина</p>
            <p>2.1.1 Интернет-магазин является собственностью ООО «НИЗ ЗАРЯ» и предназначен для дистанционного способа
                продажи товаров через сеть интернет.</p>
            <p>2.1.2 Интернет-магазин не требует от Покупателя специальных действий для просмотра товара, расчёта и
                оформления заказа, таких как регистрация или заключение договора на пользование ресурсом
                интернет-магазина.</p>
            <p>2.1.3 Интернет-магазин не несет ответственности за содержание и достоверность информации, предоставленной
                Покупателем при оформлении заказа.</p>
            <p>2.2 Статус покупателя</p>
            <p>2.2.1 Покупатель несет ответственность за достоверность предоставленной при оформлении Заказа
                информации.</p>
            <p>2.2.2 Покупатель подтверждает свое согласие с условиями, установленными настоящим Договором, путем
                проставления отметки в графе «Я принимаю условия публичного договора-оферты» при оформлении Заказа. До
                заключения Договора, условия Договора Покупателем прочитаны полностью, все условия Договора понятны, со
                всеми условиями Договора Покупатель согласен.</p>
            <p>2.2.3 Использование ресурса интернет-магазина для просмотра и выбора Товара, а также для оформления
                Заказа является для Покупателя безвозмездным.</p>

            <b><p>3. Общие положения.</p></b>
            <p>3.1 Настоящий документ является публичной офертой в соответствии со ст. ст. 435, 437, 438 Гражданского
                Кодекса Российской Федерации, и содержит в себе предложение Продавца о заключении с любым лицом договора
                купли-продажи Товаров, представленных на Сайте Продавца.</p>
            <p>3.2 Настоящая публичная оферта (именуемая в дальнейшем «Оферта») определяет все существенные условия
                договора между ООО «НИЗ ЗАРЯ» и лицом, акцептовавшим Оферту.</p>
            <p>3.3 Настоящий договор заключается между Покупателем и интернет - магазином в момент оформления
                заказа.</p>
            <p>3.4 Оферта может быть акцептована (принята) любым лицом на территории Российской Федерации, имеющим
                намерение приобрести товар и/или услуги, реализуемые/предоставляемые ООО «НИЗ ЗАРЯ» через
                интернет-магазин niz-zarya.ru.</p>
            <p>3.5 Покупатель безоговорочно принимает все условия, содержащиеся в оферте в целом (т.е. в полном объеме и
                без исключений).</p>
            <p>3.6 В случае принятия условий настоящего договора (т.е. публичной оферты интернет-магазина), лицо,
                производящее акцепт оферты, становится Покупателем.</p>
            <p>3.7 Акцептом является факт внесения предоплаты по заказу в размере не менее 50 (пятидесяти) % от его
                полной стоимости, на условиях настоящего соглашения.</p>
            <p>3.8 Оферта, все приложения к ней, а также вся информация о товарах/услугах ООО «НИЗ ЗАРЯ», опубликована
                на niz-zarya.ru.</p>

            <b><p>4. Предмет договора-аферты.</p></b>
            <p>4.1. Продавец, на основании заказа и предварительной оплаты Покупателем, обязуется продать в
                собственность Покупателю Товар, по ценам, указанным на Сайте Продавца, на дату оформления Заказа и на
                условиях настоящей Оферты.</p>
            <p>4.2. Продавец имеет право изменять стоимость товара, условия данной Оферты без предварительного
                согласования с Покупателем, обеспечивая при этом публикацию измененных условий на Сайте, не менее чем за
                один день до их вступления в действие.</p>
            <p>4.3. К Заказам, оформленным до вступления в действие новых условий Оферты, применяются условия Оферты,
                действовавшие на момент осуществления Заказа.</p>
            <p>4.4 Покупателю при оформлении заказа предоставляется право выбора способа доставки. Доставка Товаров,
                заказанных и оплаченных Покупателем, осуществляется Продавцом или Перевозчиком. Самовывоз Покупателем
                Товара со склада Продавца согласуется отдельно.</p>
            <p>4.5 К отношениям между Покупателем и Продавцом применяются положения ГК РФ, а также иные соответствующие
                нормативные правовые акты РФ.</p>
            <p>4.6 Лицо считается принявшим все условия оферты (акцепт оферты) и приложений к ней в полном объеме и без
                исключений с момента поступления денежных средств в счет оплаты товара на расчётный счёт Продавца (в
                случае безналичной оплаты), либо с момента поступления денежных средств в счет оплаты товара на
                расчетный счет Оператора платежной системы (в случае оплаты через платежные системы), в порядке и на
                условиях, установленных Продавцом. В случае акцепта оферты одним из вышеуказанных способов, физическое
                лицо считается заключившим с Продавцом договор купли-продажи заказанных товаров и приобретает статус
                Покупателя.</p>

            <b><p>5. Характеристика товара.</p></b>
            <p>5.1 В интернет-магазине предоставлены фото-образцы Товаров, сопровождающиеся текстовой информацией:
                наименование, цена и описание.</p>
            <p>5.2 Информационные материалы, представленные в интернет - магазине, носят справочный характер.</p>
            <p>5.3 По всем вопросам, касающимся свойств и характеристик товара, Покупатель, перед оформлением заказа,
                должен обратиться к Продавцу. Контакты Продавца указаны на Сайте.</p>
            <p>5.4 В случае приобретения Товара со скидкой, установленной в связи с его дефектами, Продавец лишается
                права ссылаться на эти недостатки в дальнейшем.</p>
            <p>5.5 Покупатель уведомлен о том, что указанный в счете отдельными позициями Товар не является
                комплектом.</p>

            <b><p>6. Цена товара.</p></b>
            <p>6.1 Цена в интернет-магазине указана в рублях РФ, за единицу Товара.</p>
            <p>6.2 Тарифы на оказание услуг по доставке Товара оплачиваются отдельно.</p>
            <p>6.3 Указанная на Сайте цена Товара может быть изменена интернет-магазином в одностороннем порядке, при
                этом цена на оплаченный Покупателем Товар изменению не подлежит.</p>

            <b><p>7. Порядок оформления заказа.</p></b>
            <p>7.1 Покупатель вправе заказать любой Товар в интернет-магазине, в любом имеющемся количестве, за
                исключением Товаров, участвующих в акциях или снятия товара с продажи.</p>
            <p>7.2 Заказ Товара осуществляется Покупателем самостоятельно с использованием сервиса Продавца или путем
                обращения по контактному телефону Продавца.</p>
            <p>7.3 При оформлении Заказа Покупатель обязуется сообщить следующую информацию:</p>
            - наименование Товара;
            - имя Покупателя;
            - адрес электронной почты (при оформлении Заказа на Сайте);
            - контактный телефон;
            - фактический адрес доставки Товара.
            <p>7.4 После оформления заказа, Продавец отправляет на e-mail Покупателя подтверждение принятия заказа и
                счет, с указанием наименования, размера, цены выбранного товара и общей суммы заказа, являющийся
                неотъемлемой частью настоящего Договора. Оплата счета (полностью или частично) Покупателем является
                подтверждением Покупателя правильного оформления заказа.</p>
            <p>7.5 В случае отсутствия заказанного Покупателем Товара, Продавец обязан известить об этом Покупателя по
                телефону или посредством электронной почты. Покупатель вправе заменить Товар на другой, либо
                аннулировать заказ.</p>
            <p>7.6 Покупатель вправе сделать предварительный заказ на временно отсутствующий Товар.</p>
            <p>7.7 Заказ принимается к исполнению только после полной его оплаты.</p>
            <p>7.8 Срок поставки товара указывается на сайте Продавца и начинает исчисляться на следующий день после
                поступления оплаты за заказанный Товар на расчетный счет Продавца.</p>

            <b><p>8. Оплата товара.</p></b>
            <p>8.1 Способы и порядок оплаты Товара указаны на сайте Продавца. При необходимости, порядок и условия
                оплаты заказанного Товара оговариваются Покупателем с Продавцом.</p>
            <p>8.2 Оплата по безналичной форме производится согласно оформленному счёту, в течение трёх банковских дней
                с момента получения счёта. Обязанность Покупателя по уплате цены Товара считается исполненной с момента
                зачисления соответствующих денежных средств в размере 100% (ста процентов) предоплаты на расчетный счет
                Продавца по реквизитам, указанным в счёте.</p>
            <p>8.3 Покупатель оплачивает Заказ любым способом, выбранным в интернет-магазине. До полной оплаты Товар
                Покупателю не передается.</p>
            <p>8.4 Оплаченный Товар поставляется Покупателю по ценам, наименованию, количестве, указанному в Заказе.</p>

            <b><p>9. Доставка товара.</p></b>
            <p>9.1 Способы и сроки доставки Товара указаны в интернет - магазине Продавца. Срок доставки зависит от
                выбранного способа доставки Товара. Порядок и условия доставки заказанного Товара могут быть оговорены
                Покупателем с Продавцом отдельно. Доставленный Товар передается Покупателю по указанному им адресу, а
                при отсутствии покупателя – любому лицу, предъявившему информацию о номере заказа, либо иное (в том
                числе электронное) подтверждение оформления Заказа. В случае если доставка Товара произведена в
                установленные сроки, но Товар не был передан Покупателю по его вине, последующая доставка производится в
                новые согласованные с Продавцом сроки, на условиях и по тарифам Продавца.</p>
            <p>9.2 Самовывоз Товара:</p>
            <p>9.2.1 Продавец, получив уведомление о Заказе, подтверждает его получение по телефону или по e-mail
                Покупателя и согласовывает с ним дату самовывоза Товара.</p>
            <p>9.2.2 Покупатель оплачивает и получает Заказ по месту нахождения Продавца. Адреса, контакты и режим
                работы указаны на сайте Продавца. При безналичной форме оплаты Продавец дополнительно по телефону или по
                e-mail Покупателя подтверждает факт зачисления оплаты Заказа на расчетный счет Продавца и только после
                этого согласовывает с Покупателем дату самовывоза Товара.</p>
            <p>9.2.3 Право собственности и риск случайной утраты или повреждения Товара переходит к Покупателю с момента
                передачи Товара Покупателю или его Представителю. Передача Товара Представителю осуществляется только
                после предъявления паспорта, а также копии паспорта лица, оформившего Заказ и доверенности на право
                получения Заказа данным Представителем, составленной в простой письменной форме.</p>
            <p>9.3 Доставка товара Продавцом:</p>
            <p>9.3.1 Переход права собственности и риск случайной утраты или повреждения Товара переходит к Покупателю с
                момента передачи Товара Покупателю или его Представителю.</p>
            <p>9.3.2 Доставка осуществляется только общедоступным способом – через входную дверь. Доставка товара через
                окна, балконы или иные проемы, кроме дверных, не осуществляется.</p>
            <p>9.3.3 Какие-либо работы (услуги), помимо доставки, не предусмотрены настоящим Договором.</p>
            <p>9.3.4 Работы (услуги) не оплаченные и не заказанные Покупателем не выполняются (не оказываются).</p>
            <p>9.3.5 Надлежащие условия для приемки Товара Покупатель обеспечивает самостоятельно, в том числе:</p>
            <p>9.3.5.1 Личное присутствие либо присутствие Представителя в месте исполнения Договора.</p>
            <p>9.3.5.2 Крупногабаритный Товар доставляется до адреса Покупателя лишь при условии наличия свободных
                подъездных путей для грузового автотранспорта. Проезд (въезд) грузового автотранспорта на охраняемую
                территорию должен быть согласован Покупателем со службой охраны заранее. Место выгрузки Товара должно
                быть не далее, чем 10 метров до входной двери адреса доставки.</p>
            <p>9.3.5.3 Обеспечить соответствие размера дверных проемов габаритам Товара, в ином случае Товар
                доставляется только до двери.</p>
            <p>9.3.5.4 Обеспечить свободный и достаточный по габаритам проход до места размещения Товара. Предметы,
                препятствующие переносу Товара, необходимо убрать перед доставкой.</p>
            <p>9.3.6 При отсутствии необходимых вышеизложенных условий, Продавец имеет право принять решение о
                невозможности отгрузки Товара по указанному Покупателем адресу, о чем уведомляет Покупателя.</p>
            <p>9.3.7 В случае необеспечения Покупателем надлежащей приемки Товара, повторная доставка производится в
                согласованные сторонами сроки за счет Покупателя, на условиях и в соответствии с тарифами Продавца.</p>
            <p>9.4 Доставка товара Перевозчиком:</p>
            <p>9.4.1 Право собственности, а также риск случайной утраты или повреждения товара переходит к Покупателю с
                момента передачи Товара Перевозчику, при подписании товарной накладной и/или транспортной накладной
                и/или товарно-транспортной накладной или иного документа, свидетельствующего о передаче товара
                Перевозчику.</p>
            <p>9.4.2 Обязательства Продавца по передаче Товара Покупателю, в том числе п. 8.4.1., считается исполненным
                с момента передачи Товара Перевозчику.</p>
            <p>9.4.3 В случае заказа Покупателем доставки Товара сторонним Перевозчиком (не применяя меню выбора в
                интернет-магазине или без согласования с Продавцом), стоимость доставки Товара в рамках каждого Заказа
                Продавцом не рассчитывается и оплачивается Покупателем Перевозчику самостоятельно. Стоимость доставки в
                цену Товара не входит.</p>
            <p>9.5 Точная стоимость доставки Товара определяется Продавцом при оформлении Заказа и не может быть
                изменена после согласования с Покупателем.</p>
            <p>9.6 При доставке Заказа Продавец, в целях предотвращения случаев мошенничества (в случае безналичной
                оплаты Товара), имеет право потребовать предоставить информацию, указанную при Заказе Товара.</p>
            <p>9.7 При получении Товара Покупатель или его Представитель в присутствии Продавца или его Представителя
                проверяет целостность внешней упаковки Товара, соответствие Товара по наименованию, количеству,
                ассортименту, комплектности, после чего подписывает накладную, ставя дату и подпись, подтверждая тем
                самым отсутствие претензий к внешнему виду и целостности.</p>
            <p>9.8 Срок исполнения Заказа зависит от наличия Товара на складе Продавца и удаленности Покупателя. Срок
                исполнения Заказа может быть оговорен с Покупателем индивидуально в зависимости от характеристик и
                количества заказанного Товара. В случае отсутствия части Заказа на складе Продавца, в том числе по
                причинам, не зависящим от последнего, Продавец вправе аннулировать указанный Товар из Заказа Покупателя,
                предварительно уведомив об этом Покупателя.</p>

            <b><p>10. Гарантии на товар.</p></b>
            <p>10.1 Гарантийный срок эксплуатации на Товар устанавливает производитель.</p>
            <p>10.2 Гарантийный срок не распространяется на Товар, имеющий повреждения, возникшие в результате нарушения
                Покупателем правил эксплуатации и/или рекомендаций по уходу за Товаром, использования Товара не по
                назначению, действий, направленных на повреждение или уничтожение Товара, действий непреодолимой силы, а
                также на Товар, имеющий механические повреждения, следы самостоятельного ремонта или изменения
                конструкции.</p>

            <b><p>11. Возврат товара.</p></b>
            <p>11.1 Требование Покупателя об обмене либо о возврате Товара подлежит удовлетворению, если Товар не был в
                употреблении, сохранены его потребительские свойства, сохранена и не нарушена упаковка, сохранены
                документы, подтверждающие факт покупки этого Товара в интернет-магазине niz-zarya.ru.</p>
            <p>11.2 В соответствии с Постановлением Правительства РФ «Об утверждении Правил продажи Товаров
                дистанционным способом», Покупатель вправе отказаться от Товара в любое время до момента его получения,
                а после передачи товара - в течение 7 дней.</p>
            <p>11.3 При отказе Покупателя от Товара надлежащего качества, Продавец возвращает ему сумму, уплаченную в
                соответствии с договором, за исключением расходов Продавца на осуществление возврата суммы внесенной им
                предоплаты по Заказу и расходов на доставку от Покупателя возвращенного товара. Возврат суммы
                осуществляется не позднее чем через 10 дней с даты предъявления Покупателем соответствующего требования
                и предъявления Продавцу, для перечисления денежных средств, своих банковских реквизитов.</p>
            <p>11.4 При отказе Покупатель обязан предоставить номер своего расчётного счёта с указанием реквизитов
                банка. В случае непредоставления, или некорректного предоставления вышеуказанных данных Продавец не
                несёт ответственности перед покупателем за несвоевременное перечисление денежных средств.</p>
            <p>11.5 Покупатель не вправе отказаться от Товара надлежащего качества, имеющего индивидуально-определенные
                свойства, если указанный Товар может быть использован исключительно приобретающим его Покупателем.</p>
            <p>11.6 Покупатель компенсирует Продавцу необходимые транспортные расходы, понесенные в связи с организацией
                обмена или возврата Товара, в случае отсутствия недостатков Товара.</p>
            <p>11.7 При возврате суммы оплаты, внесенной Покупателем в безналичном порядке, для оплаты Товара, комиссия,
                уплаченная Покупателем, Продавцом не компенсируется.</p>
            <p>11.8 Возврат товара, в случаях, предусмотренных законом и настоящим Договором, производится на адрес
                Продавца, указанный на сайте Продавца.</p>
            <p>11.9 Условия и порядок возврата Товара, не указанные в настоящей Оферте, регламентируются действующим
                законодательством РФ.</p>

            <b><p>12. Права обязанности сторон.</p></b>
            <p>12.1 Продавец обязуется:</p>
            <p>12.1.1 Не разглашать любую частную информацию Покупателя и не предоставлять доступ к этой информации
                третьим лицам, за исключением случаев, предусмотренных действующим законодательством РФ.</p>
            <p>12.1.2 Предоставить Покупателю возможность получения бесплатных телефонных консультаций по телефонам,
                указанным на Сайте. Объём консультаций ограничивается конкретными вопросами, связанными с выполнениями
                Заказа.</p>
            <p>12.2 Продавец имеет право:</p>
            <p>12.2.1 Изменять условия Оферты в одностороннем порядке до момента его заключения.</p>
            <p>12.2.2 Расширять и сокращать товарное предложение в интернет-магазине, регулировать доступ к покупке
                любых Товаров, а также приостанавливать или прекращать продажу любых Товаров по своему собственному
                усмотрению, без уведомления Покупателя.</p>
            <p>12.3 Покупатель обязуется:</p>
            <p>12.3.1 До момента оформления Заказа ознакомиться с содержанием Оферты на Сайте.</p>
            <p>12.3.2 Предоставлять достоверную информацию о себе (имя, контактные данные, адрес электронной почты) и
                адрес для доставки Товара.</p>
            <p>12.3.3 Принять и оплатить Товар в указанные сроки.</p>
            <p>12.4 Покупатель имеет право получать от Продавца всю интересующую его информацию по Товару, размещённому
                в интернет-магазине Продавца.</p>

            <b><p>13. Ответсвенность сторон и разрешение споров.</p></b>
            <p>13.1 Стороны несут ответственность за неисполнение или ненадлежащее исполнение обязательств,
                предусмотренных в настоящей Оферте, в соответствии с действующим законодательством РФ.</p>
            <p>13.2 В случае предоставления Покупателем недостоверных контактных данных, Продавец не несет
                ответственности за ненадлежащее исполнение Заказа.</p>
            <p>13.3 Продавец не несет ответственности, если ожидания Покупателя о потребительских свойствах Товара
                оказались не оправданны.</p>
            <p>13.4 Продавец не несет ответственности за частичное или полное неисполнение обязательств по доставке
                Товара, если они являются следствием форс-мажорных обстоятельств.</p>
            <p>13.5 Все споры и разногласия, возникающие при исполнении сторонами обязательств, указанных в Оферте,
                решаются путем переговоров. В ином случае, стороны имеют право обратиться за судебной защитой своих
                интересов по месту нахождения Продавца.</p>
            <p>13.6 В случае систематического отказа Покупателя от получения заказанных ранее Товаров, что
                приравнивается к злоупотреблению правом, Продавец вправе отказать Покупателю в доставке очередного
                Заказа.</p>

            <b><p>14. Форс-мажорные обстоятельства.</p></b>
            <p>14.1 Стороны освобождаются от ответственности за неисполнение или ненадлежащее исполнение обязательств по
                Договору на время действия непреодолимой силы. Под непреодолимой силой понимаются чрезвычайные и
                непреодолимые при данных условиях обстоятельства, препятствующие исполнению своих обязательств Сторонами
                по настоящему Договору. В том числе к ним относятся стихийные явления (землетрясения, наводнения и т.
                п.), обстоятельства общественной жизни (военные действия, чрезвычайные положения, забастовки, эпидемии и
                т.п.), запретительные меры государственных органов (запрещение перевозок, валютные ограничения,
                международные санкции запрета на торговлю и т. п.). В течение этого времени Стороны не имеют взаимных
                претензий, и каждая из Сторон принимает на себя свой риск последствия форс-мажорных обстоятельств.</p>

            <b><p>15. Реквизиты продавца.</p></b>
            <p>Общество с ограниченной ответственностью «Новый Инструментальный Завод ЗАРЯ», 454081, Челябинская область,
                город Челябинск, Товарный двор, 14, ИНН 7447310566, КПП 744701001, ОГРН 1237400004269,
                директор Францев Олег Виаленович.</p>
            <p>Банковские реквизиты: расчётный счёт № 40702810638040009689, в АО «Альфа-Банк» филиал «ЕКАТЕРИНБУРГСКИЙ»,
                корреспондентский счёт 30101810100000000964, БИК 046577964</p>
        </StyledPublicOfferAgreementPage>
    );
}

export default PublicOfferAgreementPage;