import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import StarSVG from "../icons/StarSVG";
import {Button} from "antd";
import {useSelector} from "react-redux";
import store from "../redux/store";
import {addFavoriteAction, getHotAction, getPopularAction} from "../redux/actions/pageActions";
import {ProductType, UserType} from "../utils/types";
import {Link} from "react-router-dom";
import {IsInFavorites} from "../utils/isInFavorites";
import no_img from "../images/no_img.png"
import {Autoplay, Navigation} from "swiper";
import {Swiper, SwiperSlide, useSwiper} from "swiper/react";
import {fillTill} from "../utils/fillTill";

const Photo = styled.img`
  display: flex;
  width: 182px;
  height: 182px;
  object-fit: contain;
  margin-bottom: 25px;
  align-self: center;
  user-select: none;
  pointer-events: none;
`;

const Actions = styled.div`
  display: flex;
  transition: all .2s;
  align-items: center;
  opacity: 0;
  max-height: 0;
  margin-top: 0;
  overflow: hidden;

  .ant-btn {
    background: #5B77CB;
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    padding: 8px 22px;
    height: auto;
    border: none;
    box-shadow: none;

    span {
      color: #FFFFFF;
    }

    &.cancel {
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      background: none;
      padding: 0;
      margin-left: 20px;

      span {
        text-decoration-line: underline;
        color: #C1C1C1;
      }
    }
  }
`;

const StyledCarouselItem = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 284px;
  border: 1px solid #E7E7E7;
  border-radius: 10px;
  padding: 32px 25px 18px 25px;
  position: relative;
  background: #fff;
  text-decoration: none;
  user-select: none;
  text-align: start;

  &:hover {
    ${Actions} {
      display: flex;
      max-height: 40px;
      margin-top: 18px;
      opacity: 1;
    }
  }


  .star {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    &:hover {
      path {
        stroke: none;
        fill: #A0A0A0;
      }
    }

    path {
      fill: transparent;
      stroke: #A0A0A0;
    }

    &.active {
      path {
        fill: #5B77CB;
        stroke: none;
      }
    }
  }
`;

const CountTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;

  color: #5B77CB;

  &.out {
    color: #F54844;
  }
`;

const Title = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  margin-bottom: 15px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
`;

const Price = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;

  color: #000000;

  span {
    margin-left: 10px;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    text-decoration-line: line-through;

    color: #C1C1C1;
  }
`;

export const StyledCarouselLine = styled.div`
  display: flex;
  height: 432px;

  .carouselSwiper {
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }

  .alice-carousel {
    margin: 0;
  }

  ul {
    li {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`;

function CarouselItem({Id, ImageUrls, ImageMain, Count, OldPrice, NewPrice, Name}: ProductType) {
    const user: UserType = useSelector((state: any) => state.loginReducer.userData);
    const localFavorites: number[] = useSelector((state: any) => state.pageReducer.localFavorites);


    const formatter = new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })

    return (
        <StyledCarouselItem to={`/catalog/${Id}`}>
            <Photo src={ImageUrls?.[ImageMain] ? `/${ImageUrls?.[ImageMain]?.replace("//", "/")}` : no_img}/>
            <CountTitle className={Count > 0 ? "" : "out"}>{Count > 0 ? "В наличии" : "Распродано"}</CountTitle>
            <Title>{Name}</Title>
            <Price>
                {formatter.format(NewPrice)}
                {OldPrice > 0 && <span>{formatter.format(OldPrice)}</span>}
            </Price>
            <StarSVG onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                store.dispatch(addFavoriteAction(user, [Id]))
            }}
                     className={`star ${IsInFavorites(user, localFavorites, Id) ? "active" : ""}`}/>
            <Actions>
                <Button onClick={(e) => {
                    // e.preventDefault();
                    // e.stopPropagation();
                }}>Купить</Button>
                <Button className={"cancel"}>Подробнее</Button>
            </Actions>
        </StyledCarouselItem>
    )
}

function CarouselLine({type, ...props}: { type: "hot" | "popular" } & any) {
    const popularItems: ProductType[] = useSelector((state: any) => state.pageReducer.popularItems);
    const hotItems: ProductType[] = useSelector((state: any) => state.pageReducer.hotItems);

    const [numberOfSlides, setNumberOfSlides] = useState(7);

    useEffect(() => {
        if (type === "popular")
            store.dispatch(getPopularAction())
        else
            store.dispatch(getHotAction())
    }, [type])

    const items = (type === "popular" ? popularItems : hotItems).map((el, index) => <CarouselItem key={index} {...el}/>)

    // const items = [
    //     <CarouselItem/>,
    //     <CarouselItem/>,
    //     <CarouselItem/>,
    //     <CarouselItem/>,
    //     <CarouselItem/>,
    //     <CarouselItem/>
    // ]

    return (
        <StyledCarouselLine {...props}>

            {items.length && <Swiper
                breakpoints={{
                    1920: {
                        slidesPerView: numberOfSlides
                    },
                    1000: {
                        slidesPerView: 4
                    },
                    600: {
                        slidesPerView: 2
                    },
                    360: {
                        slidesPerView: 1
                    }
                }}
                spaceBetween={22}

                // loop={true}
                // loopedSlides={100}
                className="carouselSwiper"
                loop={true}
                // rewind={true}
                passiveListeners={false}
                modules={[Autoplay]}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false
                }}
            >
                {/*{[...Array(14)].map((_, index) => {*/}
                {/*    console.log((index) % (items.length-1));*/}
                {/*    return <SwiperSlide>{items[(index - 1) % items.length]}</SwiperSlide>*/}
                {/*})}*/}
                {/*{_.assign([0,1,2,3,4], [...items,]).map(item=><SwiperSlide>{item}</SwiperSlide>)}*/}
                {items?.length && ((items?.length < numberOfSlides * 2) ? fillTill(items, numberOfSlides * 2).map((el, index) => {
                    return <SwiperSlide key={index}>{el}</SwiperSlide>
                }) : items.map((el, index) => <SwiperSlide key={index}>{el}</SwiperSlide>))}
            </Swiper>}

            {/*<AliceCarousel*/}
            {/*    // paddingRight={400}*/}
            {/*    animationType="fadeout"*/}
            {/*    animationDuration={3000}*/}
            {/*    disableButtonsControls*/}
            {/*    disableDotsControls*/}
            {/*    autoPlay*/}
            {/*    autoWidth*/}
            {/*    infinite*/}
            {/*    items={items}*/}
            {/*    mouseTracking*/}
            {/*/>*/}
        </StyledCarouselLine>
    );
}

export default CarouselLine;