import React from 'react';

function CameraSvg(props: any) {
    return (
        <svg {...props} width="92" height="74" viewBox="0 0 92 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M81.0167 73.1941H23.683C22.2474 73.1941 21.084 72.0307 21.084 70.595V36.2629C21.084 34.8272 22.2474 33.6638 23.683 33.6638H81.0167C82.4524 33.6638 83.6157 34.8272 83.6157 36.2629V70.595C83.6219 72.0307 82.4524 73.1941 81.0167 73.1941Z"
                fill="#616E7D"/>
            <path
                d="M17.9705 64.2522L2.37627 69.2027C1.20051 69.574 0 68.6953 0 67.4639V38.5897C0 37.3521 1.20051 36.4734 2.38246 36.8508L18.1314 41.8757C18.8926 42.117 19.4062 42.8287 19.4 43.6269L19.2515 62.5195C19.2329 63.3178 18.7193 64.0108 17.9705 64.2522Z"
                fill="#82BFAB"/>
            <path
                d="M86.35 35.3346L89.6793 34.2764C90.8303 33.9113 91.9999 34.7715 91.9999 35.9782V43.2246C91.9999 44.4313 90.8241 45.2914 89.6731 44.9263L86.3005 43.8496C85.5579 43.6144 85.0505 42.9151 85.0567 42.1354L85.1 37.024C85.1124 36.2505 85.6136 35.5698 86.35 35.3346Z"
                fill="#82BFAB"/>
            <path
                d="M38.095 30.4088C29.71 30.4088 22.8906 23.5894 22.8906 15.2044C22.8906 6.81938 29.71 0 38.095 0C46.4801 0 53.2995 6.81938 53.2995 15.2044C53.2995 23.5832 46.4801 30.4088 38.095 30.4088ZM38.095 3.0941C31.418 3.0941 25.9847 8.52733 25.9847 15.2044C25.9847 21.8815 31.418 27.3147 38.095 27.3147C44.7721 27.3147 50.2054 21.8815 50.2054 15.2044C50.2054 8.52733 44.7721 3.0941 38.095 3.0941Z"
                fill="#616E7D"/>
            <path
                d="M66.1289 30.5761C59.7674 30.5761 54.5879 25.3966 54.5879 19.0351C54.5879 12.6736 59.7674 7.49414 66.1289 7.49414C72.4904 7.49414 77.6699 12.6736 77.6699 19.0351C77.6699 25.3966 72.4904 30.5761 66.1289 30.5761ZM66.1289 10.5882C61.4692 10.5882 57.682 14.3754 57.682 19.0351C57.682 23.6948 61.4692 27.482 66.1289 27.482C70.7886 27.482 74.5758 23.6948 74.5758 19.0351C74.5758 14.3816 70.7886 10.5882 66.1289 10.5882Z"
                fill="#616E7D"/>
        </svg>
    );
}

export default CameraSvg;