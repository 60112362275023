import React, {useEffect, useLayoutEffect, useState} from 'react';
import styled, {css} from "styled-components";
import CommonHeader from "./CommonHeader";
import CommonFooter from "./CommonFooter";
import {Button, Form, Input, Modal} from "antd";
import FeedbackForm from "./FeedbackForm";
import NewCommonHeader from "./NewCommonHeader";

const StyledCommonLayout = styled.div<any>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
`;

const Content = styled.div`
  flex: 1 1 auto;
`;

function useWindowSize() {
    const [size, setSize] = useState();
    useLayoutEffect(() => {
        function updateSize() {
            // @ts-ignore
            setSize(window.innerWidth);
        }


        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


function CommonLayout({children}: any) {
    const width = useWindowSize();

    // useEffect(() => {
    //     if (width !== undefined) {
    //         let zoom = width / 1920;
    //         // @ts-ignore
    //         document.body.style.zoom = zoom;
    //     }
    // }, [width])

    return (
        <StyledCommonLayout>
            <FeedbackForm/>
            <NewCommonHeader/>
            <Content>
                {children}
            </Content>
            <CommonFooter/>
        </StyledCommonLayout>
    );
}

export default CommonLayout;