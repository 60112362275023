import React from 'react';

function ArrowDownSvg(props: any) {
    return (
        <svg {...props} width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.0415 1.375L4.7915 5.625L0.541504 1.375" stroke="#8C8C8C" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
}

export default ArrowDownSvg;