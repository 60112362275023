import React from 'react';

function IconArrowRightSvg(props: any) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 17L15 11L9 5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default IconArrowRightSvg;