import React from 'react';

function GroupSvg(props: any) {
    return (
        <svg {...props} width="143" height="77" viewBox="0 0 143 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.244141" y="48.8782" width="36.1565" height="28.1217" rx="2" fill="#616E7D"/>
            <rect x="53.1387" y="48.8782" width="36.1565" height="28.1217" rx="2" fill="#616E7D"/>
            <rect x="106.035" y="48.8782" width="36.1565" height="28.1217" rx="2" fill="#616E7D"/>
            <rect x="53.1387" width="36.1565" height="28.1217" rx="2" fill="#616E7D"/>
            <line x1="72.0469" y1="28.1216" x2="72.0469" y2="48.8781" stroke="#82BFAB" strokeWidth="3"/>
            <path d="M18.3203 35.8213L124.781 35.8213" stroke="#82BFAB" strokeWidth="3"/>
            <line x1="19.8203" y1="36.1565" x2="19.8203" y2="48.8782" stroke="#82BFAB" strokeWidth="3"/>
            <line x1="124.273" y1="36.8259" x2="124.273" y2="48.8781" stroke="#82BFAB" strokeWidth="3"/>
        </svg>
    );
}

export default GroupSvg;