import React from 'react';

function FeedbackSvg(props: any) {
    return (
        <svg {...props} width="104" height="90" viewBox="0 0 104 90" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M101.074 23.7044V64.498C101.074 68.831 98.4704 72.3028 93.2627 73.1707L88.923 73.1774V86.1966C88.923 87.0578 88.923 88.7937 87.1871 87.0578L78.9417 80.5549L69.8283 73.1774H37.7145C34.1358 73.1774 29.9699 69.1532 29.903 66.2339C29.6137 53.6092 29.903 27.7398 29.903 22.8301C29.903 19.656 33.9583 15.8863 37.7145 15.887C55.365 15.89 88.923 15.8922 93.2627 15.8916C96.7345 15.8912 101.074 19.3647 101.074 23.7044Z"
                fill="#616E7D" stroke="#82BFAB" strokeWidth="4"/>
            <path
                d="M2 9.81741V50.611C2 54.944 4.60383 58.4158 9.81148 59.2837L14.1512 59.2905V72.3096C14.1512 73.1708 14.1512 74.9067 15.8871 73.1708L24.1325 66.668L33.2459 59.2905H65.3598C68.9384 59.2905 73.1043 55.2662 73.1712 52.3469C73.4606 39.7223 73.1712 13.8529 73.1712 8.94311C73.1712 5.76902 69.116 1.99935 65.3598 2C47.7092 2.00305 14.1512 2.00519 9.81148 2.00466C6.33971 2.00424 2 5.4777 2 9.81741Z"
                fill="#616E7D" stroke="#82BFAB" strokeWidth="4"/>
        </svg>
    );
}

export default FeedbackSvg;