export function move(array: any[], oldIndex: number, newIndex: number) {
    const length = array.length

    if (
        length === 0 ||
        oldIndex === newIndex ||
        oldIndex < 0 ||
        oldIndex >= length
    ) {
        return array
    }

    if (newIndex >= length) {
        newIndex = length - 1
    } else if (newIndex < 0) {
        newIndex = 0
    }

    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0])

    return array
}