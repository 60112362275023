import React, {useState} from 'react';
import styled from "styled-components";
import {Button, Form, Input, Space, Upload, UploadFile, UploadProps} from "antd";
import AttachmentSVG from "../../icons/AttachmentSVG";
import FileIconSVG from "../../icons/FileIconSVG";
import {filesize} from "filesize";
import CloseSVG from "../../icons/CloseSVG";
import {StyledButton} from "../../components/StyledButton";
import {sendFeedbackAction} from "../../redux/actions/adminActions";
import store from "../../redux/store";
import History from "../../utils/history";

const AttachmentButton = styled(Button)`
    align-items: center;
    display: flex;

    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #898989;

    svg {
        margin-right: 12px;
    }
`;

const FormWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    padding: 50px 65px 60px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    max-width: 850px;
    width: 100%;
    margin-bottom: 25px;

    h1 {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 40px;

        /* серый */

        color: #898989;
    }

    .ant-form {
        width: 100%;
    }

    .ant-input {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        min-height: 230px;

        /* Dark/3-Dark */

        color: #898989;
    }
`;

const FileInfo = styled.div`
    display: flex;
    flex-direction: column;

    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    color: #898989;

    span {
        &:first-child {

        }
    }
`;

const FileList = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 65px;
    gap: 30px;
`;

const File = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 20px;

    .close-btn {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        cursor: pointer;

        &:hover {
            line {
                stroke: #ff8989;
            }
        }
    }

    svg {
        margin-right: 14px;
    }
`;

const StyledFeedbackPage = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    padding-top: 25px;
    overflow: auto;
`;

function FeedbackPage() {

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [formRef] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange: UploadProps['onChange'] = (info) => {
        let newFileList = [...info.fileList];
        // 2. Read from response and show file link
        newFileList = newFileList.map((file) => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            return file;
        });

        setFileList(newFileList);
    };

    return (
        <StyledFeedbackPage>
            <FormWrap>
                <h1>Форма обратной связи</h1>
                <Form form={formRef} onFinish={({text}) => {
                    setLoading(true);
                    store.dispatch(sendFeedbackAction(text, fileList)).then(() => {
                        formRef.resetFields();
                        setFileList([])
                    }).finally(() => {
                        setLoading(false);
                    })
                }}>
                    <Form.Item name={"text"} rules={[{
                        required: true,
                        message: "Поле не может быть пустым!"
                    }]}>
                        <Input.TextArea placeholder={"Введите текст обратной связи"}/>
                    </Form.Item>
                    <Form.Item style={{margin: 0}}>
                        <Upload
                            fileList={fileList}
                            customRequest={() => {
                            }}
                            multiple
                            onChange={handleChange}
                            showUploadList={false}
                        >
                            <AttachmentButton type="link"><AttachmentSVG/> Прикрепить файл</AttachmentButton>
                        </Upload>
                        {fileList?.length > 0 && <FileList>
                            {fileList?.map((file, index) => <File>
                                <CloseSVG className={"close-btn"}
                                          onClick={() => setFileList(fileList.filter((_, index_) => index_ !== index))}/>
                                <FileIconSVG/>
                                <FileInfo>
                                    <span>{file.name}</span>
                                    {/*
                                    // @ts-ignore */}
                                    <span>{filesize(file?.size || 0, {base: 2, standard: "jedec"})}</span>
                                </FileInfo>
                            </File>)}
                        </FileList>}
                    </Form.Item>
                    <Form.Item style={{textAlign: "center", margin: 0, marginTop: fileList?.length <= 0 ? 40 : 0}}>
                        <Space size={14}>
                            <StyledButton disabled={loading} htmlType={"submit"}>Отправить</StyledButton>
                            <StyledButton className={"cancel"} onClick={() => {
                                formRef.resetFields();
                                setFileList([])
                                History.push("/admin/home")
                            }}>Отменить</StyledButton>
                        </Space>
                    </Form.Item>
                </Form>
            </FormWrap>
        </StyledFeedbackPage>
    );
}

export default FeedbackPage;