import React from 'react';

function MailMenuSvg(props: any) {
    return (
        <svg {...props} width="94" height="64" viewBox="0 0 94 64" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 63V1H47H93V63H1Z" fill="#616E7D"/>
            <path d="M1 1V63H93V1M1 1H47H93M1 1L47 34.1485L93 1" stroke="#82BFAB" strokeWidth="2"/>
        </svg>
    );
}

export default MailMenuSvg;