import styled from "styled-components";
import {Checkbox} from "antd";

export const StyledCheckbox = styled(Checkbox)`

  .ant-checkbox {
    .ant-checkbox-inner {
      border: 1px solid #898989 !important;
      border-radius: 2px;
    }
  }
  
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border: none !important;
      background-color: #5B77CB !important;
      &:after{
        inset-inline-start: 25%;
      }
    }

    &:after {
      display: none;
    }
  }
`;
