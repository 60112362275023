import {
    CATALOG_OPEN_CHANGE,
    CHANE_FEEDBACK,
    GET_CART,
    GET_COMMON_ORDERS,
    GET_FAVORITES,
    GET_GROUP,
    GET_GROUPS,
    GET_HOT_ITEMS,
    GET_ITEM,
    GET_ITEMS_BY_GROUP,
    GET_LOCAL_CART,
    GET_LOCAL_CART_ITEMS,
    GET_LOCAL_FAVORITES,
    GET_POPULAR_ITEMS, GET_SEARCH_RESULT,
    GET_VIDEOS,
    LOGIN_SUCCESS
} from "../actionTypes";
import axios from "axios";
import {api} from "../../config";
import {message} from "antd";
import store from "../store";
import {getUserAction} from "./loginActions";
import * as _ from "lodash";
import {RoleType} from "../../utils/types";

export function getVideosAction() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/videos?count=999`).then(({data}) => {
                dispatch({type: GET_VIDEOS, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getGroupsAction() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/groups?full=true`).then(({data}) => {
                dispatch({type: GET_GROUPS, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function searchAction(string, signal) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.get(`${api}/items?count=10&search=${string}`, {
                signal: signal
            }).then(({data}) => {
                dispatch({type: GET_SEARCH_RESULT, payload: data?.Data || []})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getGroupAction(Id) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            if (Id === null) {
                dispatch({type: GET_GROUP, payload: {}})
                res();
                return
            }
            axios(`${api}/groups/${Id}`).then(({data}) => {
                dispatch({type: GET_GROUP, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getItemsByGroupAction(Id, Page, filters = {}) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/items/group/${Id}`, {
                params: {
                    count: 10,
                    page: Page,
                    ...filters
                }
            }).then(({data}) => {
                dispatch({type: GET_ITEMS_BY_GROUP, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getItemsAction(Page, params = {}) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/items`, {
                params: {
                    count: 10,
                    page: Page,
                    ...params
                }
            }).then(({data}) => {
                dispatch({type: GET_ITEMS_BY_GROUP, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getItemAction(Id) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/items/${Id}`).then(({data}) => {
                dispatch({type: GET_ITEM, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getFavoritesAction(Ids) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            if (Ids?.length > 0) {
                axios(`${api}/items/${Ids.join(",")}`).then(({data}) => {
                    dispatch({type: GET_FAVORITES, payload: Ids?.length === 1 ? [data] : data})
                    res();
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                    rej();
                })
            } else {
                dispatch({type: GET_FAVORITES, payload: []})
                res();
            }
        })
    }
}

export function getLocalCartItemsAction(Ids) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            if (Ids?.length > 0) {
                axios(`${api}/items/${Ids.join(",")}`).then(({data}) => {
                    dispatch({type: GET_LOCAL_CART_ITEMS, payload: Ids?.length === 1 ? [data] : data})
                    res();
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                    rej();
                })
            } else {
                dispatch({type: GET_LOCAL_CART_ITEMS, payload: []})
                res();
            }
        })
    }
}

export function getPopularAction() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/items/popular`).then(({data}) => {
                dispatch({type: GET_POPULAR_ITEMS, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getHotAction() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/items/hot`).then(({data}) => {
                dispatch({type: GET_HOT_ITEMS, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

// export function putCartAction(Id) {
//     return (dispatch) => {
//         return new Promise((res, rej) => {
//             axios.post(`${api}/cart/${Id}`).then(({data}) => {
//                 dispatch(getCartAction());
//                 message.success("Товар успешно добавлен в корзину!")
//                 res();
//             }).catch(({response}) => {
//                 message.error(response?.data || "Error")
//                 rej();
//             })
//         })
//     }
// }

export function sendFeedbackAction({text, email, name}) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            const formData = new FormData();
            formData.append('text', text);
            formData.append('email', email);
            formData.append('name', name);

            axios.post(`${api}/feedback`, formData).then(({data}) => {
                message.warning("Обращение успешно отправлено!");
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}


export function getCartAction() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/cart`).then(({data}) => {
                dispatch({type: GET_CART, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function syncFavoriteAction(user) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            let localFavorites = JSON.parse(localStorage.getItem("Favorites") || "false") || [];
            if (localFavorites.length > 0) {
                let Meta = {
                    ...(user?.Meta || {}),
                    Favorites: _.uniq([...(user?.Meta?.Favorites || []), ...localFavorites])
                }
                axios.post(`${api}/user`, {
                    ...user,
                    Meta: Meta
                }).then(({data}) => {
                    store.dispatch(getUserAction())
                    res();
                    localStorage.removeItem("Favorites");
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                    rej();
                })
            }
        })
    }
}

export function syncCartAction() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            let localCart = JSON.parse(localStorage.getItem("Cart") || "false") || [];
            if (localCart.length > 0) {
                let cartString = "";
                localCart.forEach((el, index) => {
                    for (let i = 0; i < el.Count; i++) {
                        cartString += el?.Id?.toString() + (((localCart.length - 1 === index) && i === el.Count - 1) ? "" : ",")
                    }
                })

                axios.post(`${api}/cart/${cartString}`).then(({data}) => {
                    dispatch(getCartAction());
                    res();
                    localStorage.removeItem("Cart");
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                })
            }
        })
    }
}

// export function remCartAction(Id) {
//     return (dispatch) => {
//         return new Promise((res, rej) => {
//             axios.delete(`${api}/cart/${Id}`).then(({data}) => {
//                 dispatch(getCartAction());
//                 res();
//             }).catch(({response}) => {
//                 message.error(response?.data || "Error")
//                 rej();
//             })
//         })
//     }
// }

export function clearCartAction() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.delete(`${api}/cart`).then(({data}) => {
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function makeOrderAction() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/cart/order`).then(({data}) => {
                dispatch(getCartAction())
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function makeUnauthorizedOrderAction({Phone, Email}) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/orders/fast`, {
                Phone: Phone,
                Email: Email,
                Positions: store.getState()?.pageReducer?.localCart
            }).then(({data}) => {
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function makeSoloOrderAction(data) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/orders`, data).then(({data}) => {
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function getOrdersAction() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/orders?count=9999`).then(({data}) => {
                dispatch({
                    type: GET_COMMON_ORDERS,
                    payload: store.getState()?.loginReducer?.userData?.Role === RoleType.admin ? data.Data : data
                })
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function changeFeedbackOpenAction(value) {
    return (dispatch) => {
        dispatch({type: CHANE_FEEDBACK, payload: value})
    }
}

export function editUserAction(fields) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/user`, fields).then(({data}) => {
                dispatch({type: LOGIN_SUCCESS, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function uploadPhotoAction(file) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            const formData = new FormData();
            formData.append("file", file);

            axios.post(`${api}/user/photo`, formData).then(({data}) => {
                dispatch({type: LOGIN_SUCCESS, payload: data})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function initLocalStorageAction() {
    return (dispatch) => {
        let localFavorites = JSON.parse(localStorage.getItem("Favorites") || "false") || [];
        let localCart = JSON.parse(localStorage.getItem("Cart") || "false") || [];

        dispatch({type: GET_LOCAL_FAVORITES, payload: localFavorites})
        dispatch({type: GET_LOCAL_CART, payload: localCart})
    }
}

export function changeCatalogOpenAction(value) {
    return (dispatch) => {
        dispatch({type: CATALOG_OPEN_CHANGE, payload: value})
    }
}

export function putCartAction(user, Ids, hideMessage = false, count) {
    return (dispatch) => {
        // return new Promise((res, rej) => {
        //     axios.post(`${api}/cart/${Id}`).then(({data}) => {
        //         dispatch(getCartAction());
        //         message.success("Товар успешно добавлен в корзину!")
        //         res();
        //     }).catch(({response}) => {
        //         message.error(response?.data || "Error")
        //         rej();
        //     })
        // })

        return new Promise((res, rej) => {

            if (!user?.Login) {
                let localCart = JSON.parse(localStorage.getItem("Cart") || "false") || [];
                Ids.forEach(Id => {
                    if (localCart.some(el => el.Id === Id)) {
                        let item = localCart.find(el => el.Id === Id);
                        const {Count} = item;

                        if (count !== undefined) {
                            localCart = [...localCart.filter(el => el.Id !== Id), {
                                ...item,
                                Id: Id,
                                Count: Count + count
                            }]
                        } else {
                            localCart = [...localCart.filter(el => el.Id !== Id), {...item, Id: Id, Count: Count + 1}]
                        }

                    } else {
                        localCart = [...localCart, {Id: Id, Count: 1, CreatedDate: new Date()}]
                    }
                    message.success(`Товар${Ids.length > 1 ? "ы" : ""} успешно добавлен${Ids.length > 1 ? "ы" : ""} в корзину!`)
                })

                localStorage.setItem("Cart", JSON.stringify(localCart));
                dispatch({type: GET_LOCAL_CART, payload: localCart})
                res();
            } else {
                const apiUrl = count !== undefined ? `${api}/cart/${Ids.join(",")}/${count}` : `${api}/cart/${Ids.join(",")}`

                axios.post(apiUrl).then(({data}) => {
                    dispatch(getCartAction());
                    !hideMessage && message.success(`Товар${Ids.length > 1 ? "ы" : ""} успешно добавлен${Ids.length > 1 ? "ы" : ""} в корзину!`)
                    res();
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                    rej();
                })

                // axios.post(`${api}/user`, {
                //     ...user,
                //     Meta: Meta
                // }).then(({data}) => {
                //     dispatch({type: LOGIN_SUCCESS, payload: data})
                //     res();
                // }).catch(({response}) => {
                //     message.error(response?.data || "Error")
                //     rej();
                // })
            }
        })


    }
}

export function addFavoriteAction(user, Ids) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            let Meta = {};

            if (!user?.Login) {
                let localFavorites = JSON.parse(localStorage.getItem("Favorites") || "false") || [];
                Ids.forEach(Id => {
                    if (localFavorites.includes(Id)) {
                        localFavorites = localFavorites.filter(el => el !== Id)
                    } else {
                        localFavorites = [...localFavorites, Id]
                    }
                })


                localStorage.setItem("Favorites", JSON.stringify(localFavorites));
                dispatch({type: GET_LOCAL_FAVORITES, payload: localFavorites})
                res();

            } else {
                Meta = {...(user?.Meta || {}), Favorites: (user?.Meta?.Favorites || [])}
                Ids.forEach((Id) => {
                    if (user?.Meta?.Favorites?.includes(Id)) {
                        Meta = {...Meta, Favorites: (Meta?.Favorites || []).filter(el => el !== Id)}
                    } else {
                        Meta = {...Meta, Favorites: [...(Meta?.Favorites || []), Id]}
                    }
                })


                axios.post(`${api}/user`, {
                    ...user,
                    Meta: Meta
                }).then(({data}) => {
                    dispatch({type: LOGIN_SUCCESS, payload: data})
                    res();
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                    rej();
                })
            }
        })
    }
}

// export function remCartAction(Id) {
//     return (dispatch) => {
//         return new Promise((res, rej) => {
//             axios.delete(`${api}/cart/${Id}`).then(({data}) => {
//                 dispatch(getCartAction());
//                 res();
//             }).catch(({response}) => {
//                 message.error(response?.data || "Error")
//                 rej();
//             })
//         })
//     }
// }

export function remCartAction(user, Ids, count) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            if (!user?.Login) {
                let localCart = JSON.parse(localStorage.getItem("Cart") || "false") || [];
                Ids.forEach(Id => {
                    if (localCart.some(el => el.Id === Id)) {
                        const item = localCart.find(el => el.Id === Id)
                        const {Count} = item
                        if (count !== undefined) {
                            if (Count > 1 && Count - count >= 1)
                                localCart = [...localCart.filter(el => el.Id !== Id), {
                                    ...item,
                                    Id: Id,
                                    Count: Count - count
                                }]
                            else
                                localCart = localCart.filter(el => el.Id !== Id)
                        } else {
                            if (Count > 1)
                                localCart = [...localCart.filter(el => el.Id !== Id), {
                                    ...item,
                                    Id: Id,
                                    Count: Count - 1
                                }]
                            else
                                localCart = localCart.filter(el => el.Id !== Id)
                        }
                    }
                })

                localStorage.setItem("Cart", JSON.stringify(localCart));
                dispatch({type: GET_LOCAL_CART, payload: localCart})
                res();
            } else {
                const apiUrl = count !== undefined ? `${api}/cart/${Ids.join(",")}/${count}` : `${api}/cart/${Ids.join(",")}`

                axios.delete(apiUrl).then(({data}) => {
                    dispatch(getCartAction());
                    res();
                }).catch(({response}) => {
                    message.error(response?.data || "Error")
                    rej();
                })
            }
        })


    }
}
