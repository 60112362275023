import React from 'react';

function ArrowsSvg({value, ...props}: any) {
    return (
        <svg {...props} width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.4697 18.5303C16.7626 18.8232 17.2374 18.8232 17.5303 18.5303L22.3033 13.7574C22.5962 13.4645 22.5962 12.9896 22.3033 12.6967C22.0104 12.4038 21.5355 12.4038 21.2426 12.6967L17 16.9393L12.7574 12.6967C12.4645 12.4038 11.9896 12.4038 11.6967 12.6967C11.4038 12.9896 11.4038 13.4645 11.6967 13.7574L16.4697 18.5303ZM16.25 1L16.25 18L17.75 18L17.75 1L16.25 1Z"
                fill={value === "asc" ? "#775DA6" : "#d9d9d9"}/>
            <path
                d="M5.46967 0.46967C5.76256 0.176777 6.23744 0.176777 6.53033 0.46967L11.3033 5.24264C11.5962 5.53553 11.5962 6.01041 11.3033 6.3033C11.0104 6.59619 10.5355 6.59619 10.2426 6.3033L6 2.06066L1.75736 6.3033C1.46447 6.59619 0.989592 6.59619 0.696699 6.3033C0.403805 6.01041 0.403805 5.53553 0.696699 5.24264L5.46967 0.46967ZM5.25 18L5.25 1L6.75 1L6.75 18L5.25 18Z"
                fill={value === "desc" ? "#775DA6" : "#d9d9d9"}/>
        </svg>
    );
}

export default ArrowsSvg;