import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Form, Input, message, Modal, Select, Space, Spin, Table, TableProps} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import EditSVG from "../../icons/EditSVG";
import {StyledButton} from "../../components/StyledButton";
import {StyledCheckbox} from "../../components/StyledCheckbox";
import {useSelector} from "react-redux";
import {
    changeUserStateAction,
    createUserAction,
    editItemAction,
    editUserAction, getCatalogAction,
    getUsersAction,
    removeUserAction
} from "../../redux/actions/adminActions";
import store from "../../redux/store";
import {PaginationType, ProductType, SortingOrderType, UsersSortType, UserType} from "../../utils/types";
import moment from "moment";
import ArrowsSVG from "../../icons/ArrowsSVG";
import SimpleModal from "../../components/SimpleModal";

const {Option} = Select;

const Avatar = styled.img`
  width: 51px;
  height: 51px;
  min-width: 51px;
  min-height: 51px;
  border-radius: 100%;
  object-fit: cover;
`;
const AvatarBlank = styled.div`
  width: 51px;
  height: 51px;
  min-width: 51px;
  min-height: 51px;
  border-radius: 100%;
  object-fit: cover;
  background: #D9D9D9;
`;

const StyledTable = styled<any>(Table)`
  max-width: 1200px;
  width: 100%;

  .ant-table {
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    margin-bottom: 150px;

    table {
      .ant-table-thead {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 96%;
          transform: translate(-50%, 0);
          left: 50%;
          border-bottom: 1px solid #DCDCDC;
          z-index: 99;
        }

        tr {


          th {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            color: #898989;
            background: #fff;
            border: none;

            &::before {
              display: none;
            }
          }
        }
      }

      .ant-table-tbody {
        tr {
          &.blocked {
            color: #e33333;
          }

          &:nth-child(even) {
            background: #F9F9F9;
          }
        }
      }
    }
  }
`;

const StyledInputSearch = styled(Input)`
  max-width: 230px;
  width: 100%;

  border: 1px solid #DCDCDC;
  border-radius: 8px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #898989;

  .ant-select-selector {

    border: 1px solid #DCDCDC !important;
    border-radius: 8px;
    height: 41px !important;
    align-items: center;

  }
`;

const AddUserButton = styled(StyledButton)`
  margin-left: auto;
`;

const Top = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 35px;

  .sortOrder {
    cursor: pointer;
    user-select: none;
  }
`;

const StyledSelect = styled(Select)`
  max-width: 230px;
  width: 100%;
  text-align: center;

  margin-left: 16px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  display: flex;
  align-items: center;

  color: #898989;

  .ant-select-selection-item {
    opacity: 0.7;
  }

  .ant-select-selector {
    border: 1px solid #DCDCDC !important;
    border-radius: 8px;
    height: 41px !important;
    align-items: center;
  }
`;

const DeleteTitle = styled.div`
  display: flex;
  align-items: center;

  .ant-checkbox {
    margin-right: 8px;
    top: -1px;
  }
`;

const TableWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  position: relative;

  .ant-btn {
    position: absolute;
    bottom: 100px;
    right: 0;
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-space {
      margin-top: 25px;
      margin-bottom: 40px;
    }

    .ant-form {
      width: 100%;
      max-width: 450px;
    }

    h1 {
      margin-bottom: 34px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin-top: 40px;
      color: #898989;
    }
  }
`;

const StyledUsersPage = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  .red {
    color: #e33434;
  }

  .removeModalWrap {
    top: 30%;
  }

  .ant-modal-wrap {
    display: flex;
    margin: 10px 0;

    .ant-modal {
      padding-bottom: 0;
      top: 0;
    }

    .removeModal {
      width: auto !important;

      .ant-modal-content {
        padding: 40px 40px 30px 40px;
      }

      h1 {
        margin-bottom: 25px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        color: #898989;
      }
    }
  }

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    display: flex;
    align-items: center;

    color: #000000;
  }

  .sorting {
    .ant-select-item {
      .ant-select-item-option-content {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #898989;

        opacity: 0.7;
      }
    }
  }
`;

function UsersPage() {
    const users: PaginationType<UserType[]> = useSelector((state: any) => state.adminReducer.users);

    const [page, setPage] = useState<number>(1);
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);
    const [modalCreateOpen, setModalCreateOpen] = useState<boolean>(false);

    const [editId, setEditId] = useState<string | undefined>(undefined);

    const [formEditRef] = Form.useForm();
    const [formCreateRef] = Form.useForm();
    const [searchValue, setSearchValue] = useState<string>("");
    const controller = new AbortController();

    const [sortValue, setSortValue] = useState<UsersSortType | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<SortingOrderType>(SortingOrderType.asc);


    useEffect(() => {
        if (editId !== undefined) {
            setModalEditOpen(true)
            formEditRef.setFieldsValue({...users.Data.find(el => el.Login === editId), Password: undefined})
        } else if (modalEditOpen) {
            setModalEditOpen(false);
        }
    }, [editId])

    useEffect(() => {
        setPage(1);
    }, [searchValue])

    useEffect(() => {
        store.dispatch(getUsersAction(page, searchValue, sortValue, sortOrder, controller.signal));

        return () => {
            controller.abort();
        }
    }, [page, sortValue, sortOrder, searchValue])

    const handleChangePage = (Page: number) => {
        // console.log(Page);
        setPage(Page);
    }

    const handleDelete = () => {
        let promises: any = [];
        deleteIds.map(el => promises = [...promises, store.dispatch(removeUserAction(el))])
        Promise.all(promises).then(() => {
            message.warning(deleteIds.length > 1 ? "Пользователи успешно удалены!" : "Пользователь успешно удален!")
            store.dispatch(getUsersAction(page, searchValue, sortValue, sortOrder, controller.signal))
            setDeleteIds([]);
            setRemovedAFew(deleteIds.length > 1);
            setDeleteConfirmOpen(false);
            setDeleteSuccessful(true);
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const columns: ColumnsType<UserType> = [
        {
            title: 'Фото',
            dataIndex: 'PhotoUrl',
            key: 'PhotoUrl',
            render: (text) => text !== undefined && text !== "" ?
                <Avatar src={`/${text?.replace("//", "/")}`} alt={"ava"}/> : <AvatarBlank/>,
        },
        {
            title: 'Имя',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: 'Почта',
            dataIndex: 'Email',
            key: 'Email',
        },
        {
            title: 'Дата регистрации',
            dataIndex: 'CreatedDate',
            key: 'CreatedDate',
            render: (text, record) => <span>{moment(text).format("DD/MM/YYYY")}</span>,
        },
        {
            title: 'Редактировать',
            render: (_, record) => <EditSVG style={{cursor: "pointer"}} onClick={() => {
                setEditId(record.Login);
            }}/>,
        },
        {
            title: 'Заблокировать',
            dataIndex: 'State',
            render: (_, record) => {
                return editLoading ? <Spin/> : <StyledCheckbox checked={!_} onClick={() => {
                    setEditLoading(true)
                    store.dispatch(changeUserStateAction(record.Login, !_)).then(() => {
                        store.dispatch(getUsersAction(page, searchValue, sortValue, sortOrder, controller.signal));
                        if (_)
                            setBlockedModal(true);
                    }).finally(() => {
                        setEditLoading(false);
                    })
                }
                }/>
            },
        },
        {
            title: <DeleteTitle><StyledCheckbox
                checked={users?.Data?.map(el => el.Login)?.every(el => deleteIds.includes(el))}
                onClick={({target: {checked}}: any) => {
                    if (checked)
                        setDeleteIds(deleteIds => [...deleteIds, ...users?.Data.map(el => el.Login)])
                    else
                        setDeleteIds(deleteIds => deleteIds.filter(el => !users?.Data.map(el => el.Login)?.includes(el)))
                }}/> Удалить</DeleteTitle>,
            render: (_, record) => <StyledCheckbox checked={deleteIds.indexOf(record.Login) !== -1}
                                                   onClick={({target: {checked}}: any) => setDeleteIds(values => {
                                                       if (checked)
                                                           return [...values, record.Login]
                                                       else
                                                           return values.filter(el => el !== record.Login)
                                                   })}/>,
        },
    ];
    const [editLoading, setEditLoading] = useState<boolean>(false);

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
    const [deleteSuccessful, setDeleteSuccessful] = useState<boolean>(false);
    const [removedAFew, setRemovedAFew] = useState<boolean>(false);
    const [blockedModal, setBlockedModal] = useState<boolean>(false);

    return (
        <StyledUsersPage>
            <SimpleModal wrapClassName={"removeModalWrap"}
                         getContainer={() => document.querySelector(`.${StyledUsersPage.styledComponentId}`)!}
                         open={blockedModal} onCancel={() => setBlockedModal(false)}>
                <span className="red">Пользователь заблокирован!</span>
            </SimpleModal>
            <SimpleModal open={deleteSuccessful} onCancel={() => setDeleteSuccessful(false)}>
                {removedAFew ? "Пользователи успешно удалены!" : "Пользователь успешно удален!"}
            </SimpleModal>
            <SimpleModal onCancel={() => setDeleteConfirmOpen(false)} closable={false} wrapClassName={"removeModalWrap"}
                         open={deleteConfirmOpen} className={"removeModal"}
                         getContainer={() => document.querySelector(`.${StyledUsersPage.styledComponentId}`)!}>
                <h1>{deleteIds.length > 1 ? `Удалить пользователей?` : `Удалить пользователя - ${deleteIds[0]}?`}</h1>
                <Space size={14}>
                    <StyledButton onClick={handleDelete}>Подтвердить</StyledButton>
                    <StyledButton onClick={() => setDeleteConfirmOpen(false)}
                                  className={"cancel"}>Отменить</StyledButton>
                </Space>
            </SimpleModal>
            <StyledModal forceRender footer={null} closable={false} open={modalCreateOpen}
                         onCancel={() => setModalCreateOpen(false)}>
                <h1>Добавить пользователя</h1>
                <Form form={formCreateRef} onFinish={(fields) => {
                    store.dispatch(createUserAction(fields)).then(() => {
                        setModalCreateOpen(false);
                        store.dispatch(getUsersAction(page, searchValue, sortValue, sortOrder, controller.signal));
                        formCreateRef.resetFields();
                    })
                }}>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите Логин!',
                        },
                    ]} name={"Login"}>
                        <Input placeholder={"Логин"}/>
                    </Form.Item>
                    <Form.Item name={"Name"}>
                        <Input placeholder={"Имя"}/>
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            type: 'email',
                            message: 'Введен неверный E-mail!',
                        }
                    ]} name={"Email"}>
                        <Input placeholder={"E-mail"}/>
                    </Form.Item>
                    <Form.Item hasFeedback rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите ваш пароль!',
                        },
                    ]} name={"Password"}>
                        <Input.Password placeholder={"Пароль"}/>
                    </Form.Item>
                    <Form.Item name={"confirm"} hasFeedback dependencies={['Password']} rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, подтвердите свой пароль!',
                        },
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('Password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Два введенных вами пароля не совпадают!'));
                            },
                        }),
                    ]}>
                        <Input.Password placeholder={"Подтвердите пароль"}/>
                    </Form.Item>
                </Form>
                <Space size={14}>
                    <StyledButton onClick={() => formCreateRef.submit()}>Сохранить</StyledButton>
                    <StyledButton onClick={() => setModalCreateOpen(false)} className={"cancel"}>Отменить</StyledButton>
                </Space>
            </StyledModal>
            <StyledModal forceRender footer={null} closable={false} open={modalEditOpen}
                         onCancel={() => setEditId(undefined)}>
                <h1>Изменить данные</h1>
                <Form form={formEditRef} onFinish={(fields) => {
                    store.dispatch(editUserAction(editId, fields)).then(() => {
                        setEditId(undefined);
                        store.dispatch(getUsersAction(page, searchValue, sortValue, sortOrder, controller.signal));
                        formEditRef.resetFields();
                    })
                }}>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите Логин!',
                        },
                    ]} name={"Login"}>
                        <Input placeholder={"Логин"}/>
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите Имя!',
                        },
                    ]} name={"Name"}>
                        <Input placeholder={"Имя"}/>
                    </Form.Item>
                    <Form.Item rules={[
                        {
                            type: 'email',
                            message: 'Введен неверный E-mail!',
                        }
                    ]} name={"Email"}>
                        <Input placeholder={"E-mail"}/>
                    </Form.Item>
                    <Form.Item hasFeedback rules={[]} name={"Password"}>
                        <Input.Password placeholder={"Пароль"}/>
                    </Form.Item>
                    <Form.Item name={"confirm"} hasFeedback dependencies={['Password']} rules={[
                        ({getFieldValue}) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('Password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Два введенных вами пароля не совпадают!'));
                            },
                        }),
                    ]}>
                        <Input.Password placeholder={"Подтвердите пароль"}/>
                    </Form.Item>
                </Form>
                <Space size={14}>
                    <StyledButton onClick={() => formEditRef.submit()}>Сохранить</StyledButton>
                    <StyledButton onClick={() => setEditId(undefined)} className={"cancel"}>Отменить</StyledButton>
                </Space>
            </StyledModal>
            <Top>
                <StyledInputSearch value={searchValue} placeholder={"Поиск пользователя"}
                                   onChange={({target: {value}}) => {
                                       setSearchValue(value);
                                   }}/>
                <Space size={30}>
                    <StyledSelect popupClassName={"sorting"}
                                  allowClear
                                  value={sortValue}
                                  onChange={(value: any) => {
                                      setSortValue(value);
                                  }}
                                  getPopupContainer={() => document.querySelector(`.${StyledUsersPage.styledComponentId}`)!}
                                  placeholder={"Порядок по умолчанию"}>
                        <Option value={UsersSortType.name}>По имени</Option>
                        <Option value={UsersSortType.email}>По почте</Option>
                        <Option value={UsersSortType.date}>По дате</Option>
                    </StyledSelect>
                    <ArrowsSVG className={"sortOrder"}
                               value={sortOrder}
                               onClick={() => setSortOrder(sortOrder => sortOrder === SortingOrderType.asc ? SortingOrderType.desc : SortingOrderType.asc)}/>
                </Space>
                <AddUserButton onClick={() => setModalCreateOpen(true)}>+Добавить пользователя</AddUserButton>
            </Top>
            <TableWrap>
                <StyledTable<(props: TableProps<UserType>) => JSX.Element>
                    columns={columns}
                    dataSource={users.Data}
                    rowKey={(record: UserType) => record.Login}
                    rowClassName={(record: UserType) => record.State ? "" : "blocked"}
                    pagination={{
                        current: users.Page,
                        total: users.Total,
                        pageSize: users.Count,
                        onChange: handleChangePage
                    }}/>
                <StyledButton disabled={deleteIds.length <= 0}
                              onClick={() => setDeleteConfirmOpen(true)}>Удалить {deleteIds.length > 1 ? "пользователей" : "пользователя"}</StyledButton>
            </TableWrap>
        </StyledUsersPage>
    );
}

export default UsersPage;