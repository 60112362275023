import React from 'react';

function MinusSvg(props:any) {
    return (
        <svg {...props} width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1H15" stroke="#151515" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
        </svg>
    );
}

export default MinusSvg;