import React from 'react';
import styled from "styled-components";
import Breadcrumbs from "../../components/Breadcrumbs";

const StyledProcessingPersonalDataPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 65px 18.75% 135px 18.75%;


  b {
    margin-top: 20px;
  }

  p, h1, h2 {
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
    padding: 0;
    color: #000000;
  }

  h1 {
    &:first-of-type {
      margin-top: 65px;
    }

    font-weight: 600;
    margin-bottom: 20px;
  }

  h2 {
    font-weight: 400;
    margin-bottom: 20px;
  }

  h1, h2 {
    text-align: center;
  }

  @media screen and (max-width: 1919px) {
    padding: 65px 20px 135px 20px;
  }
`;

function ProcessingPersonalDataPage() {
    return (
        <StyledProcessingPersonalDataPage>
            <Breadcrumbs/>

            <h1>Согласие на обработку персональных данных (152-ФЗ)</h1>

            <h1>Политика компании ООО «Новый Инструментальный Завод ЗАРЯ» об обработке персональных данных</h1>

            <b><p>1. Основные термины определения.</p></b>
            <p>1.1 «Интернет-магазин» — торговая площадка Продавца, расположенная в сети Интернет по адресу
                niz-zarya.ru, имеющая в своем контенте каталог предлагаемых для продажи Товаров.</p>
            <p>1.2 «Продавец» — общество с ограниченной ответственностью «Новый Инструментальный Завод ЗАРЯ»,
                осуществляющее реализацию Товаров Покупателям в сети Интернет по адресу niz-zarya.ru.</p>
            <p>1.3 «Покупатель» — лицо, заключившее с Продавцом Договор на условиях Оферты.</p>
            <p>1.4 «Пользователь» — любое дееспособное физическое лицо, способное оплатить и принять заказанный им товар
                в Интернет-магазине Продавца по адресу niz-zarya.ru.</p>
            <p>1.6 «Товар» — объект купли-продажи, представленный к продаже в Интернет-магазине Продавца.</p>
            <p>1.7 «Заказ» — оформленная заявка Покупателя на приобретение Товаров/Услуг в Интернет-магазине
                Продавца.</p>
            <p>1.8 «Персональные данные» — любая информация, относящаяся прямо или косвенно к определенному или
                определяемому физическому лицу (субъекту персональных данных).</p>
            <p>1.9 «Обработка персональных данных» — любое действие (операция) или совокупность действий (операций),
                совершаемых с использованием средств автоматизации или без использования таких средств с персональными
                данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
                извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных.</p>

            <b><p>2. Правовые основания обработки персональных данных.</p></b>
            <p>2.1 Политика компании ООО «Новый Инструментальный Завод ЗАРЯ» в отношении обработки персональных данных
                (далее Политика) составлена в соответствии с требованиями нормативно-правовых актов РФ:</p>
            <p>- Конституция Российской Федерации;</p>
            <p>- Трудовой кодекс Российской Федерации;</p>
            <p>- Федеральный закон от 27 июля 2006 года №152-ФЗ «О персональных данных»;</p>
            <p>- Указ Президента Российской Федерации от 06 марта 1997 г. № 188 «Об утверждении Перечня сведений
                конфиденциального характера»;</p>
            <p>- Постановление Правительства Российской Федерации от 13 сентября 2008 года №687 «Об утверждении
                Положения об особенностях обработки персональных данных, осуществляемой без использования средств
                автоматизации»;</p>
            <p>- Постановление Правительства Российской Федерации от 06 июля 2008 года №512 «Об утверждении требований к
                материальным носителям биометрических персональных данных и технологиям хранения таких данных вне
                информационных систем персональных данных»;</p>
            <p>- Постановление Правительства Российской Федерации от 01 ноября 2012 года №1119 «Об утверждении
                требований к защите персональных данных при их обработке в информационных системах персональных
                данных»;</p>
            <p>- Приказ ФСТЭК России от 18 февраля 2013 г. №21 «Об утверждении состава и содержания организационных и
                технических мер по обеспечению безопасности персональных данных при их обработке в информационных
                системах персональных данных»;</p>
            <p>- Приказ Роскомнадзора от 05 сентября 2013 №996 «Об утверждении требований и методов по обезличиванию
                персональных данных»;</p>
            <p>- Иные нормативно-правовые акты Российской Федерации и нормативные документы уполномоченных органов
                государственной власти, направленные на регулирование отношений при работе с персональными данными.</p>

            <b><p>3. Предмет согласия на обработку персональных данных.</p></b>
            <p>3.1 Обработка персональных данных Пользователя производится на условиях настоящего согласия на обработку
                персональных данных (далее Согласие).</p>

            <b><p>4. Общие положения.</p></b>
            <p>4.1 Настоящее Согласие на обработку персональных данных принимается Пользователем и вступает в законную
                силу с момента подтверждения Пользователем Заказа в Интернет-магазине Продавца или после подтверждения
                Заказа по телефону, либо после регистрации Пользователя в Интернет-магазине. Пользователь свободно,
                своей волей и в своем интересе даёт свое согласие ООО «НИЗ ЗАРЯ» на обработку своих указанных
                Персональных данных. Под персональными данными понимается любая информация, относящаяся к Пользователю
                как к Субъекту Персональных данных, в том числе фамилия, имя, отчество, адрес, образование, профессия,
                контактные данные (телефон, факс, электронная почта, почтовый адрес), фотографии, иная другая
                информация.</p>
            <p>4.2 Любые изменения, внесённые в настоящее Согласие, начинают действовать сразу после публикации нового
                Согласия в Интернет-магазине.</p>
            <p>4.3 Продавец имеет право вносить любые изменения в настоящее Согласие в рамках действующего
                законодательства РФ, без какого – либо согласования с Пользователем или его уведомления. Дальнейшее
                использование Интернет-магазина после публикации изменений в Согласии, означает принятие Пользователем
                этих изменений.</p>
            <p>4.4 Продавец не несёт ответственности за предоставление Пользователем недостоверных Персональных
                данных.</p>
            <p>4.5 Обработка Персональных данных Пользователя осуществляется с целью совершения сделки, обработки
                Заказа, выполнения услуг или улучшения качества обслуживания.</p>

            <b><p>5. Состав персональных данных пользователя.</p></b>
            <p>5.1 Пользователь дает своё согласие на обработку следующих Персональных данных:</p>
            <p>- Фамилия, имя и отчество;</p>
            <p>- Телефонный номер;</p>
            <p>- Адрес доставки товара;</p>
            <p>- Адрес электронной почты (E-mail).</p>

            <b><p>6. Срок хранения и обработки персональных данных.</p></b>
            <p>6.1 Срок хранения и обработки персональных данных Пользователя составляет 5 (пять) лет, с возможностью
                автоматической пролонгации, в случае дальнейшего использования Пользователем Интернет-магазина
                Продавца.</p>

            <b><p>7. Порядок обработки персональных данных.</p></b>
            <p>7.1 Пользователь соглашается с тем, что Интернет-магазин имеет право на хранение и обработку, в том числе
                и автоматизированную, любой информации, относящейся к Персональным данным Пользователя.</p>
            <p>7.2 Пользователь уведомлён, что под обработкой Персональных данных понимается сбор, систематизация,
                накопление, уточнение, обновление, изменение, использование, распространение, передача, в том числе
                трансграничная, обезличивание, блокирование, уничтожение, бессрочное хранение и любые другие действия
                (операции) с персональными данными, предоставленными Пользователем.</p>
            <p>7.3 Обработка персональных данных Пользователя осуществляется исключительно в целях регистрации данных в
                базе Интернет-магазина с последующим направлением Пользователю почтовых сообщений и смс-уведомлений, в
                том числе рекламного содержания, от Интернет-магазина, его аффилированных лиц и/или субподрядчиков,
                информационных и новостных рассылок, приглашений на мероприятия Интернет-магазина и другой информации
                рекламно-новостного содержания, а также с целью подтверждения личности Пользователя при посещении
                мероприятий Интернет-магазина, обработки Заказа, выполнения услуг или улучшения качества обслуживания.
                Обработка производится путем смешанной обработки персональных данных без передачи и с передачей по
                внутренней сети Интернет-магазина, c передачей и без передачи по сети Интернет.</p>
            <p>7.4 В случае отзыва Пользователем согласия на обработку персональных данных, Интернет-магазин удаляет
                Персональные данные Пользователя и не использует их в дальнейшем. Отзыв согласия на обработку
                Персональных данных можно оформить, обратившись в ООО «НИЗ ЗАРЯ».</p>
            <p>7.4.1 В случае недееспособности субъекта Персональных данных, отзыв согласия на обработку Персональных
                данных может оформить его законный представитель.</p>
            <p>7.4.2 В случае смерти субъекта Персональных данных, отзыв согласия на обработку его Персональных могут
                оформить наследники субъекта Персональных данных.</p>
            <p>7.5 Интернет-магазин обязуется не передавать личные данные Пользователей третьим лицам, за исключением
                обслуживающих организаций, с целью выполнения заказа Пользователя (курьерские службы, организации
                почтовой связи, операторы службы доставки и т.п.) и уполномоченным органам государственной власти
                Российской Федерации, если это осуществляется на законных основаниях и в предусмотренном
                законодательством РФ порядке.</p>

            <b><p>8. Ответственность и контроль.</p></b>
            <p>8.1 Интернет-магазин принимает необходимые правовые, организационные и технические меры для защиты
                Персональных данных от неправомерного или случайного доступа к ним, уничтожения, изменения,
                блокирования, копирования, предоставления, распространения, а также от иных неправомерных действий в
                отношении Персональных данных и принимает на себя обязательство сохранения конфиденциальности
                персональных данных Пользователя.</p>
            <p>8.2 Интернет-магазин вправе привлекать для обработки Персональных данных Пользователя субподрядчиков, а
                также вправе передавать Персональные данные для обработки своим аффилированным лицам, обеспечивая при
                этом принятие такими субподрядчиками и аффилированными лицами соответствующих обязательств в части
                конфиденциальности Персональных данных.</p>
            <p>8.3 Ответственным за соблюдением требований законодательства в области Персональных данных и настоящей
                политики является Руководитель ООО «НИЗ ЗАРЯ».</p>
            <p>Данное соглашение является исполнением требования Федерального закона РФ от 27 июля 2006 года № 152-ФЗ «О
                персональных данных».</p>
        </StyledProcessingPersonalDataPage>
    );
}

export default ProcessingPersonalDataPage;