import React from 'react';

function BalloonSvg(props:any) {
    return (
        <svg {...props} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.17783 19.5965C1.12375 11.3684 0 10.5239 0 7.5C0 3.35785 3.58171 0 8 0C12.4183 0 16 3.35785 16 7.5C16 10.5239 14.8763 11.3684 8.82217 19.5965C8.42488 20.1345 7.57508 20.1345 7.17783 19.5965ZM8 10.625C9.84096 10.625 11.3333 9.2259 11.3333 7.5C11.3333 5.7741 9.84096 4.375 8 4.375C6.15904 4.375 4.66667 5.7741 4.66667 7.5C4.66667 9.2259 6.15904 10.625 8 10.625Z"
                fill="#D6D6D6"/>
        </svg>
    );
}

export default BalloonSvg;