import React, {useState} from 'react';
import {Button, Form, Input, Modal} from "antd";
import styled from "styled-components";
import Close2SVG from "../icons/Close2SVG";
import {useSelector} from "react-redux";
import {pageReducer} from "../redux/reducers/pageReducer";
import store from "../redux/store";
import {changeFeedbackOpenAction, sendFeedbackAction} from "../redux/actions/pageActions";

const StyledFeedbackForm = styled(Modal)`
  border-radius: 10px;
  width: 930px !important;

  .ant-modal-close {
    width: auto;
    height: auto;
  }

  .ant-modal-content {
    padding: 65px 80px;

    .ant-form {
      label {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #8A8A8A;

        &.ant-checkbox-wrapper {
          font-weight: 400;
          font-size: 18px;
          line-height: 140%;

          color: #8A8A8A;
        }
      }

      .ant-btn {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #FFFFFF;
        background: #5B77CB;
        border-radius: 4px;
        border: none;
        padding: 18px 40px;
        height: auto;

        &:hover {
          color: #FFFFFF;
          opacity: .8;
        }
      }

      .ant-input {
        font-weight: 400;
        font-size: 18px;
        height: auto;
        padding: 16px 20px;
        line-height: 140%;

        color: #000000;
      }
    }

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 40px;

      color: #000000;
    }
  }
`;

function FeedbackForm() {
    const open = useSelector((state: any) => state.pageReducer.feedbackOpen)

    const [formRef] = Form.useForm();

    // const [open, setOpen] = useState<boolean>(false);

    return (
        <StyledFeedbackForm onCancel={() => {
            store.dispatch(changeFeedbackOpenAction(false))
        }} closeIcon={<Close2SVG/>} open={open} footer={null}>
            <h1>Обратная связь</h1>
            <Form form={formRef} onFinish={(fields) => {
                store.dispatch(sendFeedbackAction(fields)).then(() => {
                    store.dispatch(changeFeedbackOpenAction(false))
                    formRef.resetFields();
                })
            }} layout={"vertical"}>
                <Form.Item name={"name"} label={"Имя:"} rules={[{
                    required: true,
                    message: "Поле обязательно для заполнения!"
                }]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"email"} label={"E-mail:"} rules={[{
                    required: true,
                    message: "Поле обязательно для заполнения!"
                }]}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"text"} label={"Комментарий:"} rules={[{
                    required: true,
                    message: "Поле обязательно для заполнения!"
                }]}>
                    <Input.TextArea/>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={"submit"}>Отправить</Button>
                </Form.Item>
            </Form>
        </StyledFeedbackForm>
    );
}

export default FeedbackForm;