import React from 'react';

function StarSvg(props: any) {
    return (
        <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0432 1.02713C12.2192 0.631394 12.7808 0.631393 12.9568 1.02713L16.1939 8.30487C16.2652 8.46528 16.4154 8.57651 16.5897 8.59794L24.2944 9.54529C24.7127 9.59672 24.8836 10.1107 24.5795 10.4024L18.859 15.8885C18.7367 16.0058 18.6821 16.177 18.714 16.3435L20.2287 24.2492C20.3094 24.6702 19.8574 24.9909 19.4866 24.7757L12.751 20.8657C12.5958 20.7756 12.4042 20.7756 12.249 20.8657L5.51336 24.7757C5.14256 24.9909 4.69059 24.6702 4.77127 24.2492L6.28602 16.3435C6.31791 16.177 6.26333 16.0058 6.14103 15.8885L0.42053 10.4024C0.116387 10.1107 0.287342 9.59672 0.705595 9.54529L8.41031 8.59794C8.58456 8.57651 8.7348 8.46528 8.80614 8.30487L12.0432 1.02713Z"
                fill="#D6D6D6"/>
        </svg>
    );
}

export default StarSvg;