import React from 'react';

function ArrowSvg(props: any) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5195 11.98H4.52953" stroke="#898989" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M10.5166 5.98779L4.4796 11.9998L10.5166 18.0118" stroke="#898989" strokeWidth="1.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default ArrowSvg;