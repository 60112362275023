import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Button, Space} from "antd";
import CarouselLine from "../../components/CarouselLine";
import {Swiper, SwiperSlide} from "swiper/react";

import {Autoplay, Pagination} from "swiper";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import MainBanner from "../../components/MainBanner";
import SecondaryBanner from "../../components/SecondaryBanner";
import store from "../../redux/store";
import {changeFeedbackOpenAction} from "../../redux/actions/pageActions";
import {Link} from "react-router-dom";
import LongArrowRightSVG from "../../icons/LongArrowRightSVG";
import {useMediaQuery} from "usehooks-ts";
import {GroupType} from "../../utils/types";
import axios from "axios";
import {api} from "../../config";
import Banner2 from "../../components/Banner2";
import Banner3 from "../../components/Banner3";
import Banner4 from "../../components/Banner4";

const FeedbackWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Feedback = styled.div`
  display: flex;
  margin: 0 18.75% 35px 18.75%;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin-bottom: 140px;

  background: #5B77CB;
  border-radius: 10px;
  padding: 54px 68px;


  .ant-space {
    &:last-child {
      margin-left: 42px;
    }
  }

  .ant-btn {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: #000000;
    border: none;
    padding: 17px 28px;
    height: auto;
    background: #FFFFFF;
    border-radius: 4px;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;

    color: #FFFFFF;
    margin: 0;
  }

  p {
    padding: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;

    color: #FFFFFF;
  }

  @media screen and (max-width: 1919px) {
    margin: 0 20px 35px 20px;
    padding: 50px 30px;
  }

  @media screen and (max-width: 999px) {
    flex-direction: column;
    margin: 0 0 35px 0;
    border-radius: 0;
    padding: 50px 20px;
    .ant-space {
      &:last-child {
        margin-left: 0;
      }
    }
  }

  @media screen and (max-width: 479px) {
    flex-direction: column;
    margin: 0 0 35px 0;
    border-radius: 0;
    padding: 50px 20px;
    .ant-space {
      width: 100%;
      gap: 18px;

      .ant-space-item {
        width: 100%;
      }

      &:last-child {
        margin-top: 12px;
        flex-direction: column;

        .ant-btn {
          margin: 0;
          width: 100%;
        }
      }
    }
  }
`;

const Title = styled.div`
  display: flex;
  padding: 0 18.75% 0 18.75%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  @media screen and (max-width: 1919px) {
    padding: 0 20px 0 20px;
  }
  @media screen and (max-width: 479px) {
    flex-direction: column;
    align-items: flex-start;
  }

  h1 {
    font-weight: 600;
    font-size: 28px;
  }

  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    text-decoration-line: underline;
    color: #5B77CB;

    svg {
      margin-left: 8px;
    }
  }
`;

const StyledMainPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0 150px 0;


  .categoriesHeader {
    margin-top: 30px;
  }

  .mySwiper {
    width: 100%;
    height: 440px;

    @media screen and (max-width: 999px) {
      height: 663px;
    }

    .swiper-pagination-bullet-active {
      background: #5b77cb;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      width: 1200px;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @media screen and (max-width: 999px) {
        width: 480px;
      }

      @media screen and (max-width: 479px) {
        width: 360px;
        text-align: start;
      }
    }
  }

  .mySecondSwiper {
    width: 100%;
    height: 250px;
    margin-bottom: 110px;

    @media screen and (max-width: 479px) {
      height: 408px;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      width: 580px;
      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @media screen and (max-width: 1919px) {
        width: 470px;
      }
      @media screen and (max-width: 999px) {
        width: 440px;
      }

      @media screen and (max-width: 479px) {
        width: 320px;
        height: 408px;
      }
    }
  }

  > h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #000000;
  }
`;

function MainPage() {
    const mobileSize = useMediaQuery('(max-width: 479px)')

    const [categoryBanners, setCategoryBanners] = useState<GroupType[]>([])

    useEffect(() => {
        axios(`${api}/groups/main`).then(({data}) => {
            setCategoryBanners(data);
        })
    }, []);

    return (
        <StyledMainPage>

            <Swiper
                slidesPerView={"auto"}
                spaceBetween={20}
                centeredSlides={true}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Pagination]}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                className="mySwiper"
            >
                <SwiperSlide><Banner2/></SwiperSlide>
                <SwiperSlide><Banner3/></SwiperSlide>
                <SwiperSlide><Banner4/></SwiperSlide>
                <SwiperSlide><Banner2/></SwiperSlide>
                <SwiperSlide><Banner3/></SwiperSlide>
                <SwiperSlide><Banner4/></SwiperSlide>
            </Swiper>

            {categoryBanners?.length > 0 && <>
                <Title className={"categoriesHeader"}>
                    <h1>Категории товаров</h1>
                    <Link to={"/catalog"}>Смотреть все <LongArrowRightSVG/></Link>
                </Title>

                <Swiper
                    breakpoints={{
                        481: {
                            centeredSlides: false
                        },
                        0: {
                            centeredSlides: true
                        }
                    }}
                    slidesPerView={"auto"}
                    spaceBetween={16}
                    className="mySecondSwiper"
                >
                    {categoryBanners?.map((el, index) =>
                        <SwiperSlide key={index}><SecondaryBanner data={el}/></SwiperSlide>
                    )}
                </Swiper>
            </>}

            <Title>
                <h1>Популярные товары</h1>
                <Link to={"/catalog?filter=popular"}>Смотреть все <LongArrowRightSVG/></Link>
            </Title>
            <CarouselLine type={"popular"} style={{marginBottom: 150}}/>

            <FeedbackWrap>
                <Feedback>
                    <Space direction={"vertical"} size={18}>
                        <h2>Хотите сделать заказ?</h2>
                        <p>Оставьте свой номер телефона или заполните форму и наш специалист свяжется с вами в ближайшее
                            время </p>
                    </Space>
                    <Space size={mobileSize ? 18 : 30}>
                        <Button onClick={() => {
                            store.dispatch(changeFeedbackOpenAction(true))
                        }}>Заполнить форму</Button>
                        <Button onClick={() => {
                            store.dispatch(changeFeedbackOpenAction(true))
                        }}>Обратный звонок</Button>
                    </Space>
                </Feedback>
            </FeedbackWrap>

            <Title>
                <h1>Хиты продаж</h1>
                <Link to={"/catalog?filter=hot"}>Смотреть все <LongArrowRightSVG/></Link>
            </Title>
            <CarouselLine type={"hot"}/>

        </StyledMainPage>
    );
}

export default MainPage;