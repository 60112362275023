import * as _ from "lodash";
export const fillTill = (array: any[], size: number) => {
    // console.log("array:", array.length);
    // console.log("size:", size);
    // console.log("length:", Math.ceil(size / array.length) * array.length);
    let newArray = _.range(Math.ceil(size / array.length) * array.length);
    newArray = newArray.map((el, index) => {
        return array[index % array.length]
    })
    // console.log(newArray);
    return newArray
}