import {initialState} from "../initialState";
import {
    CATALOG_OPEN_CHANGE,
    CHANE_FEEDBACK,
    CHANGE_PROGRESS_VALUE, GET_CART, GET_COMMON_ORDERS, GET_FAVORITES,
    GET_GROUP,
    GET_GROUPS,
    GET_HOT_ITEMS,
    GET_ITEM,
    GET_ITEMS_BY_GROUP, GET_LOCAL_CART, GET_LOCAL_CART_ITEMS, GET_LOCAL_FAVORITES, GET_POPULAR_ITEMS, GET_SEARCH_RESULT,
    GET_VIDEOS
} from "../actionTypes";


export function pageReducer(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PROGRESS_VALUE:
            return {...state, progress: action.payload}
        case GET_VIDEOS:
            return {...state, videos: action.payload}
        case GET_GROUPS:
            return {...state, groups: action.payload}
        case GET_ITEMS_BY_GROUP:
            return {...state, items: action.payload}
        case GET_ITEM:
            return {...state, item: action.payload}
        case GET_HOT_ITEMS:
            return {...state, hotItems: action.payload}
        case GET_POPULAR_ITEMS:
            return {...state, popularItems: action.payload}
        case GET_FAVORITES:
            return {...state, favorites: action.payload}
        case GET_CART:
            return {...state, cart: action.payload}
        case GET_COMMON_ORDERS:
            return {...state, orders: action.payload}
        case CATALOG_OPEN_CHANGE:
            return {...state, showCatalog: action.payload}
        case CHANE_FEEDBACK:
            return {...state, feedbackOpen: action.payload}
        case GET_GROUP:
            return {...state, group: action.payload}
        case GET_LOCAL_FAVORITES:
            return {...state, localFavorites: action.payload}
        case GET_LOCAL_CART:
            return {...state, localCart: action.payload}
        case GET_LOCAL_CART_ITEMS:
            return {...state, localCartItems: action.payload}
        case GET_SEARCH_RESULT:
            return {...state, searchResult: action.payload}
        default:
            return state;
    }
}