export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const CHANGE_PROGRESS_VALUE = "CHANGE_PROGRESS_VALUE";

export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_CONFIG_SUCCESS = "GET_CONFIG_SUCCESS";
export const GET_SUB_GROUPS_SUCCESS = "GET_SUB_GROUPS_SUCCESS";
export const GET_CATALOG = "GET_CATALOG";
export const GET_USERS = "GET_USERS";
export const GET_VIDEOS = "GET_VIDEOS";
export const GET_GROUPS = "GET_GROUPS";
export const GET_SEARCH_RESULT = "GET_SEARCH_RESULT";
export const GET_GROUP = "GET_GROUP";
export const GET_ITEMS_BY_GROUP = "GET_ITEMS_BY_GROUP";
export const GET_POPULAR_ITEMS = "GET_POPULAR_ITEMS";
export const CHANE_FEEDBACK = "CHANE_FEEDBACK";
export const GET_HOT_ITEMS = "GET_HOT_ITEMS";
export const GET_CART = "GET_CART";
export const GET_FAVORITES = "GET_FAVORITES";
export const GET_LOCAL_CART_ITEMS = "GET_LOCAL_CART_ITEMS";
export const GET_ITEM = "GET_ITEM";
export const GET_ORDERS = "GET_ORDERS";
export const GET_LOCAL_FAVORITES = "GET_LOCAL_FAVORITES";
export const GET_LOCAL_CART = "GET_LOCAL_CART";
export const CATALOG_OPEN_CHANGE = "CATALOG_OPEN_CHANGE";
export const GET_COMMON_ORDERS = "GET_COMMON_ORDERS";
