import React from 'react';

function UploadPlusSvg(props:any) {
    return (
        <svg {...props} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6981 0V10.5M10.6981 21V10.5M10.6981 10.5H0H21" stroke="#898989"/>
        </svg>
    );
}

export default UploadPlusSvg;