import React from 'react';

function CatalogSvg(props: any) {
    return (
        <svg {...props} width="76" height="77" viewBox="0 0 76 77" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M49.8691 22.5561L45.0993 27.3248L38.0001 34.4251L33.0696 39.3556L26.8426 45.5838L21.9097 50.5143L20.8401 51.5827L21.9711 52.7125L64.0473 10.6363H64.0497L62.9187 9.50653L58.4632 13.962V1.64391C58.4632 1.21136 58.2883 0.788272 57.9822 0.481C57.6761 0.17491 57.2519 0 56.8193 0H1.64508C1.21254 0 0.788269 0.17491 0.482179 0.481C0.176089 0.78709 0 1.21018 0 1.64391V75.3561C0 75.7886 0.174907 76.2117 0.482179 76.5178C0.788269 76.8239 1.21254 77 1.64508 77H46.7692C47.2017 77 47.6212 76.8275 47.9273 76.5225L52.9973 71.4856L53.0032 71.4786L57.9834 66.4759C58.2883 66.1687 58.4632 65.7479 58.4632 65.3154V27.3909L55.173 30.6811V62.0299H44.714C44.2814 62.0299 43.8583 62.206 43.5511 62.5121C43.245 62.8182 43.0701 63.2413 43.0701 63.675V73.7134H3.28899V3.28899H55.173V17.251L49.8691 22.5561ZM46.3591 65.3177H55.1163L46.3591 73.7713V65.3177Z"
                fill="#616E7D"/>
            <path d="M50.523 11.7625H10.6367V16.5311H50.523V11.7625Z" fill="#82BFAB"/>
            <path d="M6.12695 27.3248H45.0997L49.8695 22.5562H6.12695V27.3248Z" fill="#82BFAB"/>
            <path d="M6.2793 39.3556H33.0687L37.998 34.425H6.2793V39.3556Z" fill="#82BFAB"/>
            <path d="M52.1826 39.3556V34.425H51.4298L46.498 39.3556H52.1826Z" fill="#82BFAB"/>
            <path d="M52.1838 50.5131V45.5825H40.2723L35.3418 50.5131H52.1838Z" fill="#82BFAB"/>
            <path d="M6.2793 50.5131H21.9088L26.8405 45.5825H6.2793V50.5131Z" fill="#82BFAB"/>
            <path d="M15.4856 61.672L15.6464 61.3482L17.9095 56.7427H6.2793V61.672H15.4856Z" fill="#82BFAB"/>
            <path
                d="M26.2115 59.3557L21.4961 61.672H40.7739V56.7427H29.1117L27.5588 58.2979H27.5564L26.4278 57.1681L26.0012 56.7427H24.752L26.3758 58.3665C26.5176 58.5071 26.5803 58.7057 26.5495 58.903C26.5153 59.1016 26.3912 59.267 26.2115 59.3557Z"
                fill="#82BFAB"/>
            <path d="M55.1166 65.3176H46.3594V73.77L55.1166 65.3176Z" fill="white"/>
            <path
                d="M49.8691 22.5562L55.1743 17.251V3.28784H3.28906V73.7122H43.0702V63.6739C43.0702 63.2402 43.2462 62.8171 43.5512 62.511C43.8572 62.2049 44.2815 62.0288 44.7141 62.0288H55.1731V30.6811L58.4633 27.391L69.6338 16.2205L49.0123 36.8419L51.4303 34.4251H52.1819V39.3557H46.4974L27.5565 58.2978H27.5588L29.1117 56.7425H40.7739V61.6719H21.4973L17.7923 63.4919L15.1994 64.7659C14.9619 64.8817 14.6782 64.8344 14.4915 64.6489C14.3048 64.461 14.2587 64.1773 14.3733 63.941L15.4866 61.6731H6.27906V56.7437H17.9093L19.7813 52.9288C19.8699 52.748 20.0366 52.6239 20.2339 52.592C20.4313 52.5601 20.631 52.6227 20.7717 52.7634L24.752 56.7437H26.0012L26.4278 57.1692L68.5052 15.093L64.0486 10.6376L21.9724 52.7125L20.8414 51.5827L21.9109 50.5144H6.28024V45.5838H26.8426L33.0696 39.3557H6.28024V34.4251H38.0002L45.0993 27.3248H6.1266V22.5562H49.8691ZM10.6376 11.7626H50.5239V16.5324H10.6376V11.7626ZM35.3411 50.5132L40.2716 45.5827H52.1831V50.5132H35.3411ZM64.6761 11.4802L67.6602 14.4631L25.5817 56.5416L22.5976 53.5575L64.6761 11.4802Z"
                fill="#616E7D"/>
            <path d="M46.498 39.3558L49.0118 36.842L46.498 39.3558Z" fill="white"/>
            <path d="M64.6749 11.4794L22.5977 53.5566L25.5818 56.5408L67.659 14.4636L64.6749 11.4794Z" fill="#AAD4C8"/>
            <path d="M27.5556 58.2978L69.633 16.2204L68.5043 15.0918L26.4258 57.168L27.5556 58.2978Z" fill="#616E7D"/>
            <path
                d="M20.7701 52.762C20.6306 52.6214 20.4309 52.5588 20.2323 52.5907C20.035 52.6238 19.8695 52.7479 19.7797 52.9275L17.9077 56.7424L15.6445 61.3468L17.7895 63.4906L21.4945 61.6706L26.21 59.3542C26.3896 59.2656 26.5137 59.0989 26.5468 58.9016C26.5775 58.7042 26.516 58.5057 26.373 58.365L24.7492 56.7412L20.7701 52.762Z"
                fill="#82BFAB"/>
            <path
                d="M14.3748 63.9398C14.2601 64.1774 14.3062 64.4598 14.4929 64.6477C14.6785 64.8345 14.9633 64.8817 15.2009 64.7647L17.7938 63.4907L15.6488 61.3469L15.488 61.6707L14.3748 63.9398Z"
                fill="#616E7D"/>
            <path
                d="M67.011 5.41272L66.2369 6.18681L64.9274 7.49627L63.5234 8.90026L70.2385 15.6153L71.6437 14.2102L72.9508 12.9031L73.7272 12.1278C75.5815 10.2735 75.5791 7.26699 73.7272 5.41391C71.8718 3.55846 68.8664 3.55727 67.011 5.41272Z"
                fill="#616E7D"/>
        </svg>
    );
}

export default CatalogSvg;