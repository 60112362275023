import React from 'react';

function MailSvg(props: any) {
    return (
        <svg {...props} width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 15V1H23V15C23 16.1046 22.1046 17 21 17H3C1.89543 17 1 16.1046 1 15Z" fill="white"/>
            <path
                d="M1 1V15C1 16.1046 1.89543 17 3 17H21C22.1046 17 23 16.1046 23 15V1M1 1H23M1 1L10.5645 8.67088C11.2849 9.2487 12.3073 9.25808 13.0383 8.69357L23 1"
                stroke="black" strokeWidth="1.5"/>
        </svg>
    );
}

export default MailSvg;