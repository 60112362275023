import React from 'react';

function Plus2Svg(props: any) {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 1V15" stroke="#151515" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
            <path d="M1 8H15" stroke="#151515" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round"/>
        </svg>
    );
}

export default Plus2Svg;