import {applyMiddleware, compose, createStore} from "redux";
import thunkMiddleware from 'redux-thunk'
import {logger} from "redux-logger";
import {initialState} from "./initialState";
import rootReducer from "./reducers/rootReducer";

const middlewares = [thunkMiddleware];

if (process.env.NODE_ENV === `development`) {

    middlewares.push(logger);
}

const store = createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(...middlewares)
    )
);

export default store;