import React from 'react';

function ButtonStarSvg(props:any) {
    return (
        <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.5 1.23033L15.737 8.50807C15.8797 8.82888 16.1802 9.05135 16.5287 9.0942L24.2334 10.0416L18.5129 15.5276C18.2683 15.7622 18.1591 16.1047 18.2229 16.4376L19.7377 24.3432L13.002 20.4333C12.6916 20.2531 12.3084 20.2531 11.998 20.4333L5.26234 24.3432L6.77709 16.4376C6.84086 16.1047 6.73172 15.7622 6.48712 15.5276L0.766613 10.0415L8.47133 9.0942C8.81982 9.05135 9.1203 8.82888 9.26299 8.50807L12.5 1.23033Z"
                stroke="#5B77CB"/>
        </svg>
    );
}

export default ButtonStarSvg;