import React from 'react';

function EditSvg(props: any) {
    return (
        <svg {...props} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.2809 12.0833V18.5833C18.2809 19.7804 17.2725 20.75 16.0275 20.75H3.63419C2.38923 20.75 1.38086 19.7804 1.38086 18.5833V6.66667C1.38086 5.46958 2.38923 4.5 3.63419 4.5H8.14086"
                stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.01367 14.2502L10.5627 13.8288C10.8117 13.7996 11.0438 13.6901 11.2218 13.5201L19.8194 5.25313C20.7714 4.33772 20.7714 2.85355 19.8194 1.93705V1.93705C18.8673 1.02163 17.3238 1.02163 16.3706 1.93705L7.85304 10.1271C7.68066 10.2928 7.56912 10.5073 7.53419 10.7402L7.01367 14.2502Z"
                  stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default EditSvg;