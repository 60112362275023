import React from 'react';
import styled from "styled-components";
import UserSVG from "../../icons/UserSVG";
import CameraSVG from "../../icons/CameraSVG";
import OrdersSVG from "../../icons/OrdersSVG";
import CatalogSVG from "../../icons/CatalogSVG";
import GroupSVG from "../../icons/GroupSVG";
import FeedbackSVG from "../../icons/FeedbackSVG";
import {Link} from "react-router-dom";
import SettingsSVG from "../../icons/SettingsSVG";
import MailMenuSVG from "../../icons/MailMenuSVG";

const Menu = styled.div`
  display: flex;
  gap: 60px 100px;
  flex-wrap: wrap;
  margin-top: 80px;
  width: 100%;
  max-width: 1260px;
  padding-bottom: 100px;
`;

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #FFFFFF;
  box-shadow: 2px 4px 18px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  width: 320px;
  padding: 50px;
  text-decoration: none;

  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #898989;

  cursor: pointer;
  user-select: none;

  &:hover {
    box-shadow: 2px 4px 18px rgba(0, 0, 0, 0.25);
    background: #eeeeee;
  }

  &:active {
    box-shadow: 2px 4px 18px rgba(0, 0, 0, 0.08);
    background: #eeeeee;
  }

  svg {
    margin-bottom: 20px;
  }
`;

const StyledHomePage = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

function HomePage() {
    return (
        <StyledHomePage>
            <Menu>
                <MenuItem to={"/admin/settings"}>
                    <MailMenuSVG/>
                    Настройка почты
                </MenuItem>
            </Menu>
        </StyledHomePage>
    );
}

export default HomePage;