import React from 'react';

function BurgerSvg(props: any) {
    return (
        <svg {...props} width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1.25" x2="23" y2="1.25" stroke="white" strokeWidth="1.5"/>
            <line y1="9.25" x2="23" y2="9.25" stroke="white" strokeWidth="1.5"/>
            <line y1="16.25" x2="23" y2="16.25" stroke="white" strokeWidth="1.5"/>
        </svg>
    );
}

export default BurgerSvg;