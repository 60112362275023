import {MenuProps} from "antd";

export enum RoleType {
    admin = 90,
    user = 30
}

export enum OrderStatusType {
    inWork = 0,
    completed = 1,
    canceled = 2
}

export enum ProductSortType {
    name = "name",
    code = "code",
    date = "date"
}
export enum VideoSortType {
    created = "created",
    updated = "updated",
    name = "name"
}

export enum UsersSortType {
    name = "name",
    email = "email",
    date = "date"
}

export enum SortingOrderType {
    desc = "desc",
    asc = "asc"
}

export type PaginationType<T> = {
    Total: number,
    Page: number,
    Count: number,
    Data: T
}

export type OrderType = {
    Id: number,
    CreatedDate: string,
    UpdatedDate: string,
    UserId: string,
    Positions: {
        Id: number,
        Count: number,
        Item: ProductType
    }[],
    Status: OrderStatusType
}

export type CharacteristicType = {
    Name: string,
    Value: string
}
export type ProductType = {
    Id: number,
    GroupId: number,
    SubGroupId: number,
    Name: string,
    ShortDesc: string,
    FullDesc: string,
    ImageUrls: string[],
    ImageMain: number,
    NewPrice: number,
    OldPrice: number,
    Code: string,
    Count: number,
    Meta?: {
        Characteristics?: CharacteristicType[]
    },
    Visibility: boolean,
    Popular: boolean,
    Hot: boolean
}

export type GroupType = {
    Id: number,
    ParentGroupId: number,
    ChildGroupIds: number[],
    ChildGroups?: GroupType[],
    ChildItemIds: number[],
    CreatedDate: string,
    ImageUrl: string,
    FullDesc: string,
    Name: string,
    ShowOnMainPage: boolean,
    Visibility: boolean
}

export type CartType = {
    CreatedDate: string,
    UpdatedDate: string,
    Positions: {
        Count: number,
        Id: number,
        Item: ProductType,
        CreatedDate: string
    }[],
    Status: any
}

export type MenuItemType = Required<MenuProps>['items'][number];

export type UserType = {
    readonly Login: string,
    readonly Password: string,
    Email: string,
    EmailApproved: boolean,
    Phone: string,
    PhotoUrl: string,
    PhoneApproved: boolean,
    Meta?: {
        Favorites?: number[]
    },
    Digest: string,
    Name: string,
    Timezone: string,
    State: boolean,
    Role: RoleType,
    CreatedDate: string
}

export type VideoType = {
    Id: number,
    CreatedDate: string,
    Name: string,
    Link: string,
    Visibility: boolean
}