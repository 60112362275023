import React from 'react';

function PhoneSvg(props:any) {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.9778 8.65556C14.3778 11.8 11.8 14.3667 8.65555 15.9778L6.21111 13.5333C5.91111 13.2333 5.46667 13.1333 5.07778 13.2667C3.83333 13.6778 2.48889 13.9 1.11111 13.9C0.5 13.9 0 14.4 0 15.0111V18.8889C0 19.5 0.5 20 1.11111 20C11.5444 20 20 11.5444 20 1.11111C20 0.5 19.5 0 18.8889 0H15C14.3889 0 13.8889 0.5 13.8889 1.11111C13.8889 2.5 13.6667 3.83333 13.2556 5.07778C13.1333 5.46667 13.2222 5.9 13.5333 6.21111L15.9778 8.65556Z"
                fill="#D6D6D6"/>
        </svg>
    );
}

export default PhoneSvg;