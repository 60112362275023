import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {GroupType} from "../utils/types";
import {useSelector} from "react-redux";
import IconArrowRightSVG from "../icons/IconArrowRightSVG";
import getDeepGroup from "../utils/getDeepGroup";
import IconCloseSVG from "../icons/IconCloseSVG";
import History from "../utils/history";

const StyledCatalogComponent = styled.div`
  display: flex;
  width: 100%;
`;
const Group = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 20px 12px 20px 20px;
  border-top: 1px solid #DCDCDC;

  &:hover {
    background: #fafafa;
    cursor: pointer;
  }

  svg {
    margin-left: 20px;
  }

  &:last-child {
    border-bottom: 1px solid #DCDCDC;
  }

  span {
    overflow-wrap: anywhere;
  }
`;

const Top = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 50px 1fr 50px;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 
    ". . .";

  margin-bottom: 22px;
  padding: 0 20px;

  h1 {
    overflow-wrap: anywhere;
  }

  svg {
    cursor: pointer;
  }

  svg:last-child {
    margin-left: auto;
  }

  svg:first-child {
    transform: rotate(180deg);
  }
`;
const GroupList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  h1 {
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

function CatalogComponent({onClose}: any) {
    const groups: GroupType[] = useSelector((state: any) => state.pageReducer.groups);

    const [selectedGroup, setSelectedGroup] = useState<number[]>([]);

    const [currentGroupList, setCurrentGroupList] = useState<GroupType | null>();

    useEffect(() => {
        setCurrentGroupList(getDeepGroup(selectedGroup, groups))
    }, [selectedGroup]);

    return (
        <StyledCatalogComponent>
            <GroupList>
                <Top>
                    <span>
                        {selectedGroup.length !== 0 &&
                            <IconArrowRightSVG onClick={() => setSelectedGroup([...selectedGroup].slice(0, -1))}/>}
                    </span>
                    <h1>{selectedGroup.length === 0 ? "Каталог" : currentGroupList?.Name}</h1>
                    <IconCloseSVG onClick={onClose}/>
                </Top>
                {(selectedGroup.length === 0 ? groups : currentGroupList?.ChildGroups)?.map(el =>
                    <Group key={el.Id} onClick={() => {
                        setSelectedGroup([...selectedGroup, el.Id])
                        History.push(`/catalog?group=${el.Id}`)
                        if (el.ChildGroupIds?.length === 0) {
                            onClose()
                        }
                    }}>
                        <span>{el.Name || el.Id}</span>
                        <IconArrowRightSVG/></Group>)}
            </GroupList>
        </StyledCatalogComponent>
    );
}

export default CatalogComponent;