import React, {useEffect} from 'react';
import styled from "styled-components";
import Breadcrumbs from "../../components/Breadcrumbs";
import {useSelector} from "react-redux";
import {PaginationType, VideoType} from "../../utils/types";
import moment from "moment";
import store from "../../redux/store";
import {getVideosAction} from "../../redux/actions/pageActions";

const MediaItem = styled.div`
  display: flex;
  flex-direction: column;
  
  iframe {
    border: none;
    height: 280px;
  }

  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;

    text-decoration-line: underline;

    color: #5B77CB;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    margin-top: 25px;
    margin-bottom: 6px;
    color: #A0A0A0;
  }

  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 140%;

    color: #5B77CB;
  }
`;

const Content = styled.div`
  //display: flex;
  //gap: 75px 24px;
  //flex-wrap: wrap;
  
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px 24px;


  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr;
  }
  
  @media screen and (max-width: 1919px) {
    gap: 70px 20px;
  }
`;

const StyledMediaPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 45px 18.75% 150px 18.75%;

  h1 {
    margin-top: 32px;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;

    color: #000000;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 1919px) {
    padding: 45px 20px 150px 20px;
  }
`;

function CommonMediaPage() {
    const videos: PaginationType<VideoType[]> = useSelector((state: any) => state.pageReducer.videos);

    useEffect(() => {
        store.dispatch(getVideosAction());
    }, [])

    return (
        <StyledMediaPage>
            <Breadcrumbs/>
            <h1>Медиатека</h1>
            <Content>
                {videos?.Data?.map(el => <MediaItem key={el.Id}>
                    <iframe title={el.Id.toString()} allowFullScreen={true} src={el.Link.replace("watch?v=", "embed/")}/>
                    <span>{moment(el.CreatedDate).format("DD.MM.YYYY")}</span>
                    <h2>{el.Name}</h2>
                    <a href={el.Link} target={"_blank noreferrer noopener"}>Перейти на YouTube</a>
                </MediaItem>)}
            </Content>
        </StyledMediaPage>
    );
}

export default CommonMediaPage;