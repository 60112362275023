import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Button, Form} from "antd";
import {MaskedInput} from "antd-mask-input";
import {StyledCheckbox} from "../../components/StyledCheckbox";
import {getUserAction, loginAction, regPhoneAction} from "../../redux/actions/loginActions";
import store from "../../redux/store";
import moment from "moment";
import {UserType} from "../../utils/types";
import {useSelector} from "react-redux";
import History from "../../utils/history";

const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 45px;
  @media screen and (max-width: 370px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
  }

  h1 {
    margin: 0 12px 0 0;

    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;

    color: #311C1C;
  }

  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    display: flex;
    align-items: center;

    color: #5B77CB;
  }
`;

const StyledCreateProfilePage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 65px 18.75% 450px 18.75%;
  
  @media screen and (max-width: 1919px) {
    padding: 65px 20px 450px 20px;
  }

  .ant-form-item {
    margin: 0;
  }

  .askBlock {
    margin-top: 40px;

    .askAgain {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      text-decoration-line: underline;

      color: #5B77CB;
      cursor: not-allowed;

      &.available {
        cursor: pointer;
      }
    }

    .timer {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;


      color: #8A8A8A;
    }
  }

  .ant-form {
    .ant-input {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;


      height: auto;
      padding: 16px 10px;

      color: #000000;
    }

    label {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;

      color: #8A8A8A;

      &.ant-checkbox-wrapper {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #000000;
      }
    }
  }


  .ant-btn {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    border: none;
    padding: 17px 28px;
    height: auto;
    background: #5B77CB;
    border-radius: 4px;
    margin-top: 32px;
    margin-bottom: 24px;

    &:disabled {
      background: gray;
    }

    span {
      color: #FFFFFF;
    }
  }
`;


function CreateProfilePage({code = false}) {
    const waitingTime = 60;
    const [isCode, setIsCode] = useState<boolean>(code);

    const user: UserType = useSelector((state: any) => state.loginReducer.userData);


    // const [codeValue, setCodeValue] = useState<string>("____");

    const [rulesAccepted, setRulesAccepted] = useState<boolean>(false);
    const [foreignPC, setForeignPC] = useState<boolean>(false);

    const [time, setTime] = useState<number>(waitingTime)
    const [againAvailable, setAgainAvailable] = useState<boolean>(false);

    const [showNumberReg, setShowNumberReg] = useState<boolean>(false);
    const [regPhone, setRegPhone] = useState<string>("");

    const [formRef] = Form.useForm();

    useEffect(() => {
        setIsCode(code);
    }, [code])
    useEffect(() => {
        if (isCode) {
            setShowNumberReg(true);
            if (!user.PhoneApproved)
                store.dispatch(regPhoneAction({
                    phone: user.Phone
                }))
        }
    }, [isCode])

    useEffect(() => {
        setTime(waitingTime);
    }, [againAvailable])

    useEffect(() => {
        let interval: any;
        if (!againAvailable && (isCode || showNumberReg)) {
            interval = setInterval(() => {
                setTime((time) => time - 1)
            }, 1000)
        }

        if (time === 0) {
            clearInterval(interval);
            setAgainAvailable(true);
        }


        return () => {
            if (interval)
                clearInterval(interval);
        }
    }, [time, isCode, showNumberReg, againAvailable])

    // useEffect(() => {
    //     const controller = new AbortController();
    //
    //     if (codeValue !== undefined && codeValue.indexOf("_") === -1)
    //         store.dispatch(verifyAction("phone", codeValue, controller.signal))
    //
    //     return () => {
    //         controller.abort();
    //     }
    // }, [codeValue])

    return (
        <StyledCreateProfilePage>

            {isCode ? <Top><h1>Введите код</h1></Top> :
                <Top><h1>Создать профиль</h1> <Link to={"/profile/login"}>или войти</Link></Top>}

            {showNumberReg && <Form form={formRef} requiredMark={false} layout={"vertical"} onFinish={(fields) => {
                if (isCode) {
                    store.dispatch(loginAction({
                        login: user.Login,
                        password: fields.code,
                        save: !foreignPC
                    }))
                } else {
                    store.dispatch(loginAction({
                        login: regPhone,
                        password: fields.code,
                        save: !foreignPC
                    }))
                }
            }}>
                <Form.Item name={"code"} rules={[{required: true}, {
                    validator: (_, value) => {
                        if (value !== undefined && value.indexOf("_") !== -1) {
                            return Promise.reject(new Error("Поле должно быть заполнено полностью!"))
                        } else {
                            return Promise.resolve()
                        }
                    }
                }]} style={{display: 'inline-block'}}
                           label={"Укажите 4 последние цифры с номера, который Вам позвонил. На звонок отвечать не нужно"}>
                    <MaskedInput style={{width: 165}} mask={'0000'} onChange={({target: {value}}) => {
                        if (!value.includes("_")) {
                            formRef.submit()
                        }
                    }}/>
                </Form.Item>
                <Form.Item className={"askBlock"}>
                    <span className={`askAgain ${againAvailable ? "available" : ""}`} onClick={() => {
                        if (againAvailable) {
                            setAgainAvailable(false);
                            store.dispatch(regPhoneAction({
                                phone: regPhone
                            }))
                        }
                    }}>Запросить код повторно</span> {!againAvailable && <span
                    className={"timer"}>через {moment.utc(time * 1000).format("mm:ss")}</span>}
                </Form.Item>
            </Form>}

            {!showNumberReg && <Form layout={"vertical"} requiredMark={false} onFinish={(fields) => {
                store.dispatch(regPhoneAction({
                    ...fields,
                    phone: `${fields.phoneZone}${fields.phoneNumber}`.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "")
                })).then(() => {
                    setRegPhone(`${fields.phoneZone}${fields.phoneNumber}`.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", ""));
                    setShowNumberReg(true);
                })
            }}>
                <Form.Item label={"Контактный телефон:"}>
                    <Form.Item initialValue={'+7'} name={"phoneZone"}
                               rules={[
                                   {
                                       required: true,
                                       message: "Поле не может быть пустым!"
                                   },
                                   {
                                       validator: (_, value) => {
                                           if (value !== undefined && value.indexOf("_") !== -1) {
                                               return Promise.reject(new Error("Поле не может быть пустым!"))
                                           } else {
                                               return Promise.resolve()
                                           }
                                       }
                                   }]}
                               style={{display: 'inline-block', width: 70}}>
                        <MaskedInput mask={'+0[0][0]'}/>
                    </Form.Item>
                    <Form.Item initialValue={"(___) ___ __ __"} name={"phoneNumber"}
                               rules={[
                                   {
                                       required: true,
                                       message: "Поле не может быть пустым!"
                                   },
                                   {
                                       validator: (_, value) => {
                                           if (value !== undefined && value.indexOf("_") !== -1) {
                                               return Promise.reject(new Error("Поле должно быть заполнено полностью!"))
                                           } else {
                                               return Promise.resolve()
                                           }
                                       }
                                   }]}
                               style={{display: 'inline-block', width: 'calc(215px - 8px)', margin: '0 8px'}}>
                        <MaskedInput mask={'(000) 000 00 00'}/>
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <Button disabled={!rulesAccepted} htmlType={"submit"}>Получить код</Button>
                </Form.Item>
                <Form.Item>
                    <StyledCheckbox checked={foreignPC} onChange={(e) => setForeignPC(e.target.checked)}>Чужой
                        компьютер</StyledCheckbox>
                </Form.Item>
                <Form.Item>
                    <StyledCheckbox checked={rulesAccepted} onChange={(e) => setRulesAccepted(e.target.checked)}>Согласен
                        с условиями использования интернет-магазина</StyledCheckbox>
                </Form.Item>
            </Form>}

            {/*<Form requiredMark={false} onFinish={(fields) => {*/}
            {/*    store.dispatch(signupAction({*/}
            {/*        ...fields,*/}
            {/*        phone: `${fields.phoneZone}${fields.phoneNumber}`.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "")*/}
            {/*    }))*/}
            {/*}} layout={"vertical"}>*/}
            {/*    {!isCode && <>*/}
            {/*        <Form.Item name={"login"} label={"Логин:"} style={{width: 285}} rules={[{*/}
            {/*            required: true,*/}
            {/*            message: "Поле не может быть пустым!"*/}
            {/*        }]}>*/}
            {/*            <Input/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item name={"name"} label={"Имя:"} style={{width: 285}} rules={[{*/}
            {/*            required: true,*/}
            {/*            message: "Поле не может быть пустым!"*/}
            {/*        }]}>*/}
            {/*            <Input/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item name={"email"} label={"Эл. почта:"} style={{width: 285}} rules={[*/}
            {/*            {*/}
            {/*                required: true,*/}
            {/*                message: "Поле не может быть пустым!"*/}
            {/*            },*/}
            {/*            {*/}
            {/*                type: 'email',*/}
            {/*                message: 'Введен неверный E-mail!',*/}
            {/*            }*/}
            {/*        ]}>*/}
            {/*            <Input/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item label={"Контактный телефон:"}>*/}
            {/*            <Form.Item initialValue={'+7'} name={"phoneZone"}*/}
            {/*                       rules={[*/}
            {/*                           {*/}
            {/*                               required: true,*/}
            {/*                               message: "Поле не может быть пустым!"*/}
            {/*                           },*/}
            {/*                           {*/}
            {/*                               validator: (_, value) => {*/}
            {/*                                   if (value !== undefined && value.indexOf("_") !== -1) {*/}
            {/*                                       return Promise.reject(new Error("Поле не может быть пустым!"))*/}
            {/*                                   } else {*/}
            {/*                                       return Promise.resolve()*/}
            {/*                                   }*/}
            {/*                               }*/}
            {/*                           }]}*/}
            {/*                       style={{display: 'inline-block', width: 70}}>*/}
            {/*                <MaskedInput mask={'+0[0][0]'}/>*/}
            {/*            </Form.Item>*/}
            {/*            <Form.Item name={"phoneNumber"}*/}
            {/*                       rules={[*/}
            {/*                           {*/}
            {/*                               required: true,*/}
            {/*                               message: "Поле не может быть пустым!"*/}
            {/*                           },*/}
            {/*                           {*/}
            {/*                               validator: (_, value) => {*/}
            {/*                                   if (value !== undefined && value.indexOf("_") !== -1) {*/}
            {/*                                       return Promise.reject(new Error("Поле должно быть заполнено полностью!"))*/}
            {/*                                   } else {*/}
            {/*                                       return Promise.resolve()*/}
            {/*                                   }*/}
            {/*                               }*/}
            {/*                           }]}*/}
            {/*                       style={{display: 'inline-block', width: 'calc(215px - 8px)', margin: '0 8px'}}>*/}
            {/*                <MaskedInput mask={'(000) 000 00 00'}/>*/}
            {/*            </Form.Item>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item name={"password"} label={"Пароль:"} style={{width: 285}} rules={[{*/}
            {/*            required: true,*/}
            {/*            message: "Поле не может быть пустым!"*/}
            {/*        }]}>*/}
            {/*            <Input.Password/>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item name={"confirm"} label={"Подтвердите пароль:"} style={{width: 285}} rules={[{*/}
            {/*            required: true,*/}
            {/*            message: "Поле не может быть пустым!"*/}
            {/*        }]}>*/}
            {/*            <Input.Password/>*/}
            {/*        </Form.Item>*/}
            {/*    </>}*/}
            {/*    {isCode && <Form.Item name={"code"} rules={[{required: true}, {*/}
            {/*        validator: (_, value) => {*/}
            {/*            if (value !== undefined && value.indexOf("_") !== -1) {*/}
            {/*                return Promise.reject(new Error("Поле должно быть заполнено полностью!"))*/}
            {/*            } else {*/}
            {/*                return Promise.resolve()*/}
            {/*            }*/}
            {/*        }*/}
            {/*    }]} style={{display: 'inline-block'}}*/}
            {/*                          label={"Укажите 4 последние цифры с номера, который Вам позвонил. На звонок отвечать не нужно"}>*/}
            {/*        <MaskedInput value={codeValue} style={{width: 165}} mask={'0000'} onChange={({target: {value}}) => {*/}
            {/*            setCodeValue(value)*/}
            {/*        }}/>*/}
            {/*    </Form.Item>}*/}
            {/*    {isCode && <Form.Item className={"askBlock"}>*/}
            {/*        <span className={`askAgain ${againAvailable ? "available" : ""}`} onClick={() => {*/}
            {/*            if (againAvailable) {*/}
            {/*                setAgainAvailable(false);*/}
            {/*                */}
            {/*            }*/}
            {/*        }}>Запросить код повторно</span> {!againAvailable && <span*/}
            {/*        className={"timer"}>через {moment.utc(time * 1000).format("mm:ss")}</span>}*/}
            {/*    </Form.Item>}*/}
            {/*    {!isCode && <>*/}
            {/*        <Form.Item>*/}
            {/*            <Button htmlType={"submit"}>Получить код</Button>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item>*/}
            {/*            <StyledCheckbox>Чужой компьютер</StyledCheckbox>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item>*/}
            {/*            <StyledCheckbox>Согласен с условиями использования интернет-магазина</StyledCheckbox>*/}
            {/*        </Form.Item>*/}
            {/*    </>}*/}
            {/*</Form>*/}
        </StyledCreateProfilePage>
    );
}

export default CreateProfilePage;