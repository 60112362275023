import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import {Link} from "react-router-dom";
import {GroupType, ProductType} from "../utils/types";
import {useSelector} from "react-redux";
import _ from "lodash";

export const StyledBreadcrumbs = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a, span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 40px;
    text-decoration: none;


    color: #8A8A8A;
  }

  .separator {
    margin: 0 5px;
  }
`;

function Breadcrumbs({onCatalog}: any) {
    const item: ProductType = useSelector((state: any) => state.pageReducer.item);
    const groups: GroupType[] = useSelector((store: any) => store.pageReducer.groups)


    const flattenItems = useCallback((items: any, key: any) => {
        return items.reduce((flattenedItems: any, item: any) => {
            flattenedItems.push(item)
            if (Array.isArray(item[key])) {
                flattenedItems = flattenedItems.concat(flattenItems(item[key], key))
            }
            return flattenedItems
        }, [])
    }, [item])

    const groups_ = useMemo(() => {
        const group1: GroupType | undefined = _.find(flattenItems(groups, 'ChildGroups'), ['Id', item.GroupId]);
        const group2: GroupType | undefined = _.find(flattenItems(groups, 'ChildGroups'), ['Id', group1?.ParentGroupId]);
        const group3: GroupType | undefined = _.find(flattenItems(groups, 'ChildGroups'), ['Id', group2?.ParentGroupId]);

        return _.compact([group3, group2, group1]).map((el, index, array) => <React.Fragment key={el?.Id}>
            <Link to={`/catalog?group=${el?.Id}`}>
                {el.Name}
            </Link>
            <span className="separator">
                /
            </span>
        </React.Fragment>)

    }, [item, groups])

    const routes = [
        {path: "/favorites", breadcrumb: "Избранное"},
        {path: "/about", breadcrumb: "список проектов"},
        {path: "/media", breadcrumb: "Медиатека"},
        {path: "/cart", breadcrumb: "Корзина"},
        {path: "/doc", breadcrumb: null},
        {path: "/doc/:id", breadcrumb: "Документы"},
        {path: "/catalog", breadcrumb: "Каталог"},
        {path: "/location", breadcrumb: "Адрес"},
        {path: "/catalog/:id", breadcrumb: item.Name, props: {groups: groups_}},
        {path: "/", breadcrumb: "Главная"}]

    const breadcrumbs = useReactRouterBreadcrumbs(routes);
    return (
        <StyledBreadcrumbs>
            {breadcrumbs.map(({match, breadcrumb}: any, index) => {
                if (breadcrumb.key === "/catalog" && onCatalog !== undefined) {
                    return <React.Fragment key={index}>
                        <span onClick={onCatalog} style={{cursor: "pointer"}}>
                            {breadcrumb}
                        </span>
                        {breadcrumbs.length > 1 && breadcrumbs.length - 1 !== index && <span className="separator">
                        /
                    </span>}
                    </React.Fragment>
                } else if (match?.route?.props?.groups?.length > 0) {
                    return [...match?.route?.props?.groups, <React.Fragment key={index}>
                        <Link to={match.pathname}>
                            {breadcrumb}
                        </Link>
                        {breadcrumbs.length > 1 && breadcrumbs.length - 1 !== index && <span className="separator">
                        /
                    </span>}
                    </React.Fragment>]
                } else {
                    return <React.Fragment key={index}>
                        <Link to={match.pathname}>
                            {breadcrumb}
                        </Link>
                        {breadcrumbs.length > 1 && breadcrumbs.length - 1 !== index && <span className="separator">
                        /
                    </span>}
                    </React.Fragment>
                }
            })}
        </StyledBreadcrumbs>
    );
}

export default Breadcrumbs;