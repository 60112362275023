export const initialState = {
    loginReducer: {
        loading: true,
        userData: {},
        videos: [],
    },
    adminReducer: {
        orders: [],
        users: [],
        config: {},
        groups: [],
        videos: [],
        catalog: [],
        subGroups: {},
    },
    pageReducer: {
        showCatalog: false,
        loading: true,
        progress: null,
        groups: [],
        items: [],
        hotItems: [],
        popularItems: [],
        favorites: [],
        cart: [],
        localFavorites: [],
        localCart: [],
        localCartItems: [],
        orders: [],
        searchResult: [],
        item: {},
        group: {},
        feedbackOpen: false
    }
}