import React from 'react';

function ProfileSvg(props: any) {
    return (
        <svg {...props} width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M29.7319 25.3596V28.2853C41.1915 34.7058 47 31.4956 47 46H23.856H23.5712H0C0 30.9338 5.80099 34.7058 17.2681 28.2853V25.7026C15.2595 24.309 14.8322 21.7992 14.1127 19.1508H14.0378C13.753 19.1508 13.4682 18.7349 13.4682 18.1731C13.4682 18.0345 13.5431 18.0345 13.5431 17.8958C13.3258 17.6186 13.1084 17.1954 12.966 16.6409C12.7487 15.8019 12.0367 14.1968 12.6812 13.4307C13.2508 12.9419 13.6106 13.5037 13.6106 13.5037C12.8236 9.2501 11.6769 3.66866 17.7627 1.92492C17.7627 -1.70119 32.0179 -0.096068 33.6667 5.82828C34.4537 8.12651 33.4494 13.4307 33.4494 13.4307C33.6667 13.0805 33.9515 12.9419 34.2363 13.2264C35.0233 13.7152 34.1614 15.9478 33.9515 16.6409C33.8091 17.2684 33.5918 17.6843 33.3819 18.0345L33.4569 18.1731C33.4569 18.6619 33.1721 19.1508 32.8798 19.1508C32.2352 21.5292 31.5907 24.1047 29.7319 25.3596Z"
                  fill="#D6D6D6"/>
        </svg>
    );
}

export default ProfileSvg;