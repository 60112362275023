import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Form, Input, message, Modal, Select, Space, Spin, Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import EditSVG from "../../icons/EditSVG";
import {StyledCheckbox} from "../../components/StyledCheckbox";
import {StyledButton} from "../../components/StyledButton";
import {PaginationType, SortingOrderType, UsersSortType, VideoSortType, VideoType} from "../../utils/types";
import store from "../../redux/store";
import {addVideoAction, editVideoAction, getVideosAction, removeVideoAction} from "../../redux/actions/adminActions";
import {useSelector} from "react-redux";
import ArrowsSVG from "../../icons/ArrowsSVG";
import SimpleModal from "../../components/SimpleModal";

const {Option} = Select;

const VideoInfo = styled.div`
  display: flex;
  align-items: center;

  .title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #202020;
  }
`;

const VideoThumb = styled.iframe`
  width: 183px;
  height: 90px;
  object-fit: cover;
  margin-right: 16px;
  border: none;
`;

const StyledTable = styled<any>(Table)`
  max-width: 1200px;
  width: 100%;

  .ant-table {
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    margin-bottom: 150px;

    table {
      .ant-table-thead {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 96%;
          transform: translate(-50%, 0);
          left: 50%;
          border-bottom: 1px solid #DCDCDC;
          z-index: 99;
        }

        tr {
          th {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            color: #898989;
            background: #fff;
            border: none;

            &::before {
              display: none;
            }
          }
        }
      }

      .ant-table-tbody {
        tr:nth-child(even) {
          background: #F9F9F9;
        }
      }
    }
  }
`;

const StyledInputSearch = styled(Input)`
  max-width: 230px;
  width: 100%;

  border: 1px solid #DCDCDC;
  border-radius: 8px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #898989;
`;

const Top = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 35px;
  margin-top: 45px;

  .sortOrder {
    cursor: pointer;
    user-select: none;
  }

  .ant-btn {
    margin-left: auto;
  }
`;

const StyledSelect = styled(Select)`
  max-width: 230px;
  width: 100%;
  text-align: center;
  min-width: 230px;
  margin-left: 16px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #898989;

  .ant-select-selection-item {
    opacity: 0.7;
  }

  .ant-select-selector {

    border: 1px solid #DCDCDC !important;
    border-radius: 8px;
    height: 41px !important;
    align-items: center;

  }
`;

const DeleteTitle = styled.div`
  display: flex;
  align-items: center;

  .ant-checkbox {
    margin-right: 8px;
    top: -1px;
  }
`;


const StyledModal = styled(Modal)`
  h1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-top: 34px;

    text-align: center;
    margin-bottom: 34px;
    color: #898989;
  }

  .ant-form {
    padding: 0 38px;

    .ant-input {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      padding: 12px 15px;
      color: #898989;
      border-radius: 8px;
    }
  }
`;

const TableWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  position: relative;

  .ant-btn {
    position: absolute;
    bottom: 100px;
    right: 0;
  }
`;


const StyledMediaPage = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  .removeModalWrap {
    top: 30%;
  }

  .ant-modal-wrap {
    display: flex;
    margin: 10px 0;

    .ant-modal {
      padding-bottom: 0;
      top: 0;
    }

    .removeModal {
      width: auto !important;

      .ant-modal-content {
        padding: 40px 40px 30px 40px;
      }

      h1 {
        margin-bottom: 25px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;

        color: #898989;
      }
    }
  }
  
  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    display: flex;
    align-items: center;

    color: #000000;
  }

  .sorting {
    .ant-select-item {
      .ant-select-item-option-content {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #898989;

        opacity: 0.7;
      }
    }
  }
`;

function MediaPage() {
    const [createVisible, setCreateVisible] = useState<boolean>(false);
    const [editVisible, setEditVisible] = useState<boolean>(false);
    const [createRef] = Form.useForm();
    const [editRef] = Form.useForm();
    const videos: PaginationType<VideoType[]> = useSelector((state: any) => state.adminReducer.videos);

    const [deleteIds, setDeleteIds] = useState<number[]>([]);

    const [editId, setEditId] = useState<number | undefined>(undefined);

    const [page, setPage] = useState<number>(1);

    const [loading, setLoading] = useState<boolean>(false);

    const [searchValue, setSearchValue] = useState<string>("");


    const [sortValue, setSortValue] = useState<UsersSortType | undefined>(undefined);
    const [sortOrder, setSortOrder] = useState<SortingOrderType>(SortingOrderType.asc);

    const columns: ColumnsType<VideoType> = [
        {
            title: 'Видео',
            dataIndex: 'Link',
            key: 'Link',
            width: "50%",
            render: (text, record) => <VideoInfo>
                <VideoThumb allowFullScreen={true} src={text?.replace("watch?v=", "embed/")}/>
                <span className={"title"}>{record.Name}</span>
            </VideoInfo>,
        },
        {
            title: 'Переименовать',
            render: (_, record) => <EditSVG style={{cursor: "pointer"}} onClick={() => setEditId(record.Id)}/>,
        },
        {
            title: 'Видимость',
            dataIndex: 'Visibility',
            render: (_, record) => {
                return (loading ? <Spin/> :
                        <StyledCheckbox checked={_} onClick={() => {
                            setLoading(true)
                            store.dispatch(editVideoAction(record.Id, {...record, Visibility: !_}, false)).then(() => {
                                store.dispatch(getVideosAction(page));
                                setLoading(false);
                            })
                        }}/>
                )
            },
        },
        {
            title: <DeleteTitle><StyledCheckbox
                checked={videos?.Data?.map(el => el.Id)?.every(el => deleteIds.includes(el))}
                onClick={({target: {checked}}: any) => {
                    if (checked)
                        setDeleteIds(deleteIds => [...deleteIds, ...videos?.Data.map(el => el.Id)])
                    else
                        setDeleteIds(deleteIds => deleteIds.filter(el => !videos?.Data.map(el => el.Id)?.includes(el)))
                }}/> Удалить</DeleteTitle>,
            render: (_, record) => <StyledCheckbox checked={deleteIds.indexOf(record.Id) !== -1}
                                                   onClick={({target: {checked}}: any) => setDeleteIds(values => {
                                                       if (checked)
                                                           return [...values, record.Id]
                                                       else
                                                           return values.filter(el => el !== record.Id)
                                                   })}/>,
        },
    ];


    useEffect(() => {
        if (editId !== undefined)
            setEditVisible(true)
        else
            setEditVisible(false)
    }, [editId])

    useEffect(() => {
        const controller = new AbortController();
        store.dispatch(getVideosAction(page, searchValue, sortValue, sortOrder, controller.signal));

        return () => {
            controller.abort();
        }
    }, [page, sortValue, sortOrder, searchValue])

    const handleChangePage = (Page: number) => {
        // console.log(Page);
        setPage(Page);
    }

    const handleDelete = () => {
        let promises: any = [];
        deleteIds.map(el => promises = [...promises, store.dispatch(removeVideoAction(el))])
        Promise.all(promises).then(() => {
            // message.warning(deleteIds.length > 1 ? "Видео успешно удалены!" : "Видео успешно удалено!")
            store.dispatch(getVideosAction(page, searchValue))
            setDeleteIds([]);
            setRemovedAFew(deleteIds.length > 1);
            setDeleteConfirmOpen(false);
            setDeleteSuccessful(true);
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }

    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
    const [deleteSuccessful, setDeleteSuccessful] = useState<boolean>(false);
    const [editedSuccessful, setEditedSuccessful] = useState<boolean>(false);
    const [addedSuccessful, setAddedSuccessful] = useState<boolean>(false);
    const [removedAFew, setRemovedAFew] = useState<boolean>(false);

    return (
        <StyledMediaPage>
            <SimpleModal open={addedSuccessful} onCancel={() => setAddedSuccessful(false)}>
                Видео успешно добавлено!
            </SimpleModal>
            <SimpleModal open={editedSuccessful} onCancel={() => setEditedSuccessful(false)}>
                Видео успешно переименовано!
            </SimpleModal>
            <SimpleModal open={deleteSuccessful} onCancel={() => setDeleteSuccessful(false)}>
                {removedAFew ? "Видео успешно удалены!" : "Видео успешно удалено!"}
            </SimpleModal>
            <SimpleModal onCancel={() => setDeleteConfirmOpen(false)} closable={false} wrapClassName={"removeModalWrap"}
                         open={deleteConfirmOpen} className={"removeModal"}
                         getContainer={() => document.querySelector(`.${StyledMediaPage.styledComponentId}`)!}>
                <h1>{deleteIds.length > 1 ? "Вы действительно хотите удалить эти видео?" : "Вы действительно хотите удалить это видео?"}</h1>
                <Space size={14}>
                    <StyledButton onClick={handleDelete}>Подтвердить</StyledButton>
                    <StyledButton onClick={() => setDeleteConfirmOpen(false)}
                                  className={"cancel"}>Отменить</StyledButton>
                </Space>
            </SimpleModal>
            <StyledModal open={createVisible} onCancel={() => setCreateVisible(false)} footer={null} closable={false}>
                <h1>Загрузка видео</h1>
                <Form form={createRef} layout={"vertical"} onFinish={(fields) => {
                    store.dispatch(addVideoAction({...fields, Visibility: false}, false)).then(() => {
                        store.dispatch(getVideosAction(page, searchValue));
                        createRef.resetFields();
                        setCreateVisible(false);
                        setAddedSuccessful(true);
                    })
                }}>
                    <Form.Item name={"Link"} rules={[
                        {
                            required: true,
                            message: "Это поле не может быть пустым!"
                        },
                        {
                            pattern: new RegExp(/(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w\-_]+)&?/),
                            message: "Допускаются ссылки только формата 'https://youtube.com/watch?v=***********'"
                        }]}>
                        <Input placeholder={"Вставьте ссылку на видео"}/>
                    </Form.Item>
                    <Form.Item name={"Name"} rules={[{
                        required: true,
                        message: "Это поле не может быть пустым!"
                    }]}>
                        <Input placeholder={"Введите название видео"}/>
                    </Form.Item>
                    <Form.Item style={{display: "flex", justifyContent: "center", marginTop: 60}}>
                        <Space size={14}>
                            <StyledButton htmlType={"submit"}>Подтвердить</StyledButton>
                            <StyledButton className={"cancel"} onClick={() => {
                                createRef.resetFields();
                                setCreateVisible(false)
                            }}>Отменить</StyledButton>
                        </Space>
                    </Form.Item>
                </Form>
            </StyledModal>
            <StyledModal open={editVisible} onCancel={() => setEditId(undefined)} footer={null} closable={false}>
                <h1>Переименовать видео</h1>
                <Form form={editRef} layout={"vertical"} onFinish={(fields) => {
                    store.dispatch(editVideoAction(editId, {...videos?.Data?.find(el => el.Id === editId), ...fields}, false)).then(() => {
                        store.dispatch(getVideosAction(page, searchValue));
                        editRef.resetFields();
                        setEditId(undefined);
                        setEditedSuccessful(true);
                    })
                }}>
                    <Form.Item name={"Name"} rules={[{
                        required: true,
                        message: "Это поле не может быть пустым!"
                    }]}>
                        <Input placeholder={"Введите новое название видео"}/>
                    </Form.Item>
                    <Form.Item style={{display: "flex", justifyContent: "center", marginTop: 60}}>
                        <Space size={14}>
                            <StyledButton htmlType={"submit"}>Подтвердить</StyledButton>
                            <StyledButton className={"cancel"} onClick={() => {
                                editRef.resetFields();
                                setEditId(undefined)
                            }}>Отменить</StyledButton>
                        </Space>
                    </Form.Item>
                </Form>
            </StyledModal>
            <Top>
                <StyledInputSearch
                    value={searchValue}
                    placeholder={"Поиск видео"}
                    onChange={({target: {value}}: any) => {
                        setSearchValue(value);
                    }}
                />
                <Space size={30}>
                    <StyledSelect popupClassName={"sorting"}
                                  allowClear
                                  value={sortValue}
                                  onChange={(value: any) => {
                                      setSortValue(value);
                                  }}
                                  getPopupContainer={() => document.querySelector(`.${StyledMediaPage.styledComponentId}`)!}
                                  placeholder={"Порядок по умолчанию"}>
                        <Option value={VideoSortType.name}>По названию</Option>
                        <Option value={VideoSortType.created}>По дате добавления</Option>
                        <Option value={VideoSortType.updated}>По дате изменения</Option>
                    </StyledSelect>
                    <ArrowsSVG className={"sortOrder"}
                               value={sortOrder}
                               onClick={() => setSortOrder(sortOrder => sortOrder === SortingOrderType.asc ? SortingOrderType.desc : SortingOrderType.asc)}/>
                </Space>
                <StyledButton onClick={() => setCreateVisible(true)}>+Добавить видео</StyledButton>
            </Top>
            <TableWrap>
                <StyledTable columns={columns} dataSource={videos.Data} rowKey={'Id'} pagination={{
                    current: videos.Page,
                    total: videos.Total,
                    pageSize: videos.Count,
                    onChange: handleChangePage
                }}/>
                <StyledButton disabled={deleteIds.length <= 0}
                              onClick={()=>setDeleteConfirmOpen(true)}>Удалить видео</StyledButton>
            </TableWrap>
        </StyledMediaPage>
    );
}

export default MediaPage;