import React from 'react';
import styled from "styled-components";
import {Modal, ModalProps} from "antd";
import Close2SVG from "../icons/Close2SVG";

const StyledSimpleModal = styled(Modal)`
  top: 30%;

  .ant-modal-content {
    padding: 100px 20px;

    .ant-modal-body {
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;

      color: #898989;

      .closeBtn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
`;

function SimpleModal({children, onCancel, open = false, closable = true, ...props}: ModalProps) {

    return (
        <StyledSimpleModal onCancel={onCancel} open={open} footer={null}
                           closable={false} {...props}>
            {closable && <Close2SVG onClick={onCancel} className={"closeBtn"}/>}
            {children}
        </StyledSimpleModal>
    );
}

export default SimpleModal;