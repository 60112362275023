import styled from "styled-components";
import {Button} from "antd";

export const StyledButton = styled(Button)`
  background: #775DA6;
  border-radius: 8px;
  color: #ffffff;
  width: max-content;
  border: none;
  padding: 12px 18px;
  height: auto;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  &.cancel {
    background: #fff;
    color: #775da6;
    border: 1px solid #775da6;
    padding: 11px 18px;

    &:hover {
      border: 1px solid #775da6;
      color: #775da6 !important;
    }
  }

  &:hover {
    color: #ffffff !important;
    opacity: .8;
  }
`;
