import React, {useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Button, Form, Input} from "antd";
import {StyledCheckbox} from "../../components/StyledCheckbox";
import store from "../../redux/store";
import {loginAction} from "../../redux/actions/loginActions";

const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 45px;

  h1 {
    margin: 0 12px 0 0;

    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;

    color: #311C1C;
  }

  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    display: flex;
    align-items: center;

    color: #5B77CB;
  }
`;

const StyledLoginPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 65px 18.75% 220px 18.75%;

  @media screen and (max-width: 1919px) {
    padding: 65px 20px 220px 20px;
  }

  .ant-form-item {
    margin: 0;
  }

  .askBlock {
    margin-top: 35px;

    .askAgain {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      text-decoration-line: underline;

      color: #5B77CB;
    }

    .timer {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;


      color: #8A8A8A;
    }
  }

  .ant-form {
    .ant-input {
      font-weight: 400;
      font-size: 18px;
      height: auto;
      padding: 16px 20px;
      line-height: 140%;

      color: #000000;
    }

    label {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;

      color: #8A8A8A;

      &.ant-checkbox-wrapper {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #000000;
      }
    }
  }


  .ant-btn {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: #FFFFFF;
    border: none;
    padding: 17px 28px;
    height: auto;
    background: #5B77CB;
    border-radius: 4px;
    margin-top: 32px;
    margin-bottom: 24px;
  }
`;

function LoginPage() {
    const [foreignPC, setForeignPC] = useState<boolean>(false);

    return (
        <StyledLoginPage>
            <Top><h1>Войти</h1> <Link to={"/profile/create"}>или создать профиль</Link></Top>

            <Form requiredMark={false} onFinish={(fields) => {
                store.dispatch(loginAction({...fields, save: !foreignPC}))
            }} layout={"vertical"}>
                <Form.Item name={"login"} rules={[{
                    required: true,
                    message: "Поле не может быть пустым!"
                }]} label={"Логин:"}>
                    <Input style={{width: 282, marginBottom: 28}}/>
                </Form.Item>
                <Form.Item name={"password"} rules={[{
                    required: true,
                    message: "Поле не может быть пустым!"
                }]} label={"Пароль:"}>
                    <Input.Password style={{width: 282}}/>
                </Form.Item>
                <Form.Item className={"askBlock"}>
                    <Link to={"/profile/restore"}><span className={"askAgain"}>Забыли пароль?</span></Link>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={"submit"}>Войти</Button>
                </Form.Item>
                <Form.Item>
                    <StyledCheckbox checked={foreignPC} onChange={e => setForeignPC(e.target.checked)}>Чужой
                        компьютер</StyledCheckbox>
                </Form.Item>
            </Form>
        </StyledLoginPage>
    );
}

export default LoginPage;