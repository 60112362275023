import {GroupType} from "./types";

const getDeepGroup = (Ids: number[], groups: GroupType[]) => {
    let defaultValues = Ids;

    const recursionGet: any = (Id: number, array: GroupType[]) => {
        defaultValues = [...defaultValues].splice(1)
        if (defaultValues.length > 0)
            return recursionGet(defaultValues[0], array?.find(el => el.Id === Id)?.ChildGroups || [])
        else
            return array?.find(el => el.Id === Id)
    }
    return recursionGet(defaultValues[0], groups);
}

export default getDeepGroup