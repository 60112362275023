import React from 'react';

function RequiredMarkSvg({className, ...props}: any) {
    return (
        <svg {...props} className={`requiredMark${className ? ` ${className}` : ""}`} width="11" height="11"
             viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.61364 10.7273L4.76136 6.66477L1.3267 8.84375L0.440341 7.29261L4.05966 5.40909L0.440341 3.52557L1.3267 1.97443L4.76136 4.15341L4.61364 0.0909085H6.38636L6.23864 4.15341L9.6733 1.97443L10.5597 3.52557L6.94034 5.40909L10.5597 7.29261L9.6733 8.84375L6.23864 6.66477L6.38636 10.7273H4.61364Z"
                fill="#E33333"/>
        </svg>
    );
}

export default RequiredMarkSvg;