import React from 'react';
import styled from "styled-components";
import {YMaps, Map, Placemark} from "react-yandex-maps";
import Breadcrumbs, {StyledBreadcrumbs} from "../../components/Breadcrumbs";
import {Space} from "antd";

const MapContainer = styled.div`
  width: 100%;
  height: 540px;
  margin-top: 45px;

  padding: 0 18.75% 0 18.75%;
  @media screen and (max-width: 1919px) {
    padding: 0 20px 0 20px;
  }
  @media screen and (max-width: 999px) {
    padding: 0;
  }
`;

const StyledLocationPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0 130px 0;

  ${StyledBreadcrumbs}, p, h1 {
    padding: 0 18.75% 0 18.75%;

    @media screen and (max-width: 1919px) {
      padding: 0 20px 0 20px;
    }
  }

  h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 25px;
    color: #000000;
    margin-top: 32px;
  }

  p, a {
    font-size: 18px;
    line-height: 140%;

    color: #000000;
  }
`;

function LocationPage() {
    return (
        <StyledLocationPage>
            <Breadcrumbs/>
            <h1>Как нас найти:</h1>
            <p><strong>Адрес:</strong> 454081, г. Челябинск, Товарный двор, 14</p>
            <p><strong>Часы работы:</strong> Понедельник-Пятница с 9:00 до 18:00</p>
            <p><strong>Телефон:</strong> <Space>
                <a href={"tel:+79227333371"}>
                    8 922 73 33 371
                </a>
            </Space></p>
            <MapContainer>
                <YMaps>
                    <Map width={"auto"} height={540} defaultState={{center: [55.131826, 61.411115], zoom: 15.89}}>
                        <Placemark geometry={[55.131826, 61.411115]}/>
                    </Map>
                </YMaps>
            </MapContainer>
        </StyledLocationPage>
    );
}

export default LocationPage;