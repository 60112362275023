import React, {useEffect} from 'react';
import styled from "styled-components";
import {Form, Input, Space} from "antd";
import {StyledButton} from "../../components/StyledButton";
import store from "../../redux/store";
import {loginAction} from "../../redux/actions/loginActions";
import {RoleType, UserType} from "../../utils/types";
import {useSelector} from "react-redux";
import History from "../../utils/history";

const FormWrap = styled.div`
  background: #FFFFFF;
  box-shadow: 2px 4px 18px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 60px 60px 55px;

  h1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 34px;

    color: #898989;
  }
`;

const StyledAuthPage = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

function AuthPage() {
    const user: UserType = useSelector((state: any) => state.loginReducer.userData);

    useEffect(() => {
        if (user.Role === RoleType.admin)
            History.push("/admin/home");
    }, [user])

    return (
        <StyledAuthPage>
            <FormWrap>
                <h1>Приветствую тебя, Администратор!</h1>
                <Form onFinish={(fields) => store.dispatch(loginAction(fields))}>
                    <Form.Item name={"login"} style={{marginBottom: 12}}>
                        <Input placeholder={"Логин"}/>
                    </Form.Item>
                    <Form.Item name={"password"}>
                        <Input.Password placeholder={"Пароль"}/>
                    </Form.Item>
                    <Form.Item style={{textAlign: "center", margin: 0}}>
                        <Space size={16}>
                            <StyledButton htmlType={"submit"}>Войти</StyledButton>
                            <StyledButton className={"cancel"}>Отменить</StyledButton>
                        </Space>
                    </Form.Item>
                </Form>
            </FormWrap>
        </StyledAuthPage>
    );
}

export default AuthPage;