import React from 'react';

function SadSmileSvg(props: any) {
    return (
        <svg {...props} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="19" cy="19" r="18.5" fill="white" stroke="#CACACA"/>
            <path d="M11 11L11 16" stroke="#CACACA" strokeWidth="2" strokeLinecap="round"/>
            <path d="M26 11L26 16" stroke="#CACACA" strokeWidth="2" strokeLinecap="round"/>
            <path d="M11 27C11.6667 25.6667 14.5 24.5 18.5 24.5C22.5 24.5 24.8333 25.6667 26 27" stroke="#CACACA"
                  strokeLinecap="round"/>
        </svg>
    );
}

export default SadSmileSvg;