import React from 'react';

function

Arrow45Svg(props: any) {
    return (
        <svg {...props} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 10.75C10.4142 10.75 10.75 10.4142 10.75 10L10.75 3.25C10.75 2.83579 10.4142 2.5 10 2.5C9.58579 2.5 9.25 2.83579 9.25 3.25V9.25H3.25C2.83579 9.25 2.5 9.58579 2.5 10C2.5 10.4142 2.83579 10.75 3.25 10.75L10 10.75ZM0.46967 1.53033L9.46967 10.5303L10.5303 9.46967L1.53033 0.46967L0.46967 1.53033Z"
                fill="#898989"/>
        </svg>
    );
}

export default Arrow45Svg;