import React from 'react';

function CloseSvg(props: any) {
    return (
        <svg {...props} width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="1.35355" y1="0.646447" x2="11.3536" y2="10.6464" stroke="#AEAEAE"/>
            <line x1="0.646447" y1="10.6464" x2="10.6464" y2="0.646447" stroke="#AEAEAE"/>
        </svg>
    );
}

export default CloseSvg;