import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Input, Select, Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {OrderStatusType, OrderType, PaginationType} from "../../utils/types";
import store from "../../redux/store";
import {editOrderAction, getOrdersAction} from "../../redux/actions/adminActions";
import {useSelector} from "react-redux";
import moment from "moment";
import axios from "axios";
import {GET_ORDERS} from "../../redux/actionTypes";
import {api} from "../../config";

const {Option} = Select;

const StyledTable = styled<any>(Table)`
  max-width: 1200px;
  width: 100%;

  .ant-table {
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
    margin-bottom: 50px;

    table {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      /* or 25px */


      color: #000000;

      .ant-table-tbody {
        tr {
          td {
            &:first-child {
              padding-left: 40px;
            }
          }
        }
      }

      .ant-table-thead {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          width: 96%;
          transform: translate(-50%, 0);
          left: 50%;
          border-bottom: 1px solid #DCDCDC;
          z-index: 99;
        }

        tr {
          th {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            color: #000;
            background: #fff;
            border: none;

            &:first-child {
              padding-left: 40px;
            }

            &::before {
              display: none;
            }
          }
        }
      }

      .ant-table-tbody {
        tr:nth-child(even) {
          background: #F9F9F9;
        }
      }
    }
  }
`;

const StyledInputSearch = styled(Input)`
  max-width: 230px;
  width: 100%;

  border: 1px solid #DCDCDC;
  border-radius: 8px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #898989;
`;

const Top = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin-bottom: 35px;

  .ant-btn {
    margin-left: auto;
  }
`;

const StyledRowSelect = styled(Select)`
  .ant-select-selector {
    border: none !important;
    background: none !important;
    box-shadow: none !important;
    padding-left: 0 !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */


    color: #000000;
  }
`;

const StyledSelect = styled(Select)`
  max-width: 230px;
  width: 100%;
  text-align: center;

  margin-left: 16px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #898989;

  .ant-select-selection-item {
    opacity: 0.7;
  }

  .ant-select-selector {
    border: 1px solid #DCDCDC !important;
    border-radius: 8px;
    height: 41px !important;
    align-items: center;
  }
`;

const TableWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  position: relative;

  .ant-btn {
    position: absolute;
    bottom: 100px;
    right: 0;
  }
`;


const StyledOrdersPage = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  .ant-select-dropdown {
    width: fit-content !important;
    .ant-select-item {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;
      /* or 25px */


      color: #000000;
    }
  }

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    display: flex;
    align-items: center;

    color: #000000;
  }

  .sorting {
    .ant-select-item {
      .ant-select-item-option-content {
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #898989;

        opacity: 0.7;
      }
    }
  }
`;

function OrdersPage() {
    const orders: PaginationType<OrderType[]> = useSelector((state: any) => state.adminReducer.orders);

    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedStatus, setSelectedStatus] = useState<OrderStatusType | undefined>(undefined);
    const [searchValue, setSearchValue] = useState<string>("");
    const controller = new AbortController();

    useEffect(() => {
        setPage(1);
    }, [selectedStatus])

    const columns: ColumnsType<OrderType> = [
        {
            title: "Пользователь",
            dataIndex: "UserId"
        },
        {
            title: "Номер заказа",
            dataIndex: "Id"
        },
        {
            title: "Дата заказа",
            dataIndex: "CreatedDate",
            render: (_, record) => moment(_).format("DD.MM.YYYY")
        },
        {
            title: "Статус заказа",
            dataIndex: "Status",
            render: (_, record) => <StyledRowSelect
                getPopupContainer={() => document.querySelector(`.${StyledOrdersPage.styledComponentId}`)!}
                disabled={loading}
                value={_}
                onChange={(value, option) => {
                    setLoading(true);
                    store.dispatch(editOrderAction(record.Id, {...record, Status: value})).then(() => {
                        setLoading(false);
                        store.dispatch(getOrdersAction(page, selectedStatus, searchValue));
                    })
                }}>
                <Option value={OrderStatusType.inWork}>В работе</Option>
                <Option value={OrderStatusType.canceled}>Закрыт</Option>
                <Option value={OrderStatusType.completed}>Выполнен</Option>
            </StyledRowSelect>
        }
    ];

    useEffect(() => {
        store.dispatch(getOrdersAction(page, selectedStatus, searchValue, controller.signal));

        return () => {
            controller.abort();
        }
    }, [page, selectedStatus, searchValue])

    const handleGetOrders = (Page: number) => {
        // console.log(Page);
        setPage(Page);
    }

    return (
        <StyledOrdersPage>
            <Top>
                <StyledInputSearch value={searchValue} placeholder={"Поиск заказа"}
                                   onChange={({target: {value}}) => setSearchValue(value)}/>
                <StyledSelect popupClassName={"sorting"}
                              value={selectedStatus}
                              onChange={(value: any) => setSelectedStatus(value)}
                              allowClear
                              getPopupContainer={() => document.querySelector(`.${StyledOrdersPage.styledComponentId}`)!}
                              placeholder={"Все заказы"}>
                    <Option value={OrderStatusType.inWork}>В работе</Option>
                    <Option value={OrderStatusType.canceled}>Закрыт</Option>
                    <Option value={OrderStatusType.completed}>Выполнен</Option>
                </StyledSelect>
            </Top>
            <TableWrap>
                <StyledTable columns={columns} dataSource={orders.Data} rowKey={'Id'} pagination={{
                    current: orders.Page,
                    total: orders.Total,
                    pageSize: orders.Count,
                    onChange: handleGetOrders
                }}/>
            </TableWrap>
        </StyledOrdersPage>
    );
}

export default OrdersPage;