import React from 'react';

function HomeSvg(props:any) {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M2.1975 7.37357L9.6975 1.53982C11.0513 0.486074 12.9475 0.486074 14.3025 1.53982L21.8025 7.37357C22.7163 8.08357 23.25 9.17607 23.25 10.3336V19.4998C23.25 21.5711 21.5713 23.2498 19.5 23.2498H4.5C2.42875 23.2498 0.75 21.5711 0.75 19.4998V10.3336C0.75 9.17607 1.28375 8.08357 2.1975 7.37357Z"
                  stroke="#898989" strokeWidth="1.5"/>
            <path d="M15.75 18.25H8.25" stroke="#898989" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
}

export default HomeSvg;