import React from 'react';

function Close2Svg(props: any) {
    return (
        <svg {...props} width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5283 17.1943L30.1401 30.8061" stroke="#898989" strokeWidth="1.5" strokeLinecap="square"
                  strokeLinejoin="round"/>
            <path d="M16.5283 30.8057L30.1401 17.1939" stroke="#898989" strokeWidth="1.5" strokeLinecap="square"
                  strokeLinejoin="round"/>
        </svg>
    );
}

export default Close2Svg;