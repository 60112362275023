import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Form, Input, message, Modal, Space, Upload} from "antd";
import EditSVG from "../../icons/EditSVG";
import RemoveSVG from "../../icons/RemoveSVG";
import {StyledButton} from "../../components/StyledButton";
import {useSelector} from "react-redux";
import store from "../../redux/store";
import {
    addGroupImageAction,
    createGroupAction,
    createSubGroupAction,
    deleteGroupAction,
    editGroupAction,
    getGroupsAction,
    removeGroupImageAction
} from "../../redux/actions/adminActions";
import {GroupType} from "../../utils/types";
import SimpleModal from "../../components/SimpleModal";
import {StyledCheckbox} from "../../components/StyledCheckbox";
import {declension} from "../../utils/Declension";
import {RcFile} from "antd/es/upload";
import UploadPlusSVG from "../../icons/UploadPlusSVG";
import Close2SVG from "../../icons/Close2SVG";
import getDeepGroup from "../../utils/getDeepGroup";

const UploadZone = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;
  width: 72px;
  height: 72px;
  border: 1px solid #DCDCDC;
  border-radius: 8px;
  position: relative;

  span {
    font-weight: 400;
    font-size: 14px;

    color: #898989;
    position: absolute;
    bottom: -25px;
  }
`;


const CatalogItem = styled.div`
  display: flex;
  align-items: center;


  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #898989;

  span {
    &:hover {
      cursor: pointer;
      text-decoration-line: underline;

      color: #70B6C1;
    }
  }

  svg {
    &:hover {
      cursor: pointer;

      path {
        stroke: #70B6C1;
      }
    }
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  width: 100%;
  margin-bottom: 174px;
`;

const CatalogList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 2px;
  }
`;

const SubCtgs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  overflow: visible;
  margin-bottom: 10px;
  gap: 8px 60px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 2px;
  }
`;

const SubSubCtgs = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 60px;
  grid-row-gap: 8px;
  overflow: auto;
  margin-bottom: 10px;
  gap: 8px 60px;
  width: calc(100% + 50px);

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #D9D9D9;
    border-radius: 2px;
  }
`;

const SubActions = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  margin-left: auto;

  svg {
    width: 19px;
    height: 19px;

    &:hover {
      cursor: pointer;

      path {
        stroke: #70B6C1;
      }
    }
  }
`;


const SubCtgItem = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  //width: 100%;
  cursor: pointer;
  width: 80%;

  span {

    overflow-wrap: anywhere;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    display: flex;
    align-items: flex-end;
    color: #898989;

    &:hover {
      color: #70b6c1;
    }
  }

`;

const SubCtgItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${SubSubCtgs} {
    padding-left: 28px;
  }
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  max-width: 564px;
  width: 100%;

  padding: 35px 12px 60px 0;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  border-radius: 12px;

  &:not(.secondBlock) {
    .ant-btn {
      margin-left: 35px;
    }
  }

  &.secondBlock {
    padding: 35px 50px 60px 50px;
  }

  ${CatalogItem} {
    padding: 20px 60px 20px 35px;

    &:nth-child(even) {
      background: #F9F9F9;
    }
  }

  .ant-btn {
    margin-top: auto;
  }
`;

const GroupImage = styled.img`
  display: flex;
  height: 150px;
  max-width: 300px;
  border-radius: 6px;
`;

const GroupImageWrap = styled.div`
  display: flex;
  width: fit-content;
  position: relative;

  span {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;

    &:hover {
      opacity: .6;
    }

    svg {
      path {
        stroke: #F54844;
      }
    }
  }
`;

const StyledCatalogManagementPage = styled.div`
  display: flex;
  width: 100%;
  padding-top: 50px;

  textarea {
    resize: none;
    height: 100px;
  }

  .ant-modal {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-modal-body {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    p {
      width: 216px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 25px;
      margin-right: 0;
      color: #898989;

    }
  }

  .ant-form-item {
    margin: 0;
  }

  .ant-input {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    color: #898989;
  }

  .add_sub_ctg {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;

    cursor: pointer;

    display: flex;
    align-items: flex-end;
    text-decoration-line: underline;
    margin-bottom: 10px;
    margin-top: 7px;
    color: #70B6C1;
  }

  h1 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;

    color: #000000;

    &:not(:first-child) {
      margin-top: 26px;
    }
  }
`;

function CatalogManagementPage() {

    const [modal, contextHolder] = Modal.useModal();

    const [selectedCatItem, setSelectedCatItem] = useState<number | undefined>(undefined);
    const [selectedCatItems, setSelectedCatItems] = useState<number[]>([]);

    const [selectedSubGroupId, setSelectedSubGroupId] = useState<number | undefined>(undefined);

    const [addSubOpen, setAddSubOpen] = useState<boolean>(false);

    const [secondBlock, setSecondBlock] = useState<"" | "createGroup" | "renameSubGroup" | "renameGroup" | "editGroup">("");

    const [deleteId, setDeleteId] = useState<number | undefined>(undefined);
    const [deleteParentId, setDeleteParentId] = useState<number | undefined>(undefined);
    const [modalMessage, setModalMessage] = useState<string>("");
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

    const groups: GroupType[] = useSelector((store: any) => store.adminReducer.groups)

    const selectedCatItemData = useMemo(() => groups?.find(el => el.Id === selectedCatItem), [groups, selectedCatItem])

    const selectedEditCatItemData: any = useMemo(() => {
            if (selectedCatItems !== undefined && selectedCatItems.length > 0)
                return getDeepGroup(selectedCatItems, groups);
            else
                return undefined
        },
        [groups, selectedCatItems, selectedCatItem])

    const handleDelete = (Id: number, message: string, parentId?: number) => {
        setDeleteId(Id);
        parentId && setDeleteParentId(parentId);
        setModalMessage(message);
        setShowDeleteModal(true);
    }

    const selectEditItem = (Ids: number[], data: GroupType, parentId: number | undefined = undefined) => {
        setSelectedCatItem(Ids[Ids.length - 1])
        setSelectedCatItems(Ids)
        renameRef.setFieldsValue(data)
    }

    const selectCatItem = (Id: number) => {
        setSelectedCatItem(Id);
    }

    const [createRef] = Form.useForm();
    const [createSubRef] = Form.useForm();
    const [renameRef] = Form.useForm();

    const [deepDelete, setDeepDelete] = useState<boolean>(false);
    const [attachedFile, setAttachedFile] = useState<any>(null);
    const [subAttachedFile, setSubAttachedFile] = useState<any>(null);

    const [simpleModalMessage, setSimpleModalMessage] = useState<string>("");
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const [editLoading, setEditLoading] = useState<boolean>(false);

    useEffect(() => {
        simpleModalMessage === "" ? setModalVisible(false) : setModalVisible(true)
    }, [simpleModalMessage])


    useEffect(() => {
        store.dispatch(getGroupsAction());
    }, [])

    return (
        <StyledCatalogManagementPage>
            {contextHolder}
            <SimpleModal open={modalVisible} onCancel={() => {
                setSimpleModalMessage("")
            }}>
                {simpleModalMessage}
            </SimpleModal>
            <Modal
                closable={false}
                open={showDeleteModal}
                onCancel={() => setShowDeleteModal(false)}
                getContainer={() => document.querySelector(`.${StyledCatalogManagementPage.styledComponentId}`)!}
                footer={null}
            >
                <p>{modalMessage}</p>
                <StyledCheckbox checked={deepDelete} onChange={(e) => setDeepDelete(e.target.checked)}>
                    <p>Удалить вложенные товары<br/> и группы?</p>
                </StyledCheckbox>
                <Space>
                    <StyledButton onClick={() => {
                        store.dispatch(deleteGroupAction(deleteId, deleteParentId, deepDelete)).then(() => {
                            deleteParentId === undefined && setSecondBlock("");
                            deleteParentId === undefined ? setSimpleModalMessage("Группа успешно удалена!") : setSimpleModalMessage("Подгруппа успешно удалена!");
                            setShowDeleteModal(false);
                            setDeleteId(undefined);
                            setDeleteParentId(undefined);
                        })
                    }}>Подтвердить</StyledButton>
                    <StyledButton onClick={() => setShowDeleteModal(false)} className={"cancel"}>Отменить</StyledButton>
                </Space>
            </Modal>
            <Content>
                <Block>
                    <CatalogList>
                        {groups?.map((group) => (
                            <CatalogItem key={group.Id}>
                                <span onClick={() => {
                                    setSecondBlock("editGroup");
                                    selectCatItem(group.Id)
                                }}>{group.Name}</span>
                                <Actions>
                                    <EditSVG onClick={() => {
                                        selectEditItem([group.Id], group)
                                        setSecondBlock("renameGroup");
                                    }}/>
                                    <RemoveSVG
                                        onClick={() => handleDelete(group.Id, `Все товары данной группы, будут удалены. В текущей группе - ${group?.ChildItemIds?.length || 0} ${declension((group?.ChildItemIds?.length || 0), ['товар', 'товара', 'товаров'])}.`)}/>
                                </Actions>
                            </CatalogItem>
                        ))}
                    </CatalogList>
                    <StyledButton onClick={() => setSecondBlock("createGroup")}>+Добавить группу</StyledButton>
                </Block>
                <Block className={"secondBlock"}>
                    {secondBlock === "createGroup" && <>
                        <h1>Добавление группы:</h1>
                        <Form
                            initialValues={{
                                ShowOnMainPage: false
                            }}
                            onFinish={(fields) => {
                                store.dispatch(createGroupAction(fields, attachedFile?.file || null)).then(() => {
                                    setSecondBlock("");
                                    setAttachedFile(null);
                                    createRef.resetFields();
                                })

                            }}
                            form={createRef}>
                            <Form.Item name={"Name"} rules={[{
                                required: true,
                                message: "Поле 'Название' не может быть пустым!"
                            }]}>
                                <Input placeholder={"Введите название группы"}/>
                            </Form.Item>
                            <Form.Item style={{marginTop: 10}} name={"FullDesc"}>
                                <Input.TextArea placeholder={"Введите описание группы"}/>
                            </Form.Item>
                            <Space style={{justifyContent: "space-between", display: "flex", marginTop: 10}}>
                                <Form.Item>
                                    {attachedFile !== null ? <GroupImageWrap>
                                        <GroupImage src={attachedFile.raw}/>
                                        <span onClick={() => {
                                            setAttachedFile(null)
                                        }}>
                                            <Close2SVG/>
                                        </span>
                                    </GroupImageWrap> : <Upload
                                        accept={"image/png, image/jpeg"}
                                        showUploadList={false}
                                        beforeUpload={(file: RcFile) => {
                                            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                            if (!isJpgOrPng) {
                                                message.error('Вы можете загружать только файл JPG/PNG!');
                                            }
                                            const isLt2M = file.size / 1024 / 1024 < 10;
                                            if (!isLt2M) {
                                                message.error('Изображение должно быть меньше 10MB!');
                                            }
                                            return isJpgOrPng && isLt2M;
                                        }}
                                        customRequest={(_: any) => {
                                            const reader = new FileReader();
                                            reader.onloadend = function () {
                                                setAttachedFile({
                                                    file: _.file,
                                                    raw: reader.result
                                                })
                                            }
                                            if (_.file) {
                                                reader.readAsDataURL(_.file);
                                            }
                                        }}>
                                        <UploadZone>
                                            <UploadPlusSVG/>
                                            <span>Фото</span>
                                        </UploadZone>
                                    </Upload>}
                                </Form.Item>
                                <Form.Item name={"ShowOnMainPage"} valuePropName={"checked"}>
                                    <StyledCheckbox>На главную</StyledCheckbox>
                                </Form.Item>
                            </Space>
                        </Form>

                        <StyledButton onClick={() => {
                            createRef.submit();
                            setSelectedCatItem(undefined)
                        }}>Сохранить изменения</StyledButton>
                    </>}

                    {secondBlock === "renameGroup" && <>
                        <h1>Изменить группу:</h1>
                        <Form form={renameRef} onFinish={(fields) => {

                            store.dispatch(editGroupAction(selectedCatItem, {
                                ...selectedEditCatItemData,
                                ...fields
                            })).then(() => {
                                setSimpleModalMessage("Группа успешно изменена!");
                                setSecondBlock("");
                                renameRef.resetFields();
                            })
                        }}>
                            <Form.Item name={"Name"} rules={[{
                                required: true,
                                message: "Поле 'Название' не может быть пустым!"
                            }]}>
                                <Input placeholder={"Введите новое название группы"}/>
                            </Form.Item>
                            <h1>Описание группы:</h1>
                            <Form.Item name={"FullDesc"}>
                                <Input.TextArea placeholder={"Введите описание группы"}/>
                            </Form.Item>
                            <Space style={{justifyContent: "space-between", display: "flex", marginTop: 10}}>
                                {selectedCatItemData?.ImageUrl !== "" ?
                                    <GroupImageWrap style={{margin: "15px 0"}}>
                                        <GroupImage src={`/${selectedCatItemData?.ImageUrl}`}/>
                                        <span onClick={() => {
                                            modal.confirm({
                                                okText: "Удалить",
                                                okType: "danger",
                                                content: "Вы уверены что хотите удалить фото группы?",
                                                onOk: async () => {
                                                    await store.dispatch(removeGroupImageAction(selectedCatItem, selectedCatItemData?.ImageUrl)).then(() => {
                                                        store.dispatch(getGroupsAction());
                                                    })
                                                }
                                            })
                                        }}><Close2SVG/></span>
                                    </GroupImageWrap> : <Upload
                                        accept={"image/png, image/jpeg"}
                                        showUploadList={false}
                                        beforeUpload={(file: RcFile) => {
                                            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                            if (!isJpgOrPng) {
                                                message.error('Вы можете загружать только файл JPG/PNG!');
                                            }
                                            const isLt2M = file.size / 1024 / 1024 < 10;
                                            if (!isLt2M) {
                                                message.error('Изображение должно быть меньше 10MB!');
                                            }
                                            return isJpgOrPng && isLt2M;
                                        }}
                                        customRequest={(_: any) => {
                                            const reader = new FileReader();
                                            reader.onloadend = function () {
                                                store.dispatch(addGroupImageAction(selectedCatItem, _.file))
                                            }
                                            if (_.file) {
                                                reader.readAsDataURL(_.file);
                                            }
                                        }}>
                                        <UploadZone style={{marginTop: 15}}>
                                            <UploadPlusSVG/>
                                            <span>Фото</span>
                                        </UploadZone>
                                    </Upload>}
                                <Form.Item name={"ShowOnMainPage"} valuePropName={"checked"}>
                                    <StyledCheckbox checked={selectedCatItemData?.ShowOnMainPage}>На
                                        главную</StyledCheckbox>
                                </Form.Item>
                            </Space>
                        </Form>
                        <StyledButton onClick={() => {
                            renameRef.submit();
                        }}>Сохранить изменения</StyledButton>
                    </>}

                    {secondBlock === "renameSubGroup" && <>
                        <h1>Изменить подгруппу</h1>
                        <Form form={renameRef} onFinish={(fields) => {
                            store.dispatch(editGroupAction(selectedCatItem, {
                                ...selectedEditCatItemData,
                                ...fields
                            }, true)).then(() => {
                                // setSelectedEditCatItem(undefined);
                                // if (selectedCatItem)
                                selectCatItem(selectedCatItems[0])
                                setSimpleModalMessage("Подгруппа успешно изменена!");
                                setSecondBlock("editGroup");
                                renameRef.resetFields();
                            })
                        }}>
                            <Form.Item name={"Name"} rules={[{
                                required: true,
                                message: "Поле 'Название' не может быть пустым!"
                            }]}>
                                <Input placeholder={"Введите новое название подгруппы"}/>
                            </Form.Item>
                            <Form.Item style={{marginTop: 10}} name={"FullDesc"}>
                                <Input.TextArea placeholder={"Введите описание подгруппы"}/>
                            </Form.Item>
                        </Form>
                        <Space style={{justifyContent: "space-between", display: "flex", marginTop: 10}}>
                            {selectedEditCatItemData?.ImageUrl !== "" ?
                                <GroupImageWrap style={{margin: "15px 0"}}>
                                    <GroupImage src={`/${selectedEditCatItemData?.ImageUrl}`}/>
                                    <span onClick={() => {
                                        modal.confirm({
                                            okText: "Удалить",
                                            okType: "danger",
                                            content: "Вы уверены что хотите удалить фото группы?",
                                            onOk: async () => {
                                                await store.dispatch(removeGroupImageAction(selectedCatItem, selectedEditCatItemData?.ImageUrl)).then(() => {
                                                    store.dispatch(getGroupsAction());
                                                })
                                            }
                                        })
                                    }}><Close2SVG/></span>
                                </GroupImageWrap> : <Upload
                                    accept={"image/png, image/jpeg"}
                                    showUploadList={false}
                                    beforeUpload={(file: RcFile) => {
                                        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                        if (!isJpgOrPng) {
                                            message.error('Вы можете загружать только файл JPG/PNG!');
                                        }
                                        const isLt2M = file.size / 1024 / 1024 < 10;
                                        if (!isLt2M) {
                                            message.error('Изображение должно быть меньше 10MB!');
                                        }
                                        return isJpgOrPng && isLt2M;
                                    }}
                                    customRequest={(_: any) => {
                                        const reader = new FileReader();
                                        reader.onloadend = function () {
                                            store.dispatch(addGroupImageAction(selectedCatItem, _.file))
                                        }
                                        if (_.file) {
                                            reader.readAsDataURL(_.file);
                                        }
                                    }}>
                                    <UploadZone style={{marginTop: 15}}>
                                        <UploadPlusSVG/>
                                        <span>Фото</span>
                                    </UploadZone>
                                </Upload>}
                            <StyledCheckbox
                                disabled={editLoading}
                                checked={selectedEditCatItemData?.ShowOnMainPage}
                                onChange={({target: {checked}}) => {
                                    setEditLoading(true)
                                    store.dispatch(editGroupAction(selectedCatItem, {
                                        ...selectedEditCatItemData,
                                        ShowOnMainPage: checked
                                    })).finally(() => {
                                        setEditLoading(false)
                                    })
                                }}
                            >На главную</StyledCheckbox>
                        </Space>
                        <StyledButton onClick={() => {
                            renameRef.submit();
                        }}>Сохранить изменения</StyledButton>
                    </>}

                    {selectedCatItem && (secondBlock === "editGroup") && <>
                        <h1>Название группы:</h1>
                        <Input disabled value={selectedCatItemData?.Name || "-"}/>
                        <h1>Описание группы:</h1>
                        <Input.TextArea
                            disabled
                            value={selectedCatItemData?.FullDesc}/>
                        <Space style={{justifyContent: "space-between", display: "flex", marginTop: 10}}>
                            {selectedCatItemData?.ImageUrl !== "" &&
                                <GroupImageWrap style={{margin: "15px 0"}}>
                                    <GroupImage src={`/${selectedCatItemData?.ImageUrl}`}/>
                                </GroupImageWrap>}
                            <StyledCheckbox
                                disabled
                                checked={selectedCatItemData?.ShowOnMainPage}
                            >На главную</StyledCheckbox>
                        </Space>
                        {addSubOpen ? <>
                                <h1 style={{marginTop: 35}}>{selectedSubGroupId !== undefined ? "Добавление подгруппы 1:" : "Добавление подгруппы:"}</h1>
                                <Form form={createSubRef} onFinish={(fields) => {
                                    store.dispatch(createSubGroupAction(selectedSubGroupId || selectedCatItem, fields, subAttachedFile?.file || null)).then(() => {
                                        setAddSubOpen(false);
                                        setSimpleModalMessage("Подгруппа успешно добавлена!")
                                        setSubAttachedFile(null);
                                        createSubRef.resetFields();
                                        store.dispatch(getGroupsAction());
                                    })
                                }}>
                                    <Form.Item name={"Name"} rules={[{
                                        required: true,
                                        message: "Поле 'Название' не может быть пустым!"
                                    }]}>
                                        <Input placeholder={"Введите название подгруппы"}/>
                                    </Form.Item>
                                    <Form.Item style={{marginTop: 10}} name={"FullDesc"}>
                                        <Input.TextArea placeholder={"Введите описание подгруппы"}/>
                                    </Form.Item>
                                    <Space style={{justifyContent: "space-between", display: "flex", marginTop: 10}}>
                                        <Form.Item>
                                            {subAttachedFile !== null ? <GroupImageWrap>
                                                <GroupImage src={subAttachedFile.raw}/>
                                                <span onClick={() => {
                                                    setSubAttachedFile(null)
                                                }}>
                                            <Close2SVG/>
                                        </span>
                                            </GroupImageWrap> : <Upload
                                                accept={"image/png, image/jpeg"}
                                                showUploadList={false}
                                                beforeUpload={(file: RcFile) => {
                                                    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                                                    if (!isJpgOrPng) {
                                                        message.error('Вы можете загружать только файл JPG/PNG!');
                                                    }
                                                    const isLt2M = file.size / 1024 / 1024 < 10;
                                                    if (!isLt2M) {
                                                        message.error('Изображение должно быть меньше 10MB!');
                                                    }
                                                    return isJpgOrPng && isLt2M;
                                                }}
                                                customRequest={(_: any) => {
                                                    const reader = new FileReader();
                                                    reader.onloadend = function () {
                                                        setSubAttachedFile({
                                                            file: _.file,
                                                            raw: reader.result
                                                        })
                                                    }
                                                    if (_.file) {
                                                        reader.readAsDataURL(_.file);
                                                    }
                                                }}>
                                                <UploadZone>
                                                    <UploadPlusSVG/>
                                                    <span>Фото</span>
                                                </UploadZone>
                                            </Upload>}
                                        </Form.Item>
                                        <Form.Item name={"ShowOnMainPage"} valuePropName={"checked"}>
                                            <StyledCheckbox>На главную</StyledCheckbox>
                                        </Form.Item>
                                    </Space>
                                </Form>
                                <Space style={{marginTop: 38, marginBottom: 20}}>
                                    <StyledButton onClick={() => createSubRef.submit()}>Добавить</StyledButton>
                                    <StyledButton className={"cancel"}
                                                  onClick={() => setAddSubOpen(false)}>Отмена</StyledButton>
                                </Space>
                            </> :
                            <>
                                <h1>Подгруппы группы:</h1>
                                <span className={"add_sub_ctg"}
                                      onClick={() => {
                                          setAddSubOpen(true)
                                          setSelectedSubGroupId(undefined);
                                      }}>+Добавить подгруппу</span>
                            </>
                        }
                        <SubCtgs>
                            {groups.find(el => el.Id === selectedCatItem)?.ChildGroups?.map((sub_group, index) =>
                                <SubCtgItemWrap key={index}>
                                    <SubCtgItem>
                                        <span
                                            onClick={() => {
                                                setAddSubOpen(false);
                                                setSelectedSubGroupId(selectedSubGroupId => selectedSubGroupId === sub_group.Id ? undefined : sub_group.Id)
                                            }}>{sub_group.Name}</span>
                                        <SubActions><EditSVG onClick={() => {
                                            selectEditItem([sub_group.ParentGroupId, sub_group.Id], sub_group)
                                            setSecondBlock("renameSubGroup");
                                        }}/> <RemoveSVG
                                            onClick={() => handleDelete(sub_group.Id, `Все товары данной подгруппы, будут удалены. В текущей подгруппе - ${sub_group?.ChildItemIds?.length || 0} товаров.`, sub_group.ParentGroupId)}
                                        /></SubActions>
                                    </SubCtgItem>
                                    {selectedSubGroupId === sub_group.Id && <SubSubCtgs>
                                        <h1>Подгруппа 1</h1>
                                        {sub_group?.ChildGroups?.map(sub_sub_group => <SubCtgItem
                                            key={sub_sub_group.Id}>
                                            <span>{sub_sub_group.Name}</span>
                                            <SubActions><EditSVG onClick={() => {
                                                selectEditItem([sub_group.ParentGroupId, sub_group.Id, sub_sub_group.Id], sub_sub_group)
                                                setSecondBlock("renameSubGroup");
                                            }}/> <RemoveSVG
                                                onClick={() => handleDelete(sub_sub_group.Id, `Все товары данной подгруппы, будут удалены. В текущей подгруппе - ${sub_sub_group?.ChildItemIds?.length || 0} товаров.`, sub_sub_group.ParentGroupId)}
                                            /></SubActions>
                                        </SubCtgItem>)}
                                        <span className={"add_sub_ctg"}
                                              onClick={() => {
                                                  setAddSubOpen(true)
                                              }}>+Добавить подгруппу</span>

                                    </SubSubCtgs>

                                    }
                                </SubCtgItemWrap>)}
                        </SubCtgs>
                        <StyledButton className={"cancel"}
                                      onClick={() => setSelectedCatItem(undefined)}>Отмена</StyledButton>
                    </>}
                </Block>
            </Content>
        </StyledCatalogManagementPage>
    );
}

export default CatalogManagementPage;