import React from 'react';

function SearchArrowSvg(props: any) {
    return (
        <svg {...props} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.999999 0.249999C0.585786 0.249999 0.249999 0.585786 0.249999 0.999999L0.249999 7.75C0.249999 8.16421 0.585785 8.5 0.999999 8.5C1.41421 8.5 1.75 8.16421 1.75 7.75V1.75H7.75C8.16421 1.75 8.5 1.41421 8.5 0.999999C8.5 0.585785 8.16421 0.249999 7.75 0.249999L0.999999 0.249999ZM12.5303 11.4697L1.53033 0.469669L0.469669 1.53033L11.4697 12.5303L12.5303 11.4697Z"
                fill="black"/>
        </svg>
    );
}

export default SearchArrowSvg;