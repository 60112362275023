import React, {useEffect} from 'react';
import styled from "styled-components";
import HomeSVG from "../icons/HomeSVG";
import {Link, useNavigate} from "react-router-dom";
import {api} from "../config";
import ArrowSVG from "../icons/ArrowSVG";
import {StyledCheckbox} from "./StyledCheckbox";

const Header = styled.div`
  display: flex;
  min-height: 72px;
  background: #FFFFFF;
  padding: 0 18%;
  align-items: center;

  .back {
    margin-left: 15px;
    cursor: pointer;
  }

  .exit {
    margin-left: auto;
  }
`;

const Content = styled.div`
  display: flex;
  background: #F2F1F3;
  flex: 1 1 auto;
  overflow: auto;
`;

const StyledAdminLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;

  ${StyledCheckbox} {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #70B6C1 !important;
      }
    }
  }

  * {
    font-family: Inter, Arial, sans-serif;
  }
`;

function AdminLayout({children}: any) {
    const navigate = useNavigate();
    useEffect(() => {
        // @ts-ignore
        document.body.style.zoom = 1;
    }, [])
    return (
        <StyledAdminLayout>
            <Header>
                <Link to={"/admin/home"}>
                    <HomeSVG/>
                </Link>
                <ArrowSVG className={"back"} onClick={() => navigate(-1)}/>
                <a className={"exit"} href={`${api}/exit`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.775 12H23.25" stroke="#898989" strokeWidth="1.5" strokeLinecap="round"
                              strokeLinejoin="round"/>
                        <path d="M12 7.78125L7.78125 12L12 16.2175" stroke="#898989" strokeWidth="1.5"
                              strokeLinecap="round" strokeLinejoin="round"/>
                        <path
                            d="M23.25 17.625V20.4375C23.25 21.9913 21.9913 23.25 20.4375 23.25H3.5625C2.00875 23.25 0.75 21.9913 0.75 20.4375V3.5625C0.75 2.00875 2.00875 0.75 3.5625 0.75H20.4375C21.9913 0.75 23.25 2.00875 23.25 3.5625V6.375"
                            stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </a>
            </Header>
            <Content>
                {children}
            </Content>
        </StyledAdminLayout>
    );
}

export default AdminLayout;