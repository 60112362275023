import React from 'react';

function UserSvg(props: any) {
    return (
        <svg {...props} width="70" height="80" viewBox="0 0 70 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M47.9887 47.886H21.5136C9.64292 47.886 0.015625 57.5133 0.015625 69.384V79.8206H69.4761V69.384C69.4787 57.5133 59.8593 47.886 47.9887 47.886ZM43.2646 76.1247H40.0933H29.4063H26.2323L21.3027 50.2348C21.7825 50.1953 22.2702 50.161 22.7605 50.161H46.7391C47.2347 50.161 47.7119 50.1953 48.1969 50.2348L43.2646 76.1247Z"
                fill="#616E7D"/>
            <path
                d="M37.4414 58.7208L40.0934 76.1248H43.2647L48.1969 50.2349C47.7119 50.1954 47.2347 50.1611 46.7391 50.1611H22.7605C22.2702 50.1611 21.7825 50.1954 21.3027 50.2349L26.2324 76.1248H29.4063L32.0557 58.7208H32.1506L29.546 53.9414H39.951L37.3465 58.7208H37.4414Z"
                fill="white"/>
            <path
                d="M29.4062 76.1248H40.0933L37.4413 58.7208H37.349L39.9536 53.9441H29.5486L32.1505 58.7208H32.0556L29.4062 76.1248Z"
                fill="#82BFAB"/>
            <path
                d="M57.6177 22.8635C57.6177 10.2389 47.3788 0 34.7516 0C22.1191 0 11.8828 10.2389 11.8828 22.8635C11.8828 35.4987 22.1191 45.7297 34.7516 45.7297C47.3762 45.7297 57.6177 35.496 57.6177 22.8635Z"
                fill="#616E7D"/>
        </svg>
    );
}

export default UserSvg;