import {initialState} from "../initialState";
import {LOGIN_SUCCESS, LOGOUT_SUCCESS} from "../actionTypes";

export function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {...state,
                userData: action.payload,
                loading: false
            }
        case LOGOUT_SUCCESS:
            return {...state,
                userData: {},
                loading: false
            }
        default:
            return state;
    }
}