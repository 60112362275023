import React, {useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {Button, Checkbox, Form, Input, Select} from "antd";
import {MaskedInput} from "antd-mask-input";
import {StyledCheckbox} from "../../components/StyledCheckbox";
import store from "../../redux/store";
import {restoreAction} from "../../redux/actions/loginActions";

const {Option} = Select;

const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 45px;

  h1 {
    margin: 0 12px 0 0;

    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    display: flex;
    align-items: center;

    color: #311C1C;
  }

  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    display: flex;
    align-items: center;

    color: #5B77CB;
  }
`;

const StyledRestorePasswordPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 65px 18.75% 450px 18.75%;

  @media screen and (max-width: 1919px) {
    padding: 65px 20px 450px 20px;
  }
  
  .ant-input {
    max-width: 280px;
  }

  .ant-form-item {
    margin: 0;
  }

  .askBlock {
    margin-top: 35px;

    .askAgain {
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
      text-decoration-line: underline;

      color: #5B77CB;
    }

    .timer {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;


      color: #8A8A8A;
    }
  }

  .ant-form {
    .ant-input {
      font-weight: 400;
      font-size: 18px;
      height: auto;
      padding: 16px 20px;
      line-height: 140%;

      color: #000000;
    }

    label {
      font-weight: 400;
      font-size: 18px;
      line-height: 140%;

      color: #000;

      &.ant-checkbox-wrapper {
        font-weight: 400;
        font-size: 18px;
        line-height: 140%;

        color: #8A8A8A;
      }
    }
  }


  .ant-btn {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    color: #FFFFFF;
    border: none;
    padding: 17px 28px;
    height: auto;
    background: #5B77CB;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 24px;
  }
`;

function RestorePasswordPage() {
    const [type, setType] = useState<"phone" | "email">("phone");

    return (
        <StyledRestorePasswordPage>
            <Top><h1>Восстановить пароль</h1></Top>

            <Form onFinish={({
                                 phoneZone,
                                 phoneNumber,
                                 email
                             }) => store.dispatch(restoreAction(type, type === "phone" ? `${phoneZone}${phoneNumber}`.replaceAll(" ", "").replaceAll("(", "").replaceAll(")", "") : email))}
                  layout={"vertical"}>
                <Form.Item>
                    <StyledCheckbox onClick={() => setType("phone")} checked={type === "phone"}>По номеру мобильного
                        телефона</StyledCheckbox>
                </Form.Item>
                <Form.Item>
                    <StyledCheckbox onClick={() => setType("email")} checked={type === "email"}>По
                        E-mail</StyledCheckbox>
                </Form.Item>

                <Form.Item style={{marginTop: 50}}
                           label={type === "phone" ? "Введите номер телефона, указанный вами при регистрации" : "Введите почту, указанную вами при регистрации"}>


                    {type === "phone" && <>
                        <Form.Item initialValue={'+7'} name={"phoneZone"}
                                   rules={[
                                       {
                                           required: true,
                                           message: "Поле не может быть пустым!"
                                       },
                                       {
                                           validator: (_, value) => {
                                               if (value !== undefined && value.indexOf("_") !== -1) {
                                                   return Promise.reject(new Error("Поле не может быть пустым!"))
                                               } else {
                                                   return Promise.resolve()
                                               }
                                           }
                                       }]}
                                   style={{display: 'inline-block', width: 70}}>
                            <MaskedInput mask={'+0[0][0]'}/>
                        </Form.Item>
                        <Form.Item name={"phoneNumber"}
                                   rules={[
                                       {
                                           required: true,
                                           message: "Поле не может быть пустым!"
                                       },
                                       {
                                           validator: (_, value) => {
                                               if (value !== undefined && value.indexOf("_") !== -1) {
                                                   return Promise.reject(new Error("Поле должно быть заполнено полностью!"))
                                               } else {
                                                   return Promise.resolve()
                                               }
                                           }
                                       }]}
                                   style={{display: 'inline-block', width: 'calc(215px - 8px)', margin: '0 8px'}}>
                            <MaskedInput mask={'(000) 000 00 00'}/>
                        </Form.Item>
                    </>}
                    {type === "email" && <Form.Item name={"email"} rules={[
                        {
                            required: true,
                            message: "Поле не может быть пустым!"
                        },
                        {
                            type: 'email',
                            message: 'Введен неверный E-mail!',
                        }]}>
                        <Input placeholder={"E-mail"}/>
                    </Form.Item>}
                </Form.Item>
                <Form.Item>
                    <Button htmlType={"submit"}>Подтвердить</Button>
                </Form.Item>
            </Form>
        </StyledRestorePasswordPage>
    );
}

export default RestorePasswordPage;