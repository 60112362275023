import React from 'react';
import styled from "styled-components";
import Breadcrumbs from "../../components/Breadcrumbs";

const StyledPrivacyPolicyPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 65px 18.75% 135px 18.75%;

  .space {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  b {
    margin-top: 20px;
  }

  p, h1, h2 {
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
    padding: 0;
    color: #000000;
  }

  h1 {
    &:first-of-type {
      margin-top: 50px;
    }

    font-weight: 600;
  }

  h2 {
    font-weight: 400;
    margin-bottom: 20px;
  }

  h1, h2 {
    text-align: center;
  }

  @media screen and (max-width: 1919px) {
    padding: 65px 20px 135px 20px;
  }
`;

function PrivacyPolicyPage() {
    return (
        <StyledPrivacyPolicyPage>
            <Breadcrumbs/>
            <h1>Политика конфиденциальности ООО «НИЗ ЗАРЯ»</h1>

            <span className="space">
                <b><p>г. Челябинск</p></b>
                <b><p>«15» февраля 2023 г.</p></b>
            </span>

            <p>Настоящая Политика конфиденциальности персональных данных (далее – Политика) действует в отношении всей
                информации, которую интернет-магазин niz-zarya.ru, может получить о Пользователе интернет-магазина,
                программ и продуктов интернет-магазина и в ходе исполнения любых соглашений и договоров с Пользователем,
                в том числе при участии Пользователя в акциях компании, оказании технической поддержки Пользователю и
                обслуживанию по гарантии.</p>
            <p>Общество с ограниченной ответственностью «Новый Инструментальный Завод ЗАРЯ» (далее ООО «НИЗ ЗАРЯ»)
                оставляет за собой право вносить изменения и дополнения в действующие положения Политики, при этом
                данные изменения и дополнения будут иметь преимущество над действующими положениями Политики. Обращаем
                внимание, предоставляемые ООО «НИЗ ЗАРЯ» услуги могут содержать ссылки на третьих лиц, имеющих
                собственные положения Политики защиты частной информации. В таких случаях ООО «НИЗ ЗАРЯ» не несет
                ответственности за содержание и соблюдение положений политики конфиденциальности любой сторонней
                компании. Предоставляя ООО «НИЗ ЗАРЯ» свои персональные данные, клиент дает полное согласие на их
                обработку способами, предусмотренными действующими положениями Политики конфиденциальности компании.</p>

            <b><p>1. Определение терминов.</p></b>
            <p>1.1 «Администрация интернет-магазина (далее – Администрация)» – уполномоченные на управление
                интернет-магазином сотрудники, действующие от имени ООО «НИЗ ЗАРЯ», которые организуют и (или)
                осуществляют обработку персональных данных, а также определяют цели обработки персональных данных,
                состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными
                данными.</p>
            <p>1.2 «Персональные данные» — сведения, имеющие прямое или косвенное отношение к определённому либо
                определяемому физическому или юридическому лицу (также называемому субъектом персональных данных).</p>
            <p>1.3 «Обработка персональных данных» — любая операция (действие) либо совокупность таковых, которые
                Администрация производит с персональными данными: сбор, запись, систематизация, накапливание, хранение,
                уточнение (при необходимости обновление или изменение), извлечение, использование, передача
                (распространение, предоставление, открытие к ним доступа), обезличивание, блокировка, удаление или
                уничтожение. Данные операции (действия) могут выполняться как автоматически, так и вручную.</p>
            <p>1.4 «Конфиденциальность персональных данных» — обязательное требование, предъявляемое к любому
                работающему с данными Пользователя должностному лицу, хранить полученные сведения в тайне, не посвящая в
                них посторонних, если предоставивший персональные данные Пользователь не изъявил своё согласие, а также
                отсутствует законное основание для разглашения.</p>
            <p>1.5 «Пользователь интернет-магазина» (далее — Пользователь)» – человек, посетивший интернет-магазин,
                пользующийся его программами и продуктами.</p>
            <p>1.6 «Cookies» — короткий фрагмент данных, пересылаемый веб-браузером или веб-клиентом веб-серверу в
                HTTP-запросе, всякий раз, когда Пользователь пытается открыть страницу интернет-магазина. Фрагмент
                хранится на компьютере Пользователя.</p>
            <p>1.7 «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенно й по протоколу TCP/IP.</p>

            <b><p>2. Общие положения.</p></b>
            <p>2.1 Просмотр интернет-магазина, а также использование его программ и продуктов подразумевают
                безоговорочное автоматическое согласие Пользователя с Политикой, подразумевающей добровольное
                предоставление Пользователем своих персональных данных на обработку.</p>
            <p>2.2 Если Пользователь не принимает существующую Политику, Пользователь должен покинуть
                интернет-магазин.</p>
            <p>2.3 Имеющаяся Политика распространяется только на интернет-магазин niz-zarya.ru. За содержание страниц и
                действия Пользователя, в случае перехода по ссылкам других организаций, в том числе содержащихся на
                страницах интернет-магазина, ООО «НИЗ ЗАРЯ» ответственности не несёт.</p>
            <p>2.4 Проверка достоверности персональных данных, которые решил сообщить принявший Политику Пользователь,
                не входит в обязанности Администрации.</p>

            <b><p>3. Предмет политики конфиденциальности.</p></b>
            <p>3.1 Согласно проводимой в текущий период Политике конфиденциальности Администрация интернет-магазина
                обязана не разглашать персональные данные, сообщаемые Пользователями, регистрирующимися на сайте или
                оформляющими заказ на покупку товара, а также обеспечивать этим данным абсолютную
                конфиденциальность.</p>
            <p>3.2 Заполняемые Пользователем в электронной форме на странице интернет-магазина персональные данные,
                подлежащие обработке:</p>
            <p>- фамилия, имя, отчество;</p>
            <p>- контактный телефон;</p>
            <p>- электронный адрес (e-mail);</p>
            <p>- адрес, по которому должен быть доставлен купленный товар;</p>
            <p>- адрес проживания Пользователя.</p>
            <p>3.3 Использование ООО «НИЗ ЗАРЯ» файлов Cookies и точечных маркеров. Файлы Cookies дают возможность сбора
                следующей информации о клиенте: IP-адрес/операционная система/тип браузера/адреса ссылающихся сайтов.
                Это необходимо для улучшения услуг, предоставляемых клиенту компании (сохранение пароля при закрытии
                браузера, анализ трафика и т.д.).</p>
            <p>3.4 По своему желанию клиент в любой момент может предотвратить отслеживание своих действий, отключив
                Cookies.</p>
            <p>3.5 Последствием отключения Cookies может стать невозможность доступа к требующим авторизации частям
                интернет-магазина.</p>
            <p>3.6 Точечные маркеры служат для графического отражения на сайте безличной информации о пользователях
                (счетчики посетителей/просмотров на сайте).</p>
            <p>3.7 ООО «НИЗ ЗАРЯ» осуществляется сбор данных, автоматически передаваемых при просмотре рекламных блоков
                и посещении страниц с установленными на них статистическими скриптами системы (пикселями):</p>
            <p>- время доступа и IP-адрес;</p>
            <p>- источники перехода на интернет-ресурс компании;</p>
            <p>- интернет-страницы, которые посетил Пользователь;</p>
            <p>- реферер (адрес предыдущей страницы);</p>
            <p>- просмотры рекламных баннеров;</p>
            <p>- адрес страницы, на которой располагается рекламный блок;</p>
            <p>- сведения о браузере (либо другой программе, через которую становится доступен показ рекламы);</p>
            <p>- другая техническая информация, предоставленная браузером пользователя;</p>
            <p>- сведения из Сookies.</p>
            <p>3.8 С целью контроля законности проведения финансовых платежей, а также выявления и решения технических
                проблем, интернет-магазин собирает статистику об IP-адресах всех Посетителей.</p>
            <p>3.9 Надёжно хранятся и не распространяются информация о действиях пользователя:</p>
            <p>- сведения о соглашениях/договорах между клиентом и компанией;</p>
            <p>- информация о запросах и направленных в адрес компании обращениях;</p>
            <p>- данные о товарах, приобретенных клиентом и услугах, которыми клиент пользовался;</p>
            <p>- совершенные платежи и другая финансовая информация, предусмотренная законодательством РФ.</p>
            <p>3.10 Исключение для выполнения ООО «НИЗ ЗАРЯ» требований неразглашения персональных данных Пользователя
                составляет получение прямого согласия самого Пользователя на передачу его персональных данных третьему
                лицу и положения указанные в п.п. 5.2 и 5.3 настоящей Политики.</p>

            <b><p>4. Цели сбора и обработки персональной информации пользователя.</p></b>
            <p>4.1 Идентификация Пользователя прошедшего процедуру регистрации на странице интернет-магазина.</p>
            <p>4.2 Предоставление доступа Пользователю к персонализированным ресурсам интернет-магазина.</p>
            <p>4.3 Обратная связь с Пользователем (уточнение информации, рассылка уведомлений, обработка запросов и
                заявок, подтверждение данных, информирование, оказание прочих услуг), посредством электронных и
                СМС-рассылок.</p>
            <p>4.4 Определение местоположения, предотвращение мошеннических действий, обеспечение безопасности
                платежей.</p>
            <p>4.5 Обеспечение максимально оперативного решения проблем, встречающихся при использовании
                интернет-магазина.</p>
            <p>4.6 Рекламирование товара, информирование и ознакомление с уникальными предложениями, новыми прайсами,
                новостями о деятельности интернет-магазина или его партнёров и прочими сведениями (по желанию
                Пользователя).</p>

            <b><p>5. Способы обработки персональной информации.</p></b>
            <p>5.1 Срок обработки персональных данных Пользователя ничем не ограничен. Процедура обработки может
                проводиться любым предусмотренным законодательством способом. В частности, с помощью информационных
                систем персональных данных, которые могут вестись в том числе автоматически.</p>
            <p>5.2 С целью выполнения заказа Пользователя, оформленного через интернет-магазин, обработанные
                Администрацией персональные данные Пользователя могут передаваться третьим лицам (курьерские службы,
                организации почтовой связи, операторы службы доставки и т.п.). Согласие Пользователя на подобную
                передачу предусмотрено правилами политики интернет-магазина.</p>
            <p>5.3 Обработанные Администрацией персональные данные могут передаваться уполномоченным органам
                государственной власти Российской Федерации, если это осуществляется на законных основаниях и в
                предусмотренном российским законодательством порядке.</p>
            <p>5.4 В случае утраты или разглашения персональных данных, Администрация уведомляет об этом Пользователя и
                совместно с Пользователем готова принять все возможные меры, дабы предотвратить убытки и прочие
                негативные последствия, вызванные указанной ситуацией.</p>
            <p>5.5 Все действия Администрации сайта направлены на сохранение персональных данных Пользователя и
                предотвращение допуска к ним третьих лиц (за исключением п.п. 5.2, 5.3 Политики). Персональные данные
                Пользователя не могут быть доступны даже случайно, дабы избежать уничтожения, изменения, блокировки,
                копирования, распространения или прочих противозаконных действий. Для защиты пользовательских данных
                Администрация располагает комплексом организационных и технических мер.</p>

            <b><p>6. Права и обязанности сторон.</p></b>
            <p>6.1 Пользователь обязан:</p>
            <p>6.1.1 Предоставлять достоверные сведения о себе в рамках настоящей Политики.</p>
            <p>6.1.2 Оповестить Администрацию в случае изменения персональных данных.</p>
            <p>6.2 Пользователь имеет право:</p>
            <p>6.2.1 Редактировать персональные данные в своем личном кабинете.</p>
            <p>6.2.2 Затребовать прекращения обработки своих персональных данных (необходимо обратиться в ООО «НИЗ
                ЗАРЯ»).</p>
            <p>6.3 Обязанности Администрации:</p>
            <p>6.3.1 Использовать полученные от Пользователя сведения исключительно в рамках действующей Политики.</p>
            <p>6.3.2 Обеспечить за счёт собственных сил и средств конфиденциальность полученных от Пользователя
                персональных данных в рамках действующей Политики.</p>
            <p>6.4 Права Администрации:</p>
            <p>6.4.1 Получать от Пользователя достоверные актуальные персональные данные в рамках настоящей
                Политики.</p>
            <p>6.4.2 После обращения Пользователя или его законного представителя, блокировать персональные
                пользовательские данные. Право сделать запрос на блокировку также предоставляется органу,
                уполномоченному действовать от имени Пользователя, предоставившего Администрации свои данные.</p>

            <b><p>7. Ответственность сторон.</p></b>
            <p>7.1 В случае причинения ущерба Пользователю из-за неисполнения Администрацией собственных обязательств,
                указанных в настоящей Политике, ответственность возлагается на ООО «НИЗ ЗАРЯ». Исключения составляют
                случаи, указанные в п.п. 5.2, 5.3, 7.2 настоящей Политики.</p>
            <p>7.2 ООО «НИЗ ЗАРЯ» и/или Администрация не несут ответственности в случае утраты или разглашения
                персональных данных, когда они:</p>
            <p>7.2.1 Являлись достоянием общественности до того, как были утрачены или разглашены; 7.2.2 Предоставлены
                третьим лицам до того, как их получила Администрация сайта; 7.2.3 Были разглашены с согласия
                Пользователя.</p>

            <b><p>8. Разрешение споров.</p></b>
            <p>8.1 Все споры и разногласия, возникающие при исполнении настоящей Политики, решаются путем
                переговоров.</p>
            <p>8.2 Если Пользователь намерен отстаивать свои права и/или интересы в суде, перед обращением в суд, он в
                обязательном порядке предъявить письменную претензию ООО «НИЗ ЗАРЯ» (предложить урегулировать конфликт
                добровольно). ООО «НИЗ ЗАРЯ» в течение 30 календарных дней с даты получения письменной претензии
                Пользователя, письменно уведомляет Пользователя о рассмотрении и принятых мерах.</p>
            <p>8.3 Любые отношения между Пользователем и Администрацией в области Политики конфиденциальности,
                регулируются согласно действующему законодательству Российской Федерации.</p>
            <p>8.4 При неурегулировании в процессе переговоров спорные вопросы подлежат рассмотрению в суде г.
                Челябинска.</p>

            <b><p>9. Дополнительные условия.</p></b>
            <p>9.1 Настоящая Политика конфиденциальности вступает в силу с момента её публикации и действует до момента
                принятия Политики конфиденциальности в новой редакции.</p>
            <p>9.2 Администрация вправе менять существующую на текущий момент Политику без согласования с
                Пользователем.</p>
            <p>9.3 С предложениями, пожеланиями, требованиями или вопросами по настоящей Политике конфиденциальности
                следует обращаться в Администрацию и/или в ООО «НИЗ ЗАРЯ».</p>
        </StyledPrivacyPolicyPage>
    );
}

export default PrivacyPolicyPage;