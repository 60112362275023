import axios from "axios";
import {api} from "../../config";
import {LOGIN_SUCCESS, LOGOUT_SUCCESS} from "../actionTypes";
import History from "../../utils/history";
import {message} from "antd";
import store from "../store";
import {RoleType} from "../../utils/types";
import {getCartAction, syncCartAction, syncFavoriteAction} from "./pageActions";

export function getUserAction(hideError = false) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/user`).then(({data}) => {
                dispatch({type: LOGIN_SUCCESS, payload: data})
                if (data.PhoneApproved === false)
                    History.push("/profile/confirm")
                else if (data.Role !== RoleType.admin && data.Email !== "" && data.EmailApproved === false)
                    History.push(`/profile/confirm/email/${data.Email}`)
                res();
            }).catch(({response}) => {
                !hideError && message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function loginAction({login, password, save = true}) {
    return (dispatch) => {
        axios.post(`${api}/auth`, {
            login: login,
            password: password,
            save
        }).then(({data}) => {
            dispatch({type: LOGIN_SUCCESS, payload: data})
            store.dispatch(getCartAction())
            store.dispatch(syncFavoriteAction(data))
            store.dispatch(syncCartAction())


            if (data.Role === RoleType.admin)
                History.push("/admin/home")
            else if (data.PhoneApproved === false)
                History.push("/profile/confirm")
            else if (data.Role !== RoleType.admin && data.Email !== "" && data.EmailApproved === false)
                History.push(`/profile/confirm/email/${data.Email}`)
            else if (data.Role !== RoleType.admin && (data.Name === "" || data.Email === ""))
                History.push("/profile/fill")
            else
                History.push("/")


        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }
}

export function refreshTokenAction() {
    return (dispatch) => {
        axios(`${api}/user`).then(({data}) => {
            dispatch({type: LOGIN_SUCCESS, payload: data})
            store.dispatch(getCartAction())
            store.dispatch(syncFavoriteAction(data))
            store.dispatch(syncCartAction())

            // History.location.pathname === "/login" && History.push(data.Role === 90 ? "/admin" : data.Role === 80 ? "/manager1" : data.Role === 85 ? "/supervisor" : "/mover1");
            if (data.PhoneApproved === false)
                History.push("/profile/confirm")
            else if (data.Role !== RoleType.admin && data.Email !== "" && data.EmailApproved === false)
                History.push(`/profile/confirm/email/${data.Email}`)
            else if (data.Role !== RoleType.admin && (data.Name === "" || data.Email === ""))
                History.push("/profile/fill")

        }).catch(err => {
            // console.log(History.location.pathname);
            // console.log(!History.location.pathname.includes("/catalog"));
            if (![
                "/",
                "/profile/login",
                "/profile/confirm",
                "/profile/create",
                "/profile/restore",
                "/admin",
                "/doc/personal",
                "/doc/public",
                "/doc/policy",
                "/about",
                "/media",
                "/cart",
                "/catalog",
                "/location",
                "/favorites"
            ].includes(History.location.pathname) && !["/catalog", "/profile/verify"].some(el => History.location.pathname.includes(el))) {
                History.push("/profile/login")
            }
        })
    }
}

export function signupAction({login, password, phone, name, email}) {
    return (dispatch) => {
        axios.post(`${api}/reg`, {
            Login: login,
            Password: password,
            Phone: phone,
            Name: name,
            Email: email
        }).then(({data}) => {
            dispatch({type: LOGIN_SUCCESS, payload: data})
            store.dispatch(syncFavoriteAction(data))
            store.dispatch(syncCartAction())

            if (data.PhoneApproved)
                History.push("/")
            else
                History.push("/profile/confirm")
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }
}


export function restoreAction(type, value) {
    return (dispatch) => {
        axios.post(`${api}/recovery/${type}/${value}`).then(({data}) => {
            if (type === "email") {
                message.warning("Инструкции для восстановления пароля отправлены на почту!")
                History.push("/profile/login");
            }
            if (type === "phone") {
                History.push(`/profile/verify/${value}`);
            }
        }).catch(({response}) => {
            message.error(response?.data || "Error")
        })
    }
}

export function verifyAction(type, code, signal) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/verify/${type}/${code}`, {}, {
                signal: signal
            }).then(({data}) => {
                History.push("/")
                store.dispatch(getUserAction())
                res();
            }).catch(({response, ...error}) => {
                if (error.code !== "ERR_CANCELED")
                    message.error(response?.data || "Error")
                rej();
            })
        })
    }
}
export function callVerificationAction(signal) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/request/email`, {}, {
                signal: signal
            }).then(({data}) => {
                res();
            }).catch(({response, ...error}) => {
                if (error.code !== "ERR_CANCELED")
                    message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function resetAction(type, value, code, signal) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.post(`${api}/reset/${type}/${value}/${code}`, {}, {
                signal: signal
            }).then(({data}) => {
                store.dispatch(getUserAction())
                if (data.Role === RoleType.admin)
                    History.push("/admin/home")
                else if (data.PhoneApproved === false)
                    History.push("/profile/confirm")
                else if (data.Role !== RoleType.admin && data.Email !== "" && data.EmailApproved === false)
                    History.push(`/profile/confirm/email/${data.Email}`)
                else if (data.Role !== RoleType.admin && (data.Name === "" || data.Email === ""))
                    History.push("/profile/fill")
                else
                    History.push("/profile")
                res();
            }).catch(({response, ...error}) => {
                if (error.code !== "ERR_CANCELED")
                    message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function regPhoneAction({phone}) {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios.get(`${api}/phone/${phone}`).then(({data}) => {
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}

export function logoutAction() {
    return (dispatch) => {
        return new Promise((res, rej) => {
            axios(`${api}/exit`).then(() => {
                dispatch({type: LOGOUT_SUCCESS})
                res();
            }).catch(({response}) => {
                message.error(response?.data || "Error")
                rej();
            })
        })
    }
}
