import {initialState} from "../initialState";
import {
    GET_CATALOG, GET_CONFIG_SUCCESS,
    GET_GROUPS_SUCCESS,
    GET_ORDERS,
    GET_SUB_GROUPS_SUCCESS,
    GET_USERS,
    GET_VIDEOS
} from "../actionTypes";

export function adminReducer(state = initialState, action) {
    switch (action.type) {
        case GET_GROUPS_SUCCESS:
            return {
                ...state,
                groups: action.payload
            }
        case GET_CONFIG_SUCCESS:
            return {
                ...state,
                config: action.payload
            }
        case GET_CATALOG:
            return {
                ...state,
                catalog: action.payload
            }
        case GET_USERS:
            return {
                ...state,
                users: action.payload
            }
        case GET_VIDEOS:
            return {
                ...state,
                videos: action.payload
            }
        case GET_ORDERS:
            return {
                ...state,
                orders: action.payload
            }
        case GET_SUB_GROUPS_SUCCESS:
            return {
                ...state,
                subGroups: {
                    ...state.subGroups,
                    [action.payload.Id]: action.payload.data
                }
            }
        default:
            return state;
    }
}