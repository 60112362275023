import React, {useEffect} from 'react';
import styled from "styled-components";
import {Form, Input, InputNumber, Select, Space, Tooltip} from "antd";
import {StyledButton} from "../../components/StyledButton";
import QuestionCircleSVG from "../../icons/QuestionCircleSVG";
import store from "../../redux/store";
import {editConfigAction, getConfigAction} from "../../redux/actions/adminActions";
import {GroupType} from "../../utils/types";
import {useSelector} from "react-redux";

const {Option} = Select;

const StyledSettingsPage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px 0;

  .ant-space-item {
    width: 100%;
  }

  .ant-tooltip-inner {
    background: #fff;
    position: relative;
    top: 15px;
    left: 10px;
    color: #898989;
    box-shadow: none;
    border-radius: 8px;
    border: 1px solid #DCDCDC;
  }

  .questionTooltip {
    z-index: 9999;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -36px;
  }

  .ant-form {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    width: 100%;
    max-width: 1200px;
    background: #FFF;
    padding: 38px 60px;
    border-radius: 12px;

    .space {
      display: flex;
      height: 46px;
    }

    label {
      color: #000000;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .ant-row {
      display: flex;
      justify-content: space-between;
      max-width: 652px;

      .ant-form-item-control {
        max-width: 367px;
        width: 100%;
        flex: 0 1 auto;
      }

      .ant-input {
        max-width: 367px;
        width: 100%;
      }
    }
  }
`;

function SettingsPage() {

    const config: GroupType[] = useSelector((store: any) => store.adminReducer.config)

    const [formRef] = Form.useForm();

    useEffect(() => {
        store.dispatch(getConfigAction())
    }, [])

    useEffect(() => {
        formRef.setFieldsValue(config);
    }, [config])

    return (
        <StyledSettingsPage>
            <Form
                onFinish={(fields) => {
                    store.dispatch(editConfigAction(fields))
                }}
                requiredMark={false}
                form={formRef}>
                <Form.Item rules={[{required: true, message: "WebPort является обязательным параметром!"}]}
                           label={"WebPort:"} name={"WebPort"}>
                    <InputNumber style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item name={"TypeLog"} label={"TypeLog:"}>
                    <Select>
                        <Option value={"debug"}>debug</Option>
                        <Option value={"warn"}>warn</Option>
                        <Option value={"error"}>error</Option>
                        <Option value={"info"}>info</Option>
                    </Select>
                </Form.Item>
                <Form.Item name={"Key"} label={"Key:"}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"MasterPassword"} label={"MasterPassword:"}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"NameHeaderRemoteIp"} label={"NameHeaderRemoteIp:"}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"Backup:"}>
                    <Form.Item name={"Backup"} noStyle>
                        <Select>
                            <Option value={true}>true</Option>
                            <Option value={false}>false</Option>
                        </Select>
                    </Form.Item>
                    <Tooltip
                        getPopupContainer={() => document.querySelector(`.${StyledSettingsPage.styledComponentId}`)!}
                        arrow={false} placement={"topLeft"} title={"true/false"}
                        className={"questionTooltip"}>
                        <QuestionCircleSVG/>
                    </Tooltip>
                </Form.Item>
                <Form.Item label={"EmailVerification:"}>
                    <Form.Item name={"EmailVerification"} noStyle>
                        <Select>
                            <Option value={true}>true</Option>
                            <Option value={false}>false</Option>
                        </Select>
                    </Form.Item>
                    <Tooltip
                        getPopupContainer={() => document.querySelector(`.${StyledSettingsPage.styledComponentId}`)!}
                        arrow={false} placement={"topLeft"} title={"true/false"}
                        className={"questionTooltip"}>
                        <QuestionCircleSVG/>
                    </Tooltip>
                </Form.Item>
                <Form.Item name={"TelegramApi"} label={"TelegramApi:"}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"AdminPassword"} label={"AdminPassword:"}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"MainDomain"} label={"MainDomain:"}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"SmsApiId"} label={"SmsApiId:"}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"SmsMockup:"}>
                    <Form.Item name={"SmsMockup"} noStyle>
                        <Select>
                            <Option value={true}>true</Option>
                            <Option value={false}>false</Option>
                        </Select>
                    </Form.Item>
                    <Tooltip
                        getPopupContainer={() => document.querySelector(`.${StyledSettingsPage.styledComponentId}`)!}
                        arrow={false} placement={"topLeft"} title={"true/false"}
                        className={"questionTooltip"}>
                        <QuestionCircleSVG/>
                    </Tooltip>
                </Form.Item>
                <span className={"space"}></span>
                <Form.Item name={"ServerSMTP"} label={"ServerSMTP:"}>
                    <Input/>
                </Form.Item>
                <Form.Item label={"SslSMTP:"}>
                    <Form.Item name={"SslSMTP"} noStyle>
                        <Select>
                            <Option value={true}>true</Option>
                            <Option value={false}>false</Option>
                        </Select>
                    </Form.Item>
                    <Tooltip
                        getPopupContainer={() => document.querySelector(`.${StyledSettingsPage.styledComponentId}`)!}
                        arrow={false} placement={"topLeft"} title={"true/false"}
                        className={"questionTooltip"}>
                        <QuestionCircleSVG/>
                    </Tooltip>
                </Form.Item>
                <Form.Item name={"PortSMTP"} label={"PortSMTP:"}>
                    <InputNumber style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item name={"LoginSMTP"} label={"LoginSMTP:"}>
                    <Input/>
                </Form.Item>
                <Form.Item name={"PassSMTP"} label={"PassSMTP:"}>
                    <Input/>
                </Form.Item>
                <Form.Item style={{marginTop: 68}}>
                    <StyledButton htmlType={"submit"}>Сохранить</StyledButton>
                </Form.Item>
            </Form>
        </StyledSettingsPage>
    );
}

export default SettingsPage;