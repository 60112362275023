import React from 'react';

function RemoveSvg(props: any) {
    return (
        <svg {...props} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 4.52979H15" stroke="#898989" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M5 8.46973V14.5297" stroke="#898989" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M8 7.31006V15.5801" stroke="#898989" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M11 8.46973V14.5297" stroke="#898989" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.795 18.4718H4.205C2.987 18.4718 2 17.4848 2 16.2668V4.52783H14V16.2668C14 17.4848 13.013 18.4718 11.795 18.4718Z"
                  stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M12 4.52783L11.262 2.22283C11.129 1.80883 10.744 1.52783 10.31 1.52783H5.69C5.255 1.52783 4.87 1.80883 4.738 2.22283L4 4.52783"
                stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M1 4.52979H2" stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default RemoveSvg;