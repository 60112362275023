import React from 'react';

function IconCloseSvg(props:any) {
    return (
        <svg {...props} width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7.34146 7.34146M7.34146 7.34146L13.6829 1M7.34146 7.34146L1 13.6829M7.34146 7.34146L14 14"
                  stroke="black"/>
        </svg>
    );
}

export default IconCloseSvg;