import React from 'react';

function QuestionCircleSvg(props: any) {
    return (
        <svg {...props} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.5" cy="9.5" r="9.5" fill="#70B6C1"/>
            <path
                d="M10.0327 10.9887H8.87823C8.87823 10.3101 9.14719 9.70186 9.6851 9.16395C9.73475 9.1143 9.89199 8.96534 10.1568 8.71707C10.4299 8.46053 10.6078 8.28261 10.6906 8.1833C10.7816 8.07572 10.8809 7.91434 10.9885 7.69918C11.1044 7.48402 11.1623 7.27713 11.1623 7.07851C11.1623 6.67301 11.0216 6.35854 10.7402 6.1351C10.4589 5.91166 10.0865 5.79994 9.62303 5.79994C8.71272 5.79994 8.12929 6.2013 7.87275 7.00403L6.8921 6.45785C7.09071 5.89511 7.43415 5.46064 7.9224 5.15445C8.41894 4.83998 8.98995 4.68274 9.63544 4.68274C10.3637 4.68274 10.9926 4.88963 11.5223 5.30341C12.0519 5.70891 12.3167 6.28406 12.3167 7.02886C12.3167 7.67435 12.0478 8.26606 11.5099 8.80397C11.4685 8.84534 11.3402 8.96948 11.125 9.17637C10.9099 9.37498 10.7651 9.5198 10.6906 9.61083C10.6161 9.69359 10.5127 9.82186 10.3802 9.99565C10.2478 10.1694 10.1568 10.3349 10.1071 10.4922C10.0575 10.6494 10.0327 10.8149 10.0327 10.9887ZM9.44924 13.67C9.2258 13.67 9.03547 13.5914 8.87823 13.4341C8.72099 13.2769 8.64238 13.0866 8.64238 12.8631C8.64238 12.6397 8.72099 12.4493 8.87823 12.2921C9.03547 12.1349 9.2258 12.0563 9.44924 12.0563C9.68096 12.0563 9.8713 12.1349 10.0203 12.2921C10.1775 12.4493 10.2561 12.6397 10.2561 12.8631C10.2561 13.0866 10.1775 13.2769 10.0203 13.4341C9.8713 13.5914 9.68096 13.67 9.44924 13.67Z"
                fill="white"/>
        </svg>
    );
}

export default QuestionCircleSvg;