import React from 'react';

function AttachmentSvg(props: any) {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 8.65341L10.4688 17.1522C7.48288 20.1276 3.72062 18.9289 2.36418 17.5772C1.00774 16.2256 -0.195202 13.3267 3.21723 9.92621L11.2573 1.90715C12.0683 1.22259 14.1852 0.264218 16.1653 1.90715C18.1453 3.55007 17.3499 5.76118 16.7047 6.66137L9.44858 13.88C8.54245 14.7596 7.05632 15.877 5.51379 14.568C4.32526 13.5593 4.93203 11.8058 5.89458 11.0459L12.1035 5.06978"
                stroke="#898989"/>
        </svg>
    );
}

export default AttachmentSvg;